import { ReactNode } from 'react';
import cx from 'classnames';
import emptyServerList from 'images/emptyServerList.png';
import fonts from 'styles/typography.module.scss';
import Button from 'components/Button';
import s from './EmptySection.module.scss';

interface Props {
  title?: ReactNode | string;
  buttonContent?: ReactNode | string;
  onButtonClick?: () => void;
}

const EmptySection = ({ title, buttonContent, onButtonClick }: Props) => (
  <div className={s.wrapper}>
    <img src={emptyServerList} alt="empty list" className={s.emptyImage} />
    {title && <p className={cx(s.emptyText, fonts.paragraph)}>{title}</p>}
    {buttonContent && (
      <Button className={s.emptyButton} onClick={onButtonClick}>
        {buttonContent}
      </Button>
    )}
  </div>
);

export default EmptySection;
