import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import fonts from 'styles/typography.module.scss';
import s from './NotFound.module.scss';

const NotFound: React.FC<{
  title?: string;
  path?: string;
}> = ({ title = 'Page not found', path = '..' }) => (
  <>
    <div className={s.empty}>
      <h2 className={fonts.paragraph}>{title}</h2>

      <Link to={path}>
        <Button>Go Back</Button>
      </Link>
    </div>
  </>
);

export default NotFound;
