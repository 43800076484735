import React from 'react';
import LinkCopy from 'components/LinkCopy';
import { crownIconUrl, walletIconUrl } from 'icons';
import { useSelector } from 'store/hooks';
import s from './WalletBalance.module.scss';

export type WalletBalanceProps = {
  style?: any;
};

const WalletBalance: React.FC<WalletBalanceProps> = ({ style = {} }) => {
  const balance = useSelector((state) => state.marketplace.clTokenBalance);
  return (
    <div style={style} className={s.walletContainer}>
      <div className={s.lineContainer}>
        <img src={crownIconUrl} className={s.icon} alt="" />
        <p>{balance} COLLAB</p>
      </div>
      <div className={s.lineContainer}>
        <img src={walletIconUrl} className={s.icon} alt="" />
        <LinkCopy value="0xef44...2617" />
      </div>
    </div>
  );
};

export default WalletBalance;
