import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import MascotLogo from 'images/Waving-YellowBG.png';
import Button from 'components/Button';
import AuthLayout from 'components/AuthLayout';
import fonts from 'styles/typography.module.scss';
import styles from './StartScreen.module.scss';
import useSignInUrl from '../../hooks/useSignInUrl';
import { Discord, Telegram } from '../../icons';
import usePopup, { PopUpEvents } from '../../hooks/usePopupWindow';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from '../../store/hooks';
import { login } from '../../store/user';
import Spinner from '../../components/Spinner';

const StartScreen: React.FC<{ tokenExpired: boolean }> = ({ tokenExpired }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const discordLoginUrl = useSignInUrl('discord');
  const telegramLoginUrl = useSignInUrl('telegram');
  const popup = usePopup();
  const navigate = useNavigate();
  const { isUserLoaded } = useSelector((s) => s.user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isUserLoaded && localStorage.getItem('collabLandToken')) {
      navigate(`/dashboard/`);
    }
  }, [isUserLoaded, navigate]);
  return (
    <AuthLayout>
      <h4 className={cx(styles.header, fonts.h1)}>Welcome to Command Center</h4>
      <div className={cx(styles.section)}>
        <img src={MascotLogo} className={styles.mascotLogo} alt="mascot icon" />
      </div>
      {loggedIn ? (
        <Spinner />
      ) : (
        <>
          {tokenExpired && (
            <div className={cx(styles.infoMsg)}>
              <p>Your session has timed out. Please login again.</p>
              <span>Changes you made may not have been saved</span>
            </div>
          )}
          <Button
            className={styles.discordBtn}
            color="primary"
            onClick={() => {
              popup.open(
                PopUpEvents.LOGGED_IN,
                discordLoginUrl,
                (payload: any) => {
                  setLoggedIn(true);
                  dispatch(login({ token: payload.token! }));
                },
                500,
                800,
              );
            }}
            data-tracking-info={JSON.stringify({
              id: 'button:login-with-discord:click',
            })}
          >
            <img src={Discord} alt={'Discord icon'} />
            Login with Discord
          </Button>
          <Button
            className={styles.telegramBtn}
            color="primary"
            onClick={() => {
              popup.open(
                PopUpEvents.LOGGED_IN,
                telegramLoginUrl,
                (payload: any) => {
                  setLoggedIn(true);
                  dispatch(login({ token: payload.token! }));
                },
                600,
                500,
              );
            }}
            data-tracking-info={JSON.stringify({
              id: 'button:login-with-telegram:click',
            })}
          >
            <img src={Telegram} alt={'Telegram icon'} />
            Login with Telegram
          </Button>
        </>
      )}
    </AuthLayout>
  );
};

export default StartScreen;
