import React from 'react';
import cx from 'classnames';

import Modal from 'components/Modal';
import Button from 'components/Button';

import fonts from 'styles/typography.module.scss';
import s from './DeleteConfirmationModal.module.scss';

export type DeleteConfirmationModalProps = {
  close: () => void;
  callback?: () => void;
  tpcName: string;
};

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  tpcName,
  close,
  callback,
}) => (
  <Modal>
    <h4 className={cx(fonts.title2, s.title)}>Delete {tpcName}</h4>

    <p className={cx(fonts.body1, s.text)}>
      Are you sure you want to delete {tpcName}? This cannot be undone.
    </p>

    <div className={s.row}>
      <Button
        type="button"
        color="secondary"
        className={s.cancel}
        onClick={close}
      >
        Cancel
      </Button>

      <Button
        type="button"
        color="danger"
        className={s.submit}
        onClick={callback}
      >
        Delete
      </Button>
    </div>
  </Modal>
);

export default DeleteConfirmationModal;
