import { parse, formatDistanceToNowStrict } from 'date-fns';

/* eslint-disable import/prefer-default-export */
export * from './sort';

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

export function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}

// reading a dimension prop will cause the browser to recalculate,
// which will let our animations work
// DEPRECATED
// export default function triggerBrowserReflow(node: HTMLElement): void {
//   // eslint-disable-next-line no-unused-expressions
//   node.offsetHeight;
// }

// DEPRECATED
// export function copyTextToClipboard(text: string): void {
//   const textArea = document.createElement('textarea');

//   //
//   // *** This styling is an extra step which is likely not required. ***
//   //
//   // Why is it here? To ensure:
//   // 1. the element is able to have focus and selection.
//   // 2. if the element was to flash render it has minimal visual impact.
//   // 3. less flakyness with selection and copying which **might** occur if
//   //    the textarea element is not visible.
//   //
//   // The likelihood is the element won't even render, not even a
//   // flash, so some of these are just precautions. However in
//   // Internet Explorer the element is visible whilst the popup
//   // box asking the user for permission for the web page to
//   // copy to the clipboard.
//   //

//   // Place in the top-left corner of screen regardless of scroll position.
//   textArea.style.position = 'fixed';
//   textArea.style.top = '0';
//   textArea.style.left = '0';

//   // Ensure it has a small width and height. Setting to 1px / 1em
//   // doesn't work as this gives a negative w/h on some browsers.
//   textArea.style.width = '2em';
//   textArea.style.height = '2em';

//   // We don't need padding, reducing the size if it does flash render.
//   textArea.style.padding = '0';

//   // Clean up any borders.
//   textArea.style.border = 'none';
//   textArea.style.outline = 'none';
//   textArea.style.boxShadow = 'none';

//   // Avoid flash of the white box if rendered for any reason.
//   textArea.style.background = 'transparent';

//   textArea.value = text;

//   document.body.appendChild(textArea);
//   textArea.focus();
//   textArea.select();

//   try {
//     const successful = document.execCommand('copy');
//     const msg = successful ? 'successful' : 'unsuccessful';
//     console.log(`Copying text command was ${msg}`);
//   } catch (err) {
//     console.error('Oops, unable to copy');
//   }

//   document.body.removeChild(textArea);
// }

export const simulateHttpRequest = (): Promise<void> =>
  new Promise((resolve) => setTimeout(() => resolve(), 1500));

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  // Cast to a File() type
  return b;
};

export const getImageFromAWS = async (fileName: string): Promise<string> => {
  try {
    const token = localStorage.getItem('collabLandToken');

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/files/${fileName}`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`,
          'x-api-key': process.env.REACT_APP_COLLABLAND_KEY || '',
        },
      },
    );

    const blob = await res.blob();
    const externalFile = blobToFile(blob, 'my-image');

    return URL.createObjectURL(externalFile);
  } catch (e) {
    // console.log(e);
    return '';
  }
};

export const alphabeticalSort = (collection: any[], key = 'name'): any[] =>
  [...collection].sort((a, b) => {
    if (!a[key] || !b[key]) return 0;

    return a[key].localeCompare(b[key]);
  });

export const getNameInitials = (name: string): string => {
  const names = name.split(' ');
  let initials = Array.from(names[0])[0].toUpperCase();

  if (names.length > 1) {
    initials += Array.from(names[names.length - 1])[0].toUpperCase();
  }

  return initials;
};

export const tokenIdIsUrl = (url: string): boolean => {
  const isUrl = ['ipfs', 'https', 'http'].some((protocol) =>
    url.startsWith(protocol),
  );
  return isUrl;
};
type RGBValue = [number, number, number];

function getRGBFromDecimal(c: number): RGBValue {
  const r = Math.floor(c / (256 * 256));
  const g = Math.floor(c / 256) % 256;
  const b = c % 256;

  return [r, g, b];
}

function getLuminance([r, g, b]: RGBValue) {
  const a = [r, g, b].map((v) => {
    const val = v / 255;
    return val <= 0.03928 ? val / 12.92 : ((val + 0.055) / 1.055) ** 2.4;
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}
function getContrast(rgb1: RGBValue, rgb2: RGBValue) {
  const lum1 = getLuminance(rgb1);
  const lum2 = getLuminance(rgb2);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

export const checkLowContrast = (color: number) => {
  if (!color) return false;
  const rgbValue = getRGBFromDecimal(color);

  const contrast = getContrast(rgbValue, [0, 0, 0]);

  return contrast < 2.5;
};

export const getRoleColor = (color: number): string =>
  color === 0 ? '#99aab5' : `#${Number(color).toString(16).padStart(6, '0')}`;

export const stripEmptyStringAttributes = (
  input: { [key: string]: any },
  excludedKeys: string[] = [],
) => {
  if (Array.isArray(input) || typeof input !== 'object' || input == null)
    return input;
  const obj = { ...input };
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object')
      obj[key] = stripEmptyStringAttributes(obj[key]);
    else if (obj[key] === '' && !excludedKeys.includes(key)) delete obj[key];
  });
  return obj;
};

export const isOverflowing = (el: any) => {
  return (
    el?.clientWidth < el?.scrollWidth || el?.clientHeight < el?.scrollHeight
  );
};

export function getLastVersionFromMdContent(mdContent: string) {
  const contentSplit = mdContent.split('===');
  let lastVersion = '';

  for (let i = 0; i < contentSplit.length; i++) {
    const content = contentSplit[i].trim();

    let parsedContent: { version?: string; date?: string } = {};
    try {
      parsedContent = JSON.parse(content);

      const { version } = parsedContent;

      if (version) {
        lastVersion = version;
        break;
      }
    } catch (error) {}
  }

  return lastVersion;
}

export function getMdContentParsed(mdContent: string) {
  const contentSplit = mdContent.split('===');
  const data = [];

  for (let i = 0; i < contentSplit.length; i++) {
    const content = contentSplit[i].trim();

    try {
      const parsedContent: { version?: string; date?: string } =
        JSON.parse(content);

      const { version, date } = parsedContent;

      if (version) {
        let distanceToNow = '';
        if (date) {
          const parsedDate = parse(date ?? '', 'M/d/yyyy', new Date());
          distanceToNow = formatDistanceToNowStrict(parsedDate, {
            addSuffix: true,
            roundingMethod: 'round',
          });
        }

        data.push({
          ...parsedContent,
          markdown: `## *${version}*${date ? ` *${date}*` : ''}\n---${
            contentSplit[i + 1]
          }`,
          distanceToNow,
        });
        i++; // Skip next step (content)
      }
    } catch (error) {}
  }

  return data;
}

export const openSeaCollectionRE = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
export const decIntOnly = /^\d+(\.\d{0,8})?$/;
export const decIntSixDecimals = /^\d+(\.\d{0,6})?$/;
export const evmContractAddressRE = /^(0x)?[0-9a-f]{40}$/i;
export const flowContractNameRE = /^[A-Z_a-z]+[0-9A-Z_a-z]*$/;
export const flowContractAddressRE = /[\s]/;
export const flowContractPrefix = /^(A|A\.)/;
export const tokenIdRE = /[?:/]/;
export const tokenIdValidUrlRE = /^(https|ipfs|http):\/\/[^ "]+$/;
export const rippleRE = /^r[0-9a-zA-Z]{24,34}$/;
export const expiredTokenRE = /verify AE token|Slug/gi;
