import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/reducerUtils';
import { getCollabClient } from 'sdk';
import { addToast } from 'store/toasts/actionCreators';
import { GuestPass } from 'custom-types';
import { GuestPassState } from '../types';

const initialState: GuestPassState = {
  items: [],
  loading: false,
  error: null,
  activeCommunity: '',
};

export const getGuestPasses = createAsyncThunk(
  'guestPass/getGuestPasses',
  async (communityId: string) => {
    const data = (await getCollabClient().guestPass.getGuestPasses(
      communityId,
    )) as unknown as { items: GuestPass[] };

    return {
      data,
      communityId,
    };
  },
);

function daysToSeconds(days: number) {
  return days * 24 * 60 * 60;
}

export const createGuestPass = createAsyncThunk(
  'guestPass/createGuestPass',
  async (
    {
      communityId,
      pass,
    }: {
      communityId: string;
      pass: GuestPass & { roleId: string | undefined };
    },
    { dispatch },
  ) => {
    // try {
    const newPass = {
      name: pass.name,
      roleSet: pass.roleSet,
      period: daysToSeconds(pass.period),
      platform: pass.platform,
    };

    await getCollabClient().guestPass.createGuestPass(communityId, newPass);
    await dispatch(
      addToast({
        status: 'success',
        description: 'Guest Pass was created successfully',
      }),
    );
    await dispatch(getGuestPasses(communityId));
    // } catch (err: any) {
    //   dispatch(
    //     addToast({
    //       status: 'error',
    //       description: err.message,
    //     }),
    //   );

    //   throw err;
    // }
  },
);

export const removeGuestPass = createAsyncThunk(
  'guestPass/removeGuestPass',
  async (
    { communityPK, sk }: { communityPK: string; sk: string },
    { dispatch },
  ) => {
    // try {
    await getCollabClient().guestPass.deleteGuestPass(communityPK, sk);
    await dispatch(
      addToast({
        status: 'success',
        description: 'Guest Pass was deleted successfully',
      }),
    );
    await dispatch(getGuestPasses(communityPK));
    // } catch (err: any) {
    //   await dispatch(
    //     addToast({
    //       status: 'error',
    //       description: err.message,
    //     }),
    //   );
    // }
  },
);

const guestPassSlice = createSlice({
  name: 'guestPass',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGuestPasses.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.items = [];
      })
      .addCase(getGuestPasses.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.items = payload.data.items;
        state.activeCommunity = payload.communityId;
      })
      .addCase(getGuestPasses.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message ?? 'Failed to retrieve guest passes';
      })
      .addCase(createGuestPass.pending, (state) => {
        state.loading = true;
      })
      .addCase(createGuestPass.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createGuestPass.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(removeGuestPass.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeGuestPass.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(removeGuestPass.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

export const { clearError, setError } = guestPassSlice.actions;

export default guestPassSlice.reducer;
