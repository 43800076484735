import React from 'react';
import cx from 'classnames';

import Modal from 'components/Modal';
import Button from 'components/Button';

import fonts from 'styles/typography.module.scss';
import s from './BotConfigModal.module.scss';

export type BotConfigModalProps = {
  close: () => void;
  click: () => void;
};

const BotConfigModal: React.FC<BotConfigModalProps> = ({ close, click }) => {
  return (
    <Modal className={s.content}>
      <h4 className={cx(fonts.title2, s.title)}>Warning</h4>
      <p className={cx(s.title)}>
        The Bot is configured incorrectly, and will not function as expected.
      </p>

      <div className={s.row}>
        <Button className={s.cancel} color="secondary" onClick={close}>
          Cancel
        </Button>

        <Button className={s.submit} onClick={click}>
          Details
        </Button>
      </div>
    </Modal>
  );
};

export default BotConfigModal;
