import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/reducerUtils';
import { addToast } from '../toasts/actionCreators';
import { getPremiumSupportLink } from './service';
import { PremiumSupportState } from '../types';

export const PREMIUM_SUPPORT_FEATURE_KEY = 'premium-support';

const initialPricingState: PremiumSupportState = {
  loading: false,
};

export const getDiscordSupportChannelLink = createAsyncThunk(
  PREMIUM_SUPPORT_FEATURE_KEY,
  async (
    communityId: string,
    { dispatch },
  ): Promise<{ link: string; code: string }> => {
    try {
      return getPremiumSupportLink(communityId);
    } catch (err: any) {
      dispatch(
        addToast({
          status: 'error',
          description: err.message,
        }),
      );

      throw err;
    }
  },
);

const premiumSupportSLice = createSlice({
  name: PREMIUM_SUPPORT_FEATURE_KEY,
  initialState: initialPricingState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDiscordSupportChannelLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getDiscordSupportChannelLink.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.link = payload.link;
        state.code = payload.code;
      },
    );
    builder.addCase(getDiscordSupportChannelLink.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default premiumSupportSLice.reducer;
