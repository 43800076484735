import React from 'react';
import cx from 'classnames';

import styles from './Modal.module.scss';

export type ModalProps = {
  children?: React.ReactNode;
  rootClassName?: string;
  className?: string;
  onClick?: () => void;
};

const Modal: React.FC<ModalProps> = ({
  children,
  rootClassName = '',
  className = '',
}) => (
  <div className={cx(styles.root, rootClassName)}>
    <div className={cx(styles.container, className)}>{children}</div>
  </div>
);

export default Modal;
