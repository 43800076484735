import React, { ReactNode } from 'react';
import { ButtonProps } from 'components/Button/Button';
import { InfoIcon, FailIcon, SuccessIcon, CloseIcon } from 'icons';

import s from './NotificationBanner.module.scss';

type NotificationBannerProps = Omit<ButtonProps, 'renderContainer'> & {
  close?: () => void;
  msgClass: string;
  msgTitle: string;
  msgContents: (string | { msg: ReactNode; lnk?: string })[];
};

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  close,
  msgClass,
  msgTitle,
  msgContents,
}: NotificationBannerProps) => (
  <div className={s[msgClass]}>
    {msgClass !== 'infoMsg' && (
      <div className={s.msgHeader}>
        {msgClass === 'infoMsg' && (
          <InfoIcon className={s.infoIcon} title={`'${msgClass} icon'`} />
        )}
        {msgClass === 'warningMsg' && (
          <FailIcon className={s.errorIcon} title={`'${msgClass} icon'`} />
        )}
        {msgClass === 'successMsg' && (
          <SuccessIcon className={s.successIcon} title={`'${msgClass} icon'`} />
        )}
        <h3>{msgTitle}</h3>
        {close && (
          <button type="button" className={s.closeButton} onClick={close}>
            <CloseIcon className={s.xIcon} title="close alt dark" />
          </button>
        )}
      </div>
    )}
    <div className={s.msgRow}>
      <div className={s.msgInnerContent}>
        {msgClass === 'infoMsg' ? (
          <div className={s.msgHeader}>
            <InfoIcon className={s.infoIcon} title="info icon" />
            {
              <p>
                {typeof msgContents?.[0] === 'string' ? msgContents[0] : ''}
              </p>
            }
            {close && (
              <button type="button" className={s.closeButton} onClick={close}>
                <CloseIcon className={s.xIcon} title="close alt dark" />
              </button>
            )}
          </div>
        ) : (
          <p>
            {typeof msgContents[0] === 'string'
              ? msgContents[0]
              : msgContents[0].msg}
          </p>
        )}
        {msgContents.length > 1 && (
          <ul>
            {msgContents.slice(1).map((error, i) => (
              <li key={`list-item-${i}`}>
                {typeof error === 'string' ? (
                  error
                ) : (
                  <>
                    {error.msg}
                    {error.lnk && (
                      <a
                        className={s.errorLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={error.lnk}
                      >
                        View More
                      </a>
                    )}
                  </>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
    {/* msgContents.length > 1 && (
      <Button
        color="secondary-dark"
        className={s.viewGuideButton}
        onClick={() =>
          window.open('https://collabland.freshdesk.com/support/home', '_blank')
        }
      >
        View guide
      </Button>
      ) */}
  </div>
);

export default NotificationBanner;
