import { FC, MouseEventHandler } from 'react';
import cx from 'classnames';

import s from './Overlay.module.scss';

interface OverlayInterface {
  visible?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const Overlay: FC<OverlayInterface> = ({ visible = true, onClick }) => {
  return (
    <div
      className={cx(s.root, !visible && s.hidden)}
      aria-label="overlay"
      role="button"
      tabIndex={0}
      onClick={onClick}
    />
  );
};

export default Overlay;
