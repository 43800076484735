import cx from 'classnames';
import Button from 'components/Button';
import CopyButton from 'components/CopyButton';
import Modal from 'components/Modal';
import SupportForm from 'components/SupportForm';
import { CopyIcon, CustomSvg, ListIcon } from 'icons';
import React, { useState } from 'react';
import { createSupportTicket } from 'store/community/actionCreators';
import { useDispatch } from 'store/hooks';

import { SupportFormData } from 'custom-types';
import fonts from 'styles/typography.module.scss';
import s from './SupportModal.module.scss';

export type SupportModalProps = {
  close: () => void;
};

const SupportModal: React.FC<SupportModalProps> = ({ close }) => {
  // Phase 0 is modal with selections
  // Phase 1 is the submit request modal
  // Phase 2 is the success after submit along with the ticket number
  const [ticketPhase, setTicketPhase] = useState(0);
  const [ticketId, setTicketId] = useState('');
  const [submitLoader, setSubmitLoader] = useState(false);
  const dispatch = useDispatch();

  const submitHandler = (body: SupportFormData) => {
    setSubmitLoader(true);
    dispatch(
      createSupportTicket(body, (_error, ticketId) => {
        setTicketPhase(2);
        setTicketId(ticketId!);
        setSubmitLoader(false);
      }),
    );
  };

  return (
    <Modal className={ticketPhase === 1 ? s.contentFullScreen : s.content}>
      <h4 className={cx(fonts.title2, s.title)}>
        {ticketPhase === 0 && 'How would you like to contact us?'}
        {ticketPhase === 0 && (
          <p>
            <span>Check the support articles for troubleshooting</span>
            <span>or submit a request</span>
          </p>
        )}
        {ticketPhase === 1 && 'Contact us'}{' '}
        {ticketPhase === 1 && (
          <div>
            <img
              src={CustomSvg.fileGray}
              className={s.fileIcon}
              alt="file icon"
            />
            <span>
              Check the support articles for troubleshooting{' '}
              <a
                className={s.supportLink}
                target="_blank"
                rel="noopener noreferrer"
                href="https://collabland.freshdesk.com/support/solutions"
              >
                here
              </a>
            </span>
          </div>
        )}
      </h4>
      {ticketPhase === 2 && (
        <div className={s.successContainer}>
          <h4 className={cx(fonts.title2, s.title)}>
            <div>
              <img
                src={CustomSvg.supportWhite}
                className={s.supportIcon}
                alt="support icon"
              />
              Success!
            </div>
            <p style={{ marginTop: 25, marginBottom: 25 }}>
              <span style={{ color: '#ffffff', fontSize: 16 }}>
                The submission was succesful
              </span>
            </p>
            <p>
              <span className={s.ticketNumber}>Ticket number</span>
              <div className={s.ticketIdCont}>
                <span>{ticketId}</span>
                <CopyButton id="communityId-copy-button" value={ticketId}>
                  <CopyIcon className={s.copyIcon} title="copy icon" />
                </CopyButton>
              </div>
            </p>
            <Button
              className={cx(s.alignedButton, s.okButton)}
              onClick={() => setTicketPhase(0)}
            >
              Okay
            </Button>
          </h4>
        </div>
      )}
      {ticketPhase === 0 && (
        <div className={s.buttonsContainer}>
          <Button
            className={cx(s.alignedButton, s.contactButton)}
            onClick={() =>
              window.open('https://docs.collab.land/help-docs/intro/', '_blank')
            }
            data-tracking-info={JSON.stringify({
              id: 'button:read-support-articles:click',
            })}
          >
            <img
              src={CustomSvg.fileBlack}
              className={s.fileIconButton}
              alt="file icon"
            />
            <div className={s.iconLabel}>Read support articles</div>
          </Button>

          <Button
            className={cx(s.alignedButton, s.contactButton)}
            onClick={() => setTicketPhase(1)}
            data-tracking-info={JSON.stringify({
              id: 'button:submit-support-request:click',
            })}
          >
            <img
              src={CustomSvg.freshdesk}
              className={s.icon}
              alt="freshdesk icon"
            />
            Submit a request
          </Button>

          {/*<Button*/}
          {/*  className={cx(s.alignedButton, s.contactButton)}*/}
          {/*  onClick={() => {*/}
          {/*    window.open('https://airtable.com/shr46oOOqbLOn0Gmm', '_blank');*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <ListIcon className={s.icon} />*/}
          {/*  Admin Survey*/}
          {/*</Button>*/}

          <Button
            color="secondary"
            className={cx(s.alignedButton, s.cancel)}
            onClick={close}
          >
            Cancel
          </Button>
        </div>
      )}
      {ticketPhase === 1 && (
        <SupportForm
          onCancel={() => setTicketPhase(0)}
          onSubmit={(body) => submitHandler(body)}
          submitLoader={submitLoader}
        />
      )}
    </Modal>
  );
};

export default SupportModal;
