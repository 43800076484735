import { Subreddit } from 'custom-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'store/hooks';
import { getCollabClient } from 'sdk';
import { TOKEN_EXPIRED } from 'store/user/actionTypes';

const useSubreddits = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [subreddits, setSubreddits] = useState<Subreddit[]>([]);

  useEffect(() => {
    async function fetchSubreddits() {
      try {
        const response =
          (await getCollabClient().reddit.getAdminCommunities()) as {
            items: Subreddit[];
          };
        const result = response.items.map(({ communityId, ...rest }) => ({
          ...rest,
          id: communityId!,
        }));
        setSubreddits(result);
      } catch (e) {
        const status = e instanceof Response ? e.status : 'Unknown error.';
        if (status === 401) dispatch({ type: TOKEN_EXPIRED });
      }
      setLoading(false);
    }
    setLoading(true);
    fetchSubreddits();
  }, [dispatch]);

  return { subreddits, loading };
};

export default useSubreddits;
