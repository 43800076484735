import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import roleGuide from 'images/roleGuide.png';
import s from './DiscordInviteCard.module.scss';
import { useNavigate } from 'react-router-dom';
import usePopup, { PopUpEvents } from '../../../../hooks/usePopupWindow';
import { useDispatch, useSelector } from '../../../../store/hooks';
import { getBotUrl } from '../../../../constants/community';
import { getCommunitiesAction } from '../../../../store/community/actionCreators';
import httpClient from '../../../../sdk';
import Spinner from '../../../Spinner';

const DiscordInviteCard = ({
  onSuccess,
  onClose,
}: {
  onSuccess: () => void;
  onClose: () => void;
}) => {
  const loading = useSelector((state) => state.community.loading);
  const [invitedGuild, setInvitedGuild] = useState<undefined | any>(undefined);
  const user = useSelector((state) => state.user);
  const popup = usePopup();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClickInstall = (community: any) => {
    popup.open(
      PopUpEvents.BOT_INVITED,
      getBotUrl('discord', community.id),
      () => {
        dispatch(getCommunitiesAction());
        setInvitedGuild(community);
        onSuccess();
      },
      400,
      700,
    );
  };
  return (
    <>
      {!invitedGuild && (
        <>
          <div className={s.heading}>Welcome, {user['user_profile'].name}!</div>
          <div className={s.contextText}>
            This is the Command Center. It is where you will set up and
            configure the Collab.Land Bot.
          </div>
          <div className={s.heading2}>Select a Server to install the bot</div>
          <div className={s.contextText2}>
            Make sure to not alter any of the bots default permissions or it may
            not work correctly.
          </div>
        </>
      )}
      {invitedGuild && (
        <>
          <div className={s.heading}>Success!</div>
          <div className={s.contextText}>
            The Collab.Land Bot is now installed into {invitedGuild.name}.
          </div>
          <div className={s.heading2}>Some quick tips</div>
          <div className={s.contextText2}>
            Make sure your bot is above any roles that you want it to have
            control over.
          </div>
          <img className={s.roleGuide} alt="Role Guide" src={roleGuide} />
          <div className={s.contextText2}>
            In your server, you will see two new channels: <br />
            <b>collabland-config</b> and <b>collabland-join</b>.
            <br />
            <br />
            <b>Do not delete these channels.</b>
          </div>
          <Button
            className={s.tgrButton}
            disabled={loading}
            onClick={() => {
              navigate(`/dashboard/${invitedGuild.id}/tgrs`);
              onClose();
            }}
            data-tracking-info={JSON.stringify({
              id: 'button:new-setup-a-tgr:click',
              server_id: invitedGuild.id,
            })}
          >
            Set Up A Token Granted Role
          </Button>
        </>
      )}
      {!invitedGuild && (
        <div className={s.listContainer}>
          <CommunitiesList onClickInstall={onClickInstall} />
        </div>
      )}
    </>
  );
};

const CommunitiesList = ({
  onClickInstall,
}: {
  onClickInstall: (c: any) => void;
}) => {
  const [communities, setCommunities] = useState<undefined | any[]>(undefined);
  useEffect(() => {
    console.log(communities);
    if (!communities) {
      httpClient('/discord/communities')
        .then((data) => {
          setCommunities(
            data.sort((a: any, b: any) =>
              b.isInvited === a.isInvited ? 0 : b.isInvited ? -1 : 1,
            ),
          );
        })
        .catch((error) => error);
    }
  }, []);
  return (
    <>
      {communities ? (
        communities.map((c) => (
          <div className={s.item} key={c.id}>
            <label>{c.name}</label>
            {c.isInvited ? (
              <Button className={s.installedButton} color={'empty'}>
                Installed
              </Button>
            ) : (
              <Button
                className={s.installButton}
                onClick={() => onClickInstall(c)}
                data-tracking-info={JSON.stringify({
                  id: 'button:install-bot:click',
                  server_id: c.id,
                  server_name: c.name,
                })}
              >
                Install
              </Button>
            )}
          </div>
        ))
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default DiscordInviteCard;
