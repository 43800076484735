import { addToast } from '../store/toasts/actionCreators';
import { useDispatch } from 'store/hooks';

export default function useClipboard() {
  const dispatch = useDispatch();
  const copy = async (textToCopy: string, textIdentifier?: string) => {
    await navigator.clipboard.writeText(textToCopy);
    dispatch(
      addToast({
        status: 'success',
        description: textIdentifier
          ? `${textIdentifier} is copied to clipboard`
          : 'Copied to clipboard.',
      }),
    );
  };

  return { copy };
}
