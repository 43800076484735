import React from 'react';
import cx from 'classnames';

import Modal from 'components/Modal';
import Button from 'components/Button';

import fonts from 'styles/typography.module.scss';
import s from './LogOutModal.module.scss';

export type LogOutModalProps = {
  close: () => void;
  callback?: () => void;
};

const LogOutModal: React.FC<LogOutModalProps> = ({ close, callback }) => (
  <Modal>
    <h4 className={cx(fonts.title2, s.title)}>Log out</h4>

    <p className={cx(fonts.body1, s.text)}>Are you sure you want to log out?</p>

    <div>
      <Button
        type="button"
        color="secondary"
        className={s.cancel}
        onClick={close}
      >
        Cancel
      </Button>

      <Button
        type="button"
        color="danger"
        className={s.submit}
        onClick={callback}
        data-tracking-info={JSON.stringify({
          id: 'button:log-out:click',
        })}
      >
        Log out
      </Button>
    </div>
  </Modal>
);

export default LogOutModal;
