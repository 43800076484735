import React, { useEffect, useRef, useState } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import StartScreen from 'screens/StartScreen';
import DashboardScreen from 'screens/DashboardScreen';

import { useDispatch, useSelector } from 'store/hooks';
import { checkUserToken, login } from 'store/user';
import Spinner from 'components/Spinner';
import { preloadPlans } from '../../store/pricing';
import usePopup, { PopUpEvents } from '../../hooks/usePopupWindow';
import { Mixpanel } from '../../mixpanel';
import Cookies from 'js-cookie';

const RequireAuth: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const hasMounted = useRef(false);
  const [params, setSearchParams] = useSearchParams();
  const fragment = location.hash.substring(1); // Get the fragment identifier excluding the "#"
  const urlAccessToken = new URLSearchParams(fragment).get('access_token');
  const [tokenFound, setTokenFound] = useState(true);
  const popup = usePopup();
  const { isUserLoaded, isTokenExpired } = useSelector((s) => s.user);

  useEffect(() => {
    const cvState = params.get('cv_state');
    if (cvState) {
      const minutes = 10;
      const expirationTime = new Date(
        new Date().getTime() + minutes * 60 * 1000,
      ); // Convert minutes to milliseconds
      Cookies.set('cvState', cvState, { expires: expirationTime });
      localStorage.setItem('cvState', cvState);
    }
    if (hasMounted.current) return;
    hasMounted.current = true;
    const token = localStorage.getItem('collabLandToken');
    if (token && !urlAccessToken) {
      dispatch(checkUserToken());
    } else if (urlAccessToken) {
      popup.postMessage(PopUpEvents.LOGGED_IN, { token: urlAccessToken });
      setSearchParams(new URLSearchParams());
      dispatch(login({ token: urlAccessToken! }));
    } else {
      setTokenFound(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isUserLoaded && tokenFound && !isTokenExpired) {
    return <Spinner fullPage />;
  }

  if (isTokenExpired || !tokenFound) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

const App: React.FC = () => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const isTokenExpired = useSelector((s) => s.user.isTokenExpired);
  const dispatch = useDispatch();
  const popup = usePopup();
  useEffect(() => {
    if (params.get('state') === 'bot-invited') {
      popup.postMessage(PopUpEvents.BOT_INVITED);
    }
    dispatch(preloadPlans());
    const handleClick = (event: any) => {
      let trackingInfo = event.target.getAttribute('data-tracking-info');
      if (trackingInfo) {
        trackingInfo = JSON.parse(trackingInfo);
        Mixpanel.track(trackingInfo.id, {
          ...trackingInfo,
        });
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [dispatch]);

  return (
    <Routes>
      <Route
        index
        element={<Navigate to={{ ...location, pathname: '/dashboard' }} />}
      />
      <Route
        path="/login"
        element={<StartScreen tokenExpired={isTokenExpired} />}
      />
      <Route element={<RequireAuth />}>
        <Route path="/dashboard/*" element={<DashboardScreen />} />
      </Route>
    </Routes>
  );
};

export default App;
