import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'store/hooks';
import { updateCommunityConfig } from 'store/community/actionCreators';
import Switch from 'components/Switch';
import fonts from 'styles/typography.module.scss';
import s from '../BotConfig.module.scss';
import { enableProBotFeatures } from '../../../constants/features';
import Badge from '../../Badge';

interface FeatureToggleProps {
  activeCommunityId: string;
  name: string;
  description: string;
  keyword: 'disableBkgBalCheck' | 'disablePromotionalUpdates';
  disabled: boolean;
}

const CommunityConfigToggle: FC<FeatureToggleProps> = ({
  activeCommunityId,
  name,
  description,
  keyword,
  disabled,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(false);

  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );

  useEffect(() => {
    const communityFromState = communitiesFromState.find(
      (community) => community.communityId === activeCommunityId,
    );
    if (communityFromState) {
      setValue(!(communityFromState as any)[keyword]);
    }
  }, [activeCommunityId, communitiesFromState]);

  const toggleFeature = (val: boolean) => {
    dispatch(
      updateCommunityConfig(activeCommunityId, {
        [keyword]: !val,
      }),
    );

    setValue(val);
  };

  return (
    <>
      {enableProBotFeatures && (
        <div className={s.row}>
          <div className={s.column}>
            <h6 className={cx(s.subtitle, fonts.title3)}>
              {name} <Badge>Pro</Badge>
            </h6>
            <p className={cx(s.text, fonts.caption1)}>{description}</p>
          </div>
          <Switch
            onChange={toggleFeature}
            isChecked={value}
            isDisabled={disabled}
          />
        </div>
      )}
    </>
  );
};

export default CommunityConfigToggle;
