import * as actionTypes from './actionTypes';

export const clearToasts = () => ({
  type: actionTypes.CLEAR_TOASTS,
});

export const addToast = (toastItem: {
  status: string;
  description: string;
}) => ({
  type: actionTypes.ADD_TOAST,
  payload: toastItem,
});
