import { createAsyncThunk } from 'store/reducerUtils';
import { MarketplaceApp } from 'custom-types';
import { getCollabClient } from 'sdk';
import { RootState } from 'store';
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

interface MarketplaceState {
  loading: boolean;
  items: MarketplaceApp[];
  activeCommunityPk: string;
  whitelistStatus: boolean;
  clTokenBalance: string | null;
  installModalOpen: boolean;
  installModalLoading: boolean;
  error: string | null;
  successResponse?: Record<string, string> | null;
  currentlyInstalledApp?: string;
}

const initialState: MarketplaceState = {
  loading: false,
  items: [],
  activeCommunityPk: '',
  whitelistStatus: true,
  clTokenBalance: null,
  installModalOpen: false,
  installModalLoading: false,
  error: null,
  successResponse: null,
};

const fullSerializeError = (err: any) => {
  return {
    name: err.name,
    message:
      err?.response?.obj?.error?.message ??
      err.message ??
      'Something went wrong.',
    stack: err.stack,
    statusCode: err.statusCode,
  };
};

export const getApplications = createAsyncThunk<MarketplaceApp[], string>(
  'marketplace/getApplications',
  async (communityPk: string) => {
    // try {
    const response = await getCollabClient().miniApp.getMiniAppsForCommunity(
      communityPk,
    );
    return response.items as MarketplaceApp[];

    // Bringing in the old error checks
    // if (e.status === 401) {
    //   dispatch({ type: TOKEN_EXPIRED });
    //   return;
    // }
    // if (e.status === 403) {
    //   dispatch(changeWhitelistStatus(false, communityPk));
    //   return;
    // }
    // dispatch(
    //   getApplicationsError(
    //     ,
    //     communityPk,
    //   ),
    // );
    // }
  },
  {
    serializeError: fullSerializeError,
  },
);

export const installApp = createAsyncThunk<
  { app: MarketplaceApp; communityPk: string },
  { appId: string },
  { state: RootState }
>(
  'marketplace/installApp',
  async ({ appId }, { getState }) => {
    const communityPk = getState().marketplace.activeCommunityPk;
    const cvState = Cookies.get('cvState');
    if (
      (appId.startsWith('charm-verse') || appId.startsWith('charmverse')) &&
      cvState
    ) {
      const response = (await getCollabClient().miniApp.installMiniApp(
        communityPk,
        appId,
        {
          state: cvState,
        },
      )) as unknown as {
        success: boolean;
        message: string;
        data: MarketplaceApp;
      };
      if (response.success === false) {
        throw response;
      }
      Cookies.remove('cvState');
      return { app: { ...response.data, appId }, communityPk };
    }
    // Overriding the type definition here because it's incorrect
    // @ts-ignore
    const response = (await getCollabClient().miniApp.installMiniApp(
      communityPk,
      appId,
    )) as unknown as {
      success: boolean;
      message: string;
      data: MarketplaceApp;
    };
    if (response.success === false) {
      throw response;
    }
    return { app: { ...response.data, appId }, communityPk };
  },
  {
    serializeError: fullSerializeError,
  },
);

export const uninstallApp = createAsyncThunk<
  { app: MarketplaceApp; communityPk: string },
  { appId: string },
  { state: RootState }
>('marketplace/uninstallApp', async ({ appId }, { getState }) => {
  const communityPk = getState().marketplace.activeCommunityPk;

  // Overriding the type definition here because it's incorrect
  const response = (await getCollabClient().miniApp.uninstallMiniApp(
    communityPk,
    appId,
  )) as {
    success: boolean;
    message: string;
    data: MarketplaceApp;
  };
  if (response.success === false) {
    throw response;
  }
  return { app: { ...response.data, appId }, communityPk };
});

const roleManagerSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    toggleInstallModal(state) {
      const shouldOpen = !state.installModalOpen;
      state.installModalOpen = !state.installModalOpen;

      // Clear the successResponse and error values when closing the modal
      state.successResponse = shouldOpen ? state.successResponse : null;
      state.error = shouldOpen ? state.error : null;
    },
    clearCurrentlyInstalledApp(state) {
      state.currentlyInstalledApp = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getApplications.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.whitelistStatus = true;
      })
      .addCase(getApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
        state.activeCommunityPk = action.meta.arg;
      })
      .addCase(getApplications.rejected, (state, action) => {
        state.loading = false;
        state.installModalLoading = false;
        state.items = [];
        state.error = action.error.message!;
        state.activeCommunityPk = action.meta.arg;
      })
      .addCase(installApp.pending, (state) => {
        state.installModalLoading = true;
        state.error = null;
      })
      .addCase(installApp.fulfilled, (state, action) => {
        const { app } = action.payload;
        const shouldShowSuccessResponse =
          Boolean(app.isInstalled) &&
          Object.keys(app.metadata ?? {}).length > 0;

        // state.installModalOpen = shouldShowSuccessResponse;
        state.installModalLoading = false;
        state.items = state.items.map((item) =>
          item.appId === app.appId ? { ...item, ...app } : item,
        );
        state.currentlyInstalledApp = app.appId;
        state.successResponse = shouldShowSuccessResponse ? app.metadata : null;
      })
      .addCase(installApp.rejected, (state, action) => {
        state.installModalLoading = false;
        state.error = action.error.message!;
      })
      .addCase(uninstallApp.pending, (state) => {
        state.installModalLoading = true;
        state.error = null;
      })
      .addCase(uninstallApp.fulfilled, (state, action) => {
        const { app } = action.payload;
        const shouldShowSuccessResponse =
          Boolean(app.isInstalled) &&
          Object.keys(app.metadata ?? {}).length > 0;

        state.installModalOpen = shouldShowSuccessResponse;
        state.installModalLoading = false;
        state.items = state.items.map((item) =>
          item.appId === app.appId ? { ...item, ...app } : item,
        );
        state.successResponse = shouldShowSuccessResponse ? app.metadata : null;
      })
      .addCase(uninstallApp.rejected, (state, action) => {
        state.installModalLoading = false;
        state.error = action.error.message!;
      });
  },
});

export const { toggleInstallModal, clearCurrentlyInstalledApp } =
  roleManagerSlice.actions;

export default roleManagerSlice.reducer;
