import React, { useState } from 'react';
import cx from 'classnames';
import { InfoIcon } from 'icons';
import fonts from 'styles/typography.module.scss';
import styles from './TextField.module.scss';

export type TextFieldProps = {
  className?: string;
  inputClassName?: string;
  inputContainerClassName?: string;
  required?: boolean;
  leftIcon?: string;
  rightIcon?: string;
  labelClassName?: string;
  placeholder?: string;
  multiline?: boolean;
  label?: string;
  name: string;
  value: string;
  disabled?: boolean;
  isLabelError?: boolean;
  error?: string;
  warning?: string;
  action?: JSX.Element;
  outline?: boolean;
  reference?: any;
  type?: 'text' | 'number' | 'email' | 'password';
  onChange: (value: string) => void;
  onKeyDown?: (value: string) => void;
  onBlur?: (value: string) => void;
};

const TextField: React.FC<TextFieldProps> = ({
  className,
  inputContainerClassName,
  leftIcon,
  rightIcon,
  required,
  inputClassName,
  labelClassName,
  placeholder,
  multiline = false,
  label,
  name,
  value,
  disabled = false,
  isLabelError = true,
  error,
  warning,
  action,
  outline = true,
  reference,
  type = 'text',
  onChange,
  onKeyDown,
  onBlur = () => null,
}: TextFieldProps) => {
  const [focus, setFocus] = useState<boolean>(false);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.labelContainer}>
        {label &&
          (error && isLabelError ? (
            <div className={styles.errorLabel}>
              <label
                className={cx(styles.label, fonts.title5, labelClassName)}
                htmlFor={`input-${name}`}
              >
                {label}
              </label>
              <InfoIcon
                className={styles.inlineErrorMsgInfo}
                title="info icon"
              />
            </div>
          ) : (
            <label
              className={cx(styles.label, fonts.title5, labelClassName)}
              htmlFor={`input-${name}`}
            >
              {label}
            </label>
          ))}
        {required && <span>(Required)</span>}
      </div>
      <label
        htmlFor={`input-${name}`}
        className={cx(styles.inputContainer, inputContainerClassName, {
          [styles.withError]: error,
          [styles.withWarning]: warning,
          [styles.disabled]: disabled,
          [styles.outline]: outline,
          [styles.focus]: focus,
          [styles.pressed]: focus && value,
        })}
      >
        {leftIcon && (
          <img alt="left icon" className={styles.leftIcon} src={leftIcon} />
        )}
        {multiline ? (
          <textarea
            id={`input-${name}`}
            ref={reference}
            className={cx(
              styles.input,
              styles.textarea,
              inputClassName,
              fonts.body1,
            )}
            value={value}
            placeholder={placeholder}
            onChange={(e): void => onChange(e.currentTarget.value)}
            onFocus={(): void => setFocus(true)}
            onBlur={(): void => setFocus(false)}
            disabled={disabled}
          />
        ) : (
          <input
            id={`input-${name}`}
            ref={reference}
            type={type}
            className={cx(styles.input, inputClassName, fonts.body1, {
              [styles.withLeftIcon]: leftIcon,
              [styles.withRightIcon]: rightIcon,
            })}
            value={value}
            placeholder={placeholder}
            onChange={(e): void => onChange(e.currentTarget.value)}
            onFocus={(): void => setFocus(true)}
            onBlur={(val): void => {
              setFocus(false);
              onBlur(val.target.value);
            }}
            onKeyDown={(e): void => {
              if (e.key === 'Enter' && onKeyDown) {
                return onKeyDown(e.currentTarget.value);
              }
            }}
            disabled={disabled}
          />
        )}
        {rightIcon && (
          <img alt="right icon" className={styles.leftIcon} src={rightIcon} />
        )}
        {action && <div className={styles.action}>{action}</div>}
      </label>
      {error && !isLabelError && (
        <p className={styles.error}>
          <InfoIcon className={styles.inlineErrorMsgInfo} title="info icon" />
          <span className={fonts.caption1}>{error}</span>
        </p>
      )}
    </div>
  );
};

export default TextField;
