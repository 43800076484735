import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/hooks';
import {
  checkCommunityBotConf,
  hideBotBanner,
  setBotModalShown,
  showBotBanner,
} from 'store/discordBot';
import {
  clearError,
  toggleRedditConnectModal,
} from 'store/community/actionCreators';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Logo from 'images/Logo-Mark-Color.svg';
import { alphabeticalSort, getNameInitials } from 'utils';
import { PlusIcon } from 'icons';
import BotConfigModal from 'components/BotConfigModal';
import fonts from 'styles/typography.module.scss';
import useScreenSize from 'hooks/useScreenSize';
import s from './ServerNavigation.module.scss';
import InviteCommunityModal from '../InviteCommunityModal';
import { modalActions } from '../../store/modal';
import usePlanHook from '../../hooks/usePlanHook';

interface ServerNavigationInterface {
  visible?: boolean;
}

type RouteParams = {
  communityId: string;
};

const ServerNavigation: React.FC<ServerNavigationInterface> = ({
  visible = true,
}) => {
  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { communityId: activeCommunityId } =
    useParams<RouteParams>() as NonNullable<RouteParams>;

  const showModal = useSelector((state) => {
    if (activeCommunityId && state.discordBot[activeCommunityId])
      return state.discordBot[activeCommunityId].showModal;
    return null;
  });
  const { isInviteServerModalOpen, inviteModalPlatform } = useSelector(
    (state) => {
      return state.modal;
    },
  );

  const isModalShown = useSelector((state) =>
    Boolean(
      activeCommunityId && state.discordBot?.[activeCommunityId]?.isModalShown,
    ),
  );
  const shownForSelected = useSelector((state) =>
    Boolean(
      activeCommunityId && state.discordBot?.[activeCommunityId]?.checked,
    ),
  );
  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );

  const platform = useSelector((state) => state.user.platform);

  useEffect(() => {
    if (
      activeCommunityId &&
      (platform === 'discord' || platform === 'reddit') &&
      !shownForSelected &&
      !isModalShown &&
      communitiesFromState.find((c) => c.communityId === activeCommunityId)
    ) {
      dispatch(
        checkCommunityBotConf({
          communityId: activeCommunityId,
        }),
      );
    }
  }, [
    platform,
    activeCommunityId,
    communitiesFromState,
    shownForSelected,
    isModalShown,
    dispatch,
  ]);

  return (
    <>
      <div className={cx(s.root, !visible && s.hidden)}>
        {!['mobile', 'tablet'].includes(screenSize) && (
          <img src={Logo} className={s.logo} alt="logo icon" />
        )}

        <nav className={s.container}>
          {alphabeticalSort(communitiesFromState, 'name').map((community) => (
            <ServerButton
              community={community}
              activeCommunityId={activeCommunityId}
            />
          ))}

          <button
            type="button"
            className={cx('tooltip-trigger', s.addCommunity)}
            onClick={() => {
              if (platform === 'reddit') {
                dispatch(clearError());
                dispatch(toggleRedditConnectModal());
              } else if (platform === 'discord') {
                dispatch(modalActions.openInviteModal('discord'));
              } else {
                dispatch(modalActions.openInviteModal('telegram'));
              }
            }}
            data-tooltip-content="Add Collab.Land to another server"
            data-tracking-info={JSON.stringify({
              id: 'button:add-to-new-server:click',
            })}
          >
            <PlusIcon className={s.plus} title="add icon" />
          </button>
        </nav>
        {isInviteServerModalOpen && (
          <InviteCommunityModal platform={inviteModalPlatform} />
        )}
        {showModal &&
          !isModalShown &&
          (platform === 'discord' || platform === 'reddit') && (
            <BotConfigModal
              close={() => {
                dispatch(
                  setBotModalShown({
                    communityId: activeCommunityId,
                    isModalShown: true,
                  }),
                );
              }}
              click={() => {
                dispatch(showBotBanner({ communityId: activeCommunityId }));
                dispatch(
                  setBotModalShown({
                    communityId: activeCommunityId,
                    isModalShown: true,
                  }),
                );
                navigate(`/dashboard/${activeCommunityId}/config`);
              }}
            />
          )}
      </div>

      <ReactTooltip
        anchorSelect=".tooltip-trigger"
        place="right"
        variant="dark"
      />
    </>
  );
};

const ServerButton = ({
  community,
  activeCommunityId,
}: {
  community: any;
  activeCommunityId: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const platform = useSelector((state) => state.user.platform);
  const { defaultPlanIfActive } = usePlanHook(community);
  const navigateToSelectedServer = (communityId: string) => {
    if (platform === 'discord') {
      if (defaultPlanIfActive) {
        navigate(`/dashboard/${communityId}/subscription`);
      } else {
        navigate(`/dashboard/${communityId}/tgrs`);
      }
    } else navigate(`/dashboard/${communityId}/tgas`);

    dispatch(
      hideBotBanner({
        communityId: activeCommunityId,
      }),
    );
  };

  return (
    <div key={community.communityId}>
      <>
        <button
          key={community.pk}
          type="button"
          className={cx(
            s.community,
            {
              [s.activeCommunity]: activeCommunityId === community.communityId,
            },
            {
              [s.noImage]: !community.serverImage,
            },
            'tooltip-trigger',
          )}
          onClick={() => navigateToSelectedServer(community.communityId)}
          data-tooltip-content={community.name}
        >
          <ServerNavigationImage
            imageSrc={community.serverImage}
            name={community.name}
          />
        </button>
      </>
    </div>
  );
};

function ServerNavigationImage({
  imageSrc,
  name,
}: {
  imageSrc: string;
  name: string;
}) {
  const [imageError, setImageError] = useState(false);

  if (imageSrc && !imageError)
    return (
      <img
        src={imageSrc}
        alt="server icon"
        onError={() => setImageError(true)}
      />
    );

  return <p className={fonts.title2}>{getNameInitials(name)}</p>;
}

export default ServerNavigation;
