import { useEffect, useRef } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/hooks';

import { getApplications } from 'store/marketplace';
import {
  checkWalletStatus,
  createWallet,
} from 'store/community/actionCreators';
import Spinner from 'components/Spinner';
import EmptySection from 'components/EmptySection';

function useWalletStatus(communityPk: string, enabled: boolean) {
  const dispatch = useDispatch();
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (enabled) {
      intervalRef.current = setInterval(() => {
        dispatch(checkWalletStatus(communityPk));
      }, 15000);
    } else {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [enabled, communityPk, dispatch]);
}

const MarketplaceContainer = () => {
  const dispatch = useDispatch();
  const { communityId } = useParams();

  const { activeCommunityPk, loading, whitelistStatus } = useSelector(
    (state) => state.marketplace,
  );
  const communityFromState = useSelector(
    (state) =>
      state.community.communityGroups.items.find(
        (community) => community.communityId === communityId,
      )!,
  );

  const communityPk = communityFromState.pk;

  const wallet = communityFromState?.wallet?.[0];
  const hasWallet = Boolean(wallet);
  const isWalletActive = wallet?.status === 'active';

  useWalletStatus(communityPk, hasWallet && !isWalletActive);

  useEffect(() => {
    if (!loading && activeCommunityPk !== communityPk && isWalletActive) {
      dispatch(getApplications(communityPk));
    }
  }, [dispatch, communityPk, activeCommunityPk, isWalletActive, loading]);

  const handleCreateWallet = () => {
    dispatch(createWallet(communityPk));
  };

  if (loading) return <Spinner fullPage />;

  if (!hasWallet)
    return (
      <EmptySection
        title={
          <>
            You don&apos;t have a community wallet.
            <br /> Create your wallet below!
          </>
        }
        buttonContent="Create community wallet"
        onButtonClick={handleCreateWallet}
      />
    );

  if (!isWalletActive) {
    return (
      <EmptySection
        title={
          <>
            Creating your community&apos;s wallet...
            <br /> Hang tight!
          </>
        }
      />
    );
  }
  if (!whitelistStatus)
    return (
      <EmptySection title="Marletplace is in Beta and only availabe to whitelisted communities." />
    );

  return <Outlet />;
};

export default MarketplaceContainer;
