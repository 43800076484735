import React, { useState } from 'react';
import cx from 'classnames';

import Modal from 'components/Modal';
import Button from 'components/Button';

import fonts from 'styles/typography.module.scss';
import s from './CancelSubscriptionModal.module.scss';
import { unsubscribe } from '../../store/pricing/service';
import { preloadSubs } from '../../store/pricing';
import { addToast } from '../../store/toasts/actionCreators';
import { useDispatch } from '../../store/hooks';
import { Simulate } from 'react-dom/test-utils';
import Spinner from '../Spinner';

export type BuyPlanModalProps = {
  handleClose: () => void;
  subscriberId: string;
  planId: string;
};

const CancelSubscriptionModal: React.FC<BuyPlanModalProps> = ({
  handleClose,
  planId,
  subscriberId,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleCancel = async () => {
    try {
      setLoading(true);
      await unsubscribe('stripe', planId, subscriberId);
      dispatch(preloadSubs(subscriberId));
      handleClose();
    } catch (err) {
      dispatch(
        addToast({
          description: 'Failed to unsubscribe',
          status: 'error',
        }),
      );
      throw err;
    }
  };
  return (
    <Modal className={s.container}>
      <h4 className={cx(fonts.title2, s.title, s.text)}>Cancel Subscription</h4>
      <p className={cx(s.text, fonts.title3, s.uninstallText)}>
        Your subscription will be active until the end of the current billing
        cycle. You may continue using the app until then.
      </p>
      {loading ? (
        <Spinner />
      ) : (
        <div className={s.buttonsRow}>
          <Button
            className={s.buttonFixWidth}
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button className={s.buttonFixWidth} onClick={handleCancel}>
            Proceed
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default CancelSubscriptionModal;
