import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/reducerUtils';
import { SnapshotState } from '../types';
import { addToast } from 'store/toasts/actionCreators';

type SnapshotSubscriptionRequest = {
  space: string;
  channels: string[];
};

const initialState: SnapshotState = {
  items: [],
  loading: false,
  error: null,
};

export const fetchSnapshotSubscriptions = createAsyncThunk(
  'snapshot/fetchSnapshotSubscriptions',
  async (communityPk: string, { dispatch }) => {
    const token = localStorage.getItem('collabLandToken');
    const url = `${
      process.env.REACT_APP_API_URL
    }/snapshot/communities/${encodeURIComponent(communityPk)}/space`;

    const response = await fetch(url, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const payload = await response.json();

    return payload;
  },
);

export const createSnapshotSubscription = createAsyncThunk(
  'snapshot/createSnapshotSubscription',
  async (
    {
      communityPk,
      formData,
    }: {
      communityPk: string;
      formData: SnapshotSubscriptionRequest;
    },
    { dispatch },
  ) => {
    const token = localStorage.getItem('collabLandToken');

    const url = `${
      process.env.REACT_APP_API_URL
    }/snapshot/communities/${encodeURIComponent(communityPk)}/space`;

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw response;
    }

    dispatch(
      addToast({
        status: 'success',
        description: 'Subscription created successfully',
      }),
    );

    dispatch(fetchSnapshotSubscriptions(communityPk));
  },
);

export const updateSnapshotSubscription = createAsyncThunk(
  'snapshot/updateSnapshotSubscription',
  async (
    {
      communityPk,
      formData,
    }: { communityPk: string; formData: SnapshotSubscriptionRequest },
    { dispatch },
  ) => {
    const token = localStorage.getItem('collabLandToken');
    const url = `${
      process.env.REACT_APP_API_URL
    }/snapshot/communities/${encodeURIComponent(
      communityPk,
    )}/space/${encodeURIComponent(formData.space)}`;
    const requestData = JSON.stringify({
      channels: formData.channels,
    });

    const response = await fetch(url, {
      method: 'put',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: requestData,
    });

    if (!response.ok) {
      throw response;
    }

    dispatch(
      addToast({
        status: 'success',
        description: 'Subscription updated successfully',
      }),
    );

    dispatch(fetchSnapshotSubscriptions(communityPk));
  },
);

export const deleteSnapshotSubscription = createAsyncThunk(
  'snapshot/deleteSnapshotSubscription',
  async (
    { communityPk, spaceId }: { communityPk: string; spaceId: string },
    { dispatch },
  ) => {
    const url = `${
      process.env.REACT_APP_API_URL
    }/snapshot/communities/${encodeURIComponent(
      communityPk,
    )}/space/${encodeURIComponent(spaceId)}`;
    const token = localStorage.getItem('collabLandToken');

    const response = await fetch(url, {
      method: 'delete',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    dispatch(
      addToast({
        status: 'success',
        description: 'Subscription updated successfully',
      }),
    );

    dispatch(fetchSnapshotSubscriptions(communityPk));
  },
);

const snapshotSlice = createSlice({
  name: 'snapshot',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSnapshotSubscriptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSnapshotSubscriptions.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.items = payload.items;
      })
      .addCase(fetchSnapshotSubscriptions.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message ?? 'Failed to retrieve subscriptions';
      })
      .addCase(createSnapshotSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSnapshotSubscription.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createSnapshotSubscription.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateSnapshotSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSnapshotSubscription.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateSnapshotSubscription.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteSnapshotSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSnapshotSubscription.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteSnapshotSubscription.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default snapshotSlice.reducer;
