import support from './support.svg';
import supportWhite from './support_white.svg';
import freshdesk from './freshdesk.svg';
import fileBlack from './fileBlack.svg';
import fileGray from './fileGray.svg';

export const CustomSvg = {
  support,
  supportWhite,
  freshdesk,
  fileBlack,
  fileGray,
};

export { default as crownIconUrl } from './crown_icon.png';
export { default as adIcon } from './ad_icon.png';
export { default as walletIconUrl } from './wallet_icon.png';
export { default as bracketsIconUrl } from './brackets.png';
export { default as simpleLogoBlackUrl } from './simple_face_black.png';
export { default as envelope } from './envelope.png';
export { default as rocketIcon } from './rocket_news_icon.png';
export { default as imgPlaceholderUrl } from './imagePlaceholder.png';
export { default as lightningIconUrl } from './lightning.png';
export { default as lightningRoundedIconUrl } from './lightningRounded.png';
export { default as confettiIconUrl } from './confetti.png';
export { default as CheckSharpIcon } from './check_sharp.png';
export { default as Bubble } from './bubble.png';
export { default as AlarmClock } from './alarm-clock.png';
export { default as Discord } from './discord.svg';
export { default as Telegram } from './telegram.svg';

export {
  FaAngleDown as ArrowShortIcon,
  FaBars as BurgerMenuIcon,
  FaDiscord as DiscordIcon,
  FaUserCircle as UserCircle,
  FaFileAlt as FileIcon,
  FaInfoCircle as InfoIcon,
  FaQuestionCircle as QuestionIcon,
  FaTelegramPlane as TelegramIcon,
  FaTrash as TrashIcon,
  FaRegCopy as CopyIcon,
  FaSearch as SearchIcon,
  FaImage as ImageIcon,
  FaRedditSquare as RedditSqIcon,
  FaReddit as RedditIconNormal,
  FaWallet as WalletIconSolid,
  FaCoins as CoinsIconSolid,
  FaRegArrowAltCircleDown as DownloadIcon,
  FaChevronLeft as LeftArrowIcon,
  FaChevronRight as RightArrowIcon,
  FaCog as SettingsIcon,
  FaClipboardList as ListIcon,
} from 'react-icons/fa';
export {
  AiFillWarning as AttentionIcon,
  AiOutlinePlus as PlusIcon,
} from 'react-icons/ai';
export {
  RiCloseLine as CloseIcon,
  RiCheckLine as CheckIcon,
  RiPencilFill as EditIcon,
  RiCloseCircleFill as FailIcon,
  RiCheckboxCircleFill as SuccessIcon,
} from 'react-icons/ri';
export { IoIosMail as EmailIcon } from 'react-icons/io';
export {
  IoExitOutline as LeaveIcon,
  IoEllipsisVertical as DotsIcon,
  IoEllipsisHorizontal as DotsHorizontal,
  IoArrowBackOutline as ArrowIcon,
} from 'react-icons/io5';
