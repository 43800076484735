import React from 'react';

import cx from 'classnames';
import styles from './Spinner.module.scss';
import { ReactFCC } from 'custom-types/react';

type SpinnerProps = {
  className?: string;
  fullPage?: boolean;
};

const OptionalWrapper: ReactFCC = ({ children }) => (
  <div className={cx(styles.fullPage)}>{children}</div>
);

const Spinner: React.FC<SpinnerProps> = ({
  className,
  fullPage = false,
}: SpinnerProps) => {
  const Wrapper = fullPage ? OptionalWrapper : React.Fragment;
  return (
    <Wrapper>
      <div className={cx(styles.loader, className)}>Loading...</div>
    </Wrapper>
  );
};

export default Spinner;
