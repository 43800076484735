import httpClient from '../../sdk';

export async function getPremiumSupportLink(
  communityId: string,
): Promise<{ link: string; code: string }> {
  return await httpClient(
    `communities/${encodeURIComponent(communityId)}/premium-support`,
    {
      method: 'get',
    },
  );
}
