import { useState } from 'react';
import cx from 'classnames';

import { useDispatch, useSelector } from 'store/hooks';
import { useParams } from 'react-router-dom';
import { Community, TPC } from 'custom-types';

import TpcCard from 'components/TpcCard';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';
import SelectRoleModal from 'components/SelectRoleModal';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

import {
  removeAllInvalidTpcs,
  removeCommunityTPCsById,
  updateTpcRole,
} from 'store/community/actionCreators';

import fonts from 'styles/typography.module.scss';
import s from './InvalidTpcConfig.module.scss';

type Props = {
  onShowMetadataModal: (item: any) => void;
};

export default function InvalidTpcConfig({ onShowMetadataModal }: Props) {
  const { communityId: activeCommunityId } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [activeTpc, setActiveTpc] = useState<TPC | null>(null);
  const [showSelectRoleModal, setShowSelectRoleModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );

  const { invalidTpcs, roles, pk } =
    communitiesFromState?.find(
      (community: Community) => community.communityId === activeCommunityId,
    ) ?? ({} as Community);

  const handleModify = (item: TPC) => {
    setActiveTpc(item);
    setShowSelectRoleModal(true);
  };

  const handleDelete = (item: TPC) => {
    setActiveTpc(item);
    setShowDeleteModal(true);
  };

  return (
    <>
      <div className={s.headerRow}>
        <h3 className={cx(fonts.h2, s.headerTitle)}>Invalid TGRs</h3>
        <Button color="secondary" onClick={() => setShowDeleteModal(true)}>
          Delete all invalid TGRs
        </Button>
      </div>
      <div className={s.invalidContainer}>
        {loading ? (
          <div style={{ height: 150, margin: 'auto' }}>
            <Spinner />
          </div>
        ) : (
          invalidTpcs.map((tpc: TPC) => (
            <TpcCard
              className={s.invalidCard}
              tpc={{
                ...tpc,
                type: tpc.type!,
                sk: tpc.sk!,
                pk: tpc.pk!,
                collectionName: tpc.collectionName!,
                streamReceiver: tpc.streamReceiver!,
              }}
              key={tpc.sk}
              showMetadataCallback={onShowMetadataModal}
              onModify={handleModify}
              onDelete={handleDelete}
            />
          ))
        )}
      </div>
      {showDeleteModal && (
        <DeleteConfirmationModal
          tpcName={activeTpc?.name || 'all invalid TGRs'}
          close={() => {
            setActiveTpc(null);
            setShowDeleteModal(false);
          }}
          callback={() => {
            setShowDeleteModal(false);
            setLoading(true);
            if (activeTpc?.sk) {
              setActiveTpc(null);

              dispatch(
                removeCommunityTPCsById(pk, activeTpc.sk, () => {
                  setLoading(false);
                }),
              );
            } else {
              dispatch(
                removeAllInvalidTpcs(pk, () => {
                  setLoading(false);
                }),
              );
            }
          }}
        />
      )}
      {showSelectRoleModal && (
        <SelectRoleModal
          close={() => {
            setShowSelectRoleModal(false);
            setActiveTpc(null);
          }}
          callback={(role) => {
            setLoading(true);
            setShowSelectRoleModal(false);
            setActiveTpc(null);
            if (activeTpc?.sk) {
              dispatch(
                updateTpcRole(pk, activeTpc.sk, role.id, () => {
                  setLoading(false);
                }),
              );
            }
          }}
          data={roles}
        />
      )}
    </>
  );
}
