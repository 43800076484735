import { AnyAction } from 'redux';
import { MarketplaceApp } from 'custom-types';
import * as actionTypes from './actionTypes';
import { CommunityState } from '../types';

const initialState: CommunityState = {
  communityGroups: {
    items: [],
    paginationToken: '',
  },
  isCommunityLoaded: false,
  selectedRoles: [],
  selectedRoleCompositionRoles: [],
  error: null,
  loading: false,
  showRedditConnectModal: false,
};

const reducer = (
  state: CommunityState = initialState,
  action: AnyAction,
): CommunityState => {
  switch (action.type) {
    case actionTypes.GET_COMMUNITY_LIST:
      return {
        ...state,
        communityGroups: action.payload,
        isCommunityLoaded: true,
        loading: false,
      };
    case actionTypes.UPDATE_COMMUNITY:
      return {
        ...state,
        communityGroups: {
          ...state.communityGroups,
          items: state.communityGroups.items.map((item) =>
            item.communityId === action.payload.communityId
              ? {
                  ...item,
                  welcomeMessage: action.payload.message,
                  tpcs: action.payload.tpcs,
                }
              : item,
          ),
        },
      };
    case actionTypes.UPDATE_COMMUNITY_TPCS:
      return {
        ...state,
        loading: false,
        communityGroups: {
          ...state.communityGroups,
          items: state.communityGroups.items.map((item) => {
            if (item.pk === action.payload.communityPk) {
              const communityRoles = item.roles.map((role) => role.id);
              return {
                ...item,
                tpcs: action.payload.tpcs,
                roleCompositionTpcs: action.payload.roleCompositionTpcs,
                invalidTpcs: action.payload.tpcs.filter(
                  (tpc: any) => !communityRoles.includes(tpc.roleId),
                ),
              };
            }
            return item;
          }),
        },
      };
    case actionTypes.ADD_SELECTED_ROLE: {
      const storeKey =
        action.payload.page === 'role-composition'
          ? 'selectedRoleCompositionRoles'
          : 'selectedRoles';
      return {
        ...state,
        [storeKey]: [...state[storeKey], action.payload.roleId],
      };
    }
    case actionTypes.REMOVE_SELECTED_ROLE: {
      const storeKey =
        action.payload.page === 'role-composition'
          ? 'selectedRoleCompositionRoles'
          : 'selectedRoles';
      return {
        ...state,
        [storeKey]: state[storeKey].filter(
          (el) => el !== action.payload.roleId,
        ),
      };
    }
    case actionTypes.FILTER_TPC_BY_ROLE:
      return {
        ...state,
        communityGroups: {
          ...state.communityGroups,
          items: state.communityGroups.items.map((item) =>
            item.communityId === action.payload.communityId
              ? {
                  ...item,
                  tpcs: item.tpcs.filter(
                    (tpc) => tpc.roleId !== action.payload.roleId,
                  ),
                }
              : item,
          ),
        },
        selectedRoles: [...state.selectedRoles, action.payload.roleId],
      };
    case actionTypes.FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case actionTypes.TOGGLE_REDDIT_CONNECT_MODAL:
      return {
        ...state,
        showRedditConnectModal: !state.showRedditConnectModal,
      };

    case actionTypes.UPDATE_WALLET_INFO:
      return {
        ...state,
        communityGroups: {
          ...state.communityGroups,
          items: state.communityGroups.items.map((item) =>
            item.pk === action.payload.communityPk
              ? {
                  ...item,
                  wallet: [action.payload.wallet],
                }
              : item,
          ),
        },
      };
    case 'marketplace/uninstallApp/fulfilled':
    case 'marketplace/installApp/fulfilled':
      const { app, communityPk } = action.payload as {
        app: MarketplaceApp;
        communityPk: string;
      };
      let miniapps =
        state.communityGroups.items
          .find((c) => c.pk === communityPk)
          ?.miniapps?.slice() || [];
      if (app.isInstalled) miniapps.push(app.appId);
      else miniapps = miniapps.filter((appId) => appId !== app.appId);
      return {
        ...state,
        communityGroups: {
          ...state.communityGroups,
          items: state.communityGroups.items.map((item) =>
            item.pk === communityPk ? { ...item, miniapps } : item,
          ),
        },
      };
    default:
      return state;
  }
};

export default reducer;
