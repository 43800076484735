/* eslint-disable react/button-has-type */
import React from 'react';
import cx from 'classnames';

import fonts from 'styles/typography.module.scss';

import styles from './Button.module.scss';

type ButtonInjectedProps = {
  className?: string;
  children: JSX.Element;
  onClick?: (e: React.SyntheticEvent) => void;
  disabled: boolean;
  type?: 'submit' | 'reset' | 'button';
};

export type ButtonProps = {
  className?: string;
  children?: React.ReactNode;
  onClick?: (e?: React.SyntheticEvent) => void;
  renderContainer?: (props: ButtonInjectedProps) => JSX.Element;
  size?: 'small' | 'medium' | 'large';
  color?:
    | 'primary'
    | 'secondary'
    | 'secondary-light'
    | 'secondary-dark'
    | 'empty'
    | 'danger'
    | 'danger-outline'
    | 'danger-secondary';
  type?: 'submit' | 'reset' | 'button';
  rounded?: boolean;
  outline?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  withIcon?: boolean;
};

const defaultRenderContainer = (props: ButtonInjectedProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <button {...props} />
);

const Button: React.FC<ButtonProps> = (props, context) => {
  const {
    className,
    children,
    size = 'medium',
    color = 'primary',
    type,
    withIcon = false,
    rounded = false,
    outline = false,
    disabled = false,
    fullWidth = false,
    onClick,
    renderContainer = defaultRenderContainer,
  } = props;
  const classNames = cx(styles.root, className, styles[size], styles[color], {
    [styles.rounded]: rounded,
    [styles.outline]: outline,
    [fonts.button1]: size !== 'small',
    [fonts.button3]: size === 'small',
    [styles.full]: fullWidth,
    [styles.disabled]: disabled,
    [styles.withIcon]: withIcon,
  });

  const content = <>{children}</>;

  return renderContainer({
    ...props,
    className: classNames,
    children: content,
    type,
    disabled,
    onClick,
  });
};

export default Button;
