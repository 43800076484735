import { AnyAction } from 'redux';
import * as actionTypes from './actionTypes';
import { ToastState } from '../types';

const initialState: ToastState = {
  toastList: [],
};

const reducer = (
  state: ToastState = initialState,
  action: AnyAction,
): ToastState => {
  switch (action.type) {
    case actionTypes.ADD_TOAST:
      return {
        ...state,
        toastList: [...state.toastList, action.payload],
      };
    case actionTypes.CLEAR_TOASTS:
      return {
        ...state,
        toastList: [],
      };
    default:
      return state;
  }
};

export default reducer;
