import React from 'react';
import cx from 'classnames';

import { checkLowContrast, getRoleColor } from 'utils';

import s from './RoleName.module.scss';

export type RoleNameProps = {
  className?: string;
  children: React.ReactNode;
  roleColor: number;
};

const RoleName: React.FC<RoleNameProps> = ({
  className = '',
  children,
  roleColor,
}: RoleNameProps) => {
  return (
    <h3
      className={cx(s.main, className, {
        [s.lowContrast]: checkLowContrast(roleColor),
      })}
      style={
        { '--custom-color': getRoleColor(roleColor) } as React.CSSProperties
      }
    >
      {children}
    </h3>
  );
};

export default RoleName;
