export const enableProBotFeatures =
  process.env.REACT_APP_ENABLE_PRO_BOT === 'true';

export const enableProBotFeaturesV1 =
  process.env.REACT_APP_ENABLE_PRO_BOT_V1 === 'true';

export const enableProBotFeaturesV2 =
  process.env.REACT_APP_ENABLE_PRO_BOT_V2 === 'true';

export const enableFriendTechFeatures =
  process.env.REACT_APP_ENABLE_FRIEND_TECH === 'true';
