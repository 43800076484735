// import widgets from './widgets';
// import wallets from './wallets';
import user from './user';
import community from './community';
import discordBot from './discordBot';
import roleManager from './roleManager';
import toasts from './toasts';
import guestPass from './guestPass';
import marketplace from './marketplace';
import snapshot from './snapshot';
import letsGoConfig from './letsGoConfig';
import analytics from './analytics';
import pricing from './pricing';
import premiumSupport from './premiumSupport';
import modal from './modal';

const rootReducer = {
  user,
  community,
  modal,
  discordBot,
  roleManager,
  toasts,
  guestPass,
  marketplace,
  snapshot,
  analytics,
  pricing,
  premiumSupport,
  letsGoConfig,
};

export default rootReducer;
