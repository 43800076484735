function parseApiError(e: any) {
  let errorMsg = '';
  if (e.status === 422)
    errorMsg = e.response?.body?.error?.details
      ?.map((error: any) => error?.message)
      .join('\n');
  return errorMsg ?? (e as Error).message;
}

export default function parseErrorMessage(
  error: any,
  { forwardApiError }: { forwardApiError?: boolean } = {
    forwardApiError: true,
  },
) {
  if (error.status === 422) {
    return parseApiError(error);
  }

  // A very generic catch-all
  let errorMessage = error?.response?.obj?.error?.message || error.message;
  errorMessage =
    forwardApiError && errorMessage ? errorMessage : 'Something went wrong';

  return errorMessage;
}
