import React, { FC, MouseEventHandler } from 'react';
import PlatformIcon from 'components/PlatformIcon';
import { BurgerMenuIcon } from 'icons';
import Logo from 'images/Logo-Mark-Color.svg';
import { useParams } from 'react-router-dom';
import { useSelector } from 'store/hooks';
import cx from 'classnames';

import fonts from 'styles/typography.module.scss';
import s from './ServerTopBar.module.scss';

interface ServerTopBarInterface {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const ServerTopBar: FC<ServerTopBarInterface> = ({ onClick }) => {
  const { communityId } = useParams<{ communityId?: string }>();
  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );
  const activeCommunity = communitiesFromState.find(
    (community) => community.communityId === communityId,
  );
  const platform = useSelector((state) => state.user.platform);

  return (
    <div className={s.root}>
      <button type="button" className={s.burgerMenuButton} onClick={onClick}>
        <BurgerMenuIcon className={s.burgerMenu} title="burger menu icon" />
      </button>
      <div className={s.activeCommunityContainer}>
        {activeCommunity ? (
          <h5 className={cx(fonts.title3, s.communityName, s.ellipsis)}>
            {activeCommunity.name}
          </h5>
        ) : (
          <div className={s.placeholderInput} />
        )}
        <div className={cx(fonts.caption2, s.communitySubName)}>
          <PlatformIcon />
          <p className={s.ellipsis}>Logged in with {platform}</p>
        </div>
      </div>
      <img src={Logo} alt="logo" className={s.logo} />
    </div>
  );
};

export default ServerTopBar;
