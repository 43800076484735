// usePopup.js

export enum PopUpEvents {
  BOT_INVITED = 'collabland-bot-invited',
  LOGGED_IN = 'collabland-logged-in',
}

const usePopup = () => {
  const open = (
    popUpEvent: PopUpEvents,
    url: string,
    onClose: (payload?: any) => void,
    width?: number,
    height?: number,
  ) => {
    const popupWindow = window.open(
      url,
      popUpEvent,
      `width=${width ?? 400},height=${height ?? 700},top=${
        (window.screen.height - (height ?? 700)) / 2
      },left=${(window.screen.width - (width ?? 400)) / 2}`,
    );
    window.addEventListener(
      'message',
      (event) => {
        if (event.data.type === popUpEvent) {
          // Close the popup window
          popupWindow?.close();
          onClose(event.data.payload);
        }
      },
      false,
    );
  };

  const postMessage = (event: PopUpEvents, payload?: any) => {
    window.opener?.postMessage({ type: event, payload });
  };

  return {
    open,
    postMessage,
  };
};

export default usePopup;
