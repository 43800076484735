import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { MINIAPP_KEYS } from '../../constants/marketplace';
import useGetCurrentCommunity from '../../hooks/useGetCurrentCommunity';
import usePlanHook from '../../hooks/usePlanHook';
import { FeatureIdentifiers } from '../../store/types';
import { useSelector } from '../../store/hooks';

const MiniAppProtection = ({ miniapp }: { miniapp: MINIAPP_KEYS }) => {
  const { communityId } = useParams();
  const activeCommunity = useGetCurrentCommunity();
  const productsFromState = useSelector((state) => state?.marketplace?.items);
  const { checkFeature } = usePlanHook(activeCommunity);
  const isMiniAppInstalled = activeCommunity?.miniapps?.includes(miniapp);
  if (!isMiniAppInstalled) {
    return <Navigate to={`/dashboard/${communityId}/marketplace/${miniapp}`} />;
  }
  const proMiniApps = productsFromState
    .filter((m) => m.type === 'pro')
    .map((m) => m.appId);
  const isProApp = proMiniApps.includes(miniapp);
  if (isProApp && !checkFeature(FeatureIdentifiers.MINIAPP, miniapp)) {
    return <Navigate to={`/dashboard/${communityId}/marketplace/${miniapp}`} />;
  }
  return <Outlet />;
};

export default MiniAppProtection;
