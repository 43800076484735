import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/hooks';

import { TPC } from 'custom-types';
import {
  getAxieRules,
  getChainNameById,
  getExtraFieldDataByTokenType,
  getRoninTokens,
  tokenTypesWithMetadata,
  tokenTypesWithTokenId,
} from 'constants/community';
import Button from 'components/Button';
import { TrashIcon } from 'icons';

import fonts from 'styles/typography.module.scss';
import s from './TpcCard.module.scss';
import Switch from '../Switch';
import { updateTpcStatus } from '../../store/community/actionCreators';
import { enableProBotFeatures } from '../../constants/features';

type TPCSafe = TPC & {
  type: Exclude<TPC['type'], null>;
  sk: Exclude<TPC['sk'], null | undefined>;
  pk: Exclude<TPC['pk'], null | undefined>;
  collectionName: Exclude<TPC['collectionName'], null>;
  streamReceiver: Exclude<TPC['streamReceiver'], null>;
};

export type TpcCardProps = {
  tpc: TPCSafe;
  className?: string;
  onDelete?: (tpc: TPC) => void;
  onModify?: (tpc: TPC) => void;
  showMetadataCallback?: (tpc: TPC) => void;
};

const formatAddress = (address: string) => {
  return address?.length > 20
    ? `${address.slice(0, 6)}...${address.slice(
        address.length - 4,
        address.length,
      )}`
    : address;
};

type AllowedFields = {
  showMinMaxToken: boolean;
  showTokenId: boolean;
  showEventId: boolean;
  showAddress: boolean;
  showTokenSymbol: boolean;
  showFilter: boolean;
  showCollectionName: boolean;
  showStreamAttributes: boolean;
  showCurrency: boolean;
  showTaxon: boolean;
};

const getAllowedFieldByTokenTypes = ({
  type,
  chainId,
}: {
  type: string;
  chainId: number;
}): AllowedFields => {
  const showMinMaxToken = !['POAP', 'ERC777'].includes(type);
  const showStreamAttributes = ['ERC777'].includes(type);
  const showEventId = type === 'POAP';
  const showAddress = ![
    'POAP',
    'ROLL',
    'OPEN_SEA',
    'otterspace',
    'Bitcoin_Ordinals',
  ].includes(type);
  const showTokenId = tokenTypesWithTokenId.includes(type);
  const showTokenSymbol = type === 'ROLL' || chainId === 2020;
  const showFilter = type === 'SOLANA_NFT';
  const showCollectionName = [
    'OPEN_SEA',
    'FLOW_NFT',
    'FLOW_FT',
    'Bitcoin_Ordinals',
  ].includes(type);
  const showCurrency = ['XRPL_FT'].includes(type);
  const showTaxon = ['XRPL_NFT'].includes(type);

  return {
    showMinMaxToken,
    showTokenId,
    showEventId,
    showAddress,
    showTokenSymbol,
    showFilter,
    showCollectionName,
    showStreamAttributes,
    showCurrency,
    showTaxon,
  };
};

const MaybeLink = ({
  to,
  children,
  className,
  ...props
}: React.ComponentProps<typeof Link>) => {
  if (to)
    return (
      <Link to={to} className={className} {...props}>
        {children}
      </Link>
    );
  return <div className={className}>{children}</div>;
};

const getIdFromPk = (pk: string) => {
  return pk.split('#').pop();
};

const getEncodedTpcId = (sk: string) => {
  return encodeURIComponent(sk.replace('TPC#', ''));
};

const TpcCard: React.FC<TpcCardProps> = ({
  tpc,
  className = '',
  onDelete,
  onModify,
  showMetadataCallback = () => undefined,
}) => {
  const allowedFields = getAllowedFieldByTokenTypes({
    type: tpc.type,
    chainId: tpc.chainId,
  });
  const dispatch = useDispatch();
  const { address, collectionName, unit } = getExtraFieldDataByTokenType(
    tpc.type,
  );
  const platform = useSelector((state) => state.user.platform);

  const url =
    onDelete || onModify
      ? ''
      : platform === 'discord'
      ? `/dashboard/${getIdFromPk(tpc.pk)}/tgrs/${
          tpc.roleId
        }/edit/${getEncodedTpcId(tpc.sk)}`
      : `/dashboard/${getIdFromPk(tpc.pk)}/tgas/edit/${getEncodedTpcId(
          tpc.sk,
        )}`;

  const roninToken =
    tpc.chainId === 2020
      ? getRoninTokens().find((c) => c.value === tpc.contractAddress)
      : null;

  const axieRule =
    tpc.chainId === 8000000002020
      ? getAxieRules().find((a) => a.name === tpc.name)
      : null;
  return (
    <MaybeLink to={url} className={cx(s.card, className)}>
      <div className={s.cardMain}>
        <div className={s.cardRow}>
          <p className={cx(fonts.caption1, s.cardKey)}>Description</p>

          <p className={cx(fonts.caption1, s.cardValue)}>{tpc.name}</p>
        </div>
        {/*{enableProBotFeatures && (*/}
        {/*  <div className={s.cardRow}>*/}
        {/*    <p className={cx(fonts.caption1, s.cardKey)}>Active</p>*/}

        {/*    <Switch*/}
        {/*      isChecked={tpc.status === 'active'}*/}
        {/*      onChange={(value) => {*/}
        {/*        dispatch(*/}
        {/*          updateTpcStatus(*/}
        {/*            tpc.pk,*/}
        {/*            tpc.id,*/}
        {/*            value ? 'active' : 'inactive',*/}
        {/*          ),*/}
        {/*        );*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}

        <div className={s.cardRow}>
          <p className={cx(fonts.caption1, s.cardKey)}>Chain / token type</p>

          <p className={cx(fonts.caption1, s.cardValue)}>
            {getChainNameById(tpc.chainId.toString())} / {tpc.type}
          </p>
        </div>
      </div>

      <div className={s.cardAdditional}>
        {allowedFields.showFilter && (
          <div className={s.cardRow}>
            <p className={cx(fonts.caption1, s.cardKey)}>Filters</p>

            <p className={cx(fonts.caption1, s.cardValue)}>{tpc.filter}</p>
          </div>
        )}

        {allowedFields.showTaxon && tpc.taxon && (
          <div className={s.cardRow}>
            <p className={cx(fonts.caption1, s.cardKey)}>Taxon</p>

            <p className={cx(fonts.caption1, s.cardValue)}>{tpc.taxon}</p>
          </div>
        )}

        {allowedFields.showCurrency && (
          <div className={s.cardRow}>
            <p className={cx(fonts.caption1, s.cardKey)}>Currency</p>

            <p className={cx(fonts.caption1, s.cardValue)}>{tpc.currency}</p>
          </div>
        )}

        {allowedFields.showAddress && tpc.contractAddress && (
          <div className={s.cardRow}>
            <p className={cx(fonts.caption1, s.cardKey)}>{address.name}</p>

            <p
              className={cx(fonts.caption1, s.cardValue)}
              title={tpc.contractAddress}
            >
              {formatAddress(tpc.contractAddress)}
            </p>
          </div>
        )}

        {allowedFields.showCollectionName && (
          <div className={s.cardRow}>
            <p className={cx(fonts.caption1, s.cardKey)}>
              {collectionName.name}
            </p>

            <p
              className={cx(fonts.caption1, s.cardValue)}
              title={tpc.collectionName}
            >
              {formatAddress(tpc.collectionName)}
            </p>
          </div>
        )}

        {allowedFields.showTokenSymbol && (
          <div className={s.cardRow}>
            <p className={cx(fonts.caption1, s.cardKey)}>Token Symbol</p>

            <p className={cx(fonts.caption1, s.cardValue)}>
              {roninToken?.tokenSymbol ?? tpc.tokenSymbol}
            </p>
          </div>
        )}
        {roninToken && (
          <div className={s.cardRow}>
            <p className={cx(fonts.caption1, s.cardKey)}>Token Name</p>
            <p className={cx(fonts.caption1, s.cardValue)}>
              {roninToken.tokenName}
            </p>
          </div>
        )}
        {axieRule && (
          <div className={s.cardRow}>
            <p className={cx(fonts.caption1, s.cardKey)}>Rule Name</p>
            <p className={cx(fonts.caption1, s.cardValue)}>{axieRule.name}</p>
          </div>
        )}

        {tpc.type === 'otterspace' && (
          <>
            <div className={s.cardRow}>
              <p className={cx(fonts.caption1, s.cardKey)}>Raft token ID</p>

              <p className={cx(fonts.caption1, s.cardValue)}>
                {tpc.raftTokenId}
              </p>
            </div>
            <div className={s.cardRow}>
              <p className={cx(fonts.caption1, s.cardKey)}>Badge Name</p>

              <p className={cx(fonts.caption1, s.cardValue)}>
                {tpc.otterspaceName}
              </p>
            </div>
          </>
        )}

        {allowedFields.showMinMaxToken && tpc.minToken && (
          <div className={s.cardRow}>
            <p className={cx(fonts.caption1, s.cardKey)}>Min / max {unit}</p>

            <p
              className={cx(fonts.caption1, s.cardValue)}
              title={`${tpc.minToken} / ${!tpc.maxToken ? '∞' : tpc.maxToken}`}
            >
              {tpc.minToken} / {!tpc.maxToken ? '∞' : tpc.maxToken}
            </p>
          </div>
        )}
        {allowedFields.showStreamAttributes && (
          <>
            <div className={s.cardRow}>
              <p className={cx(fonts.caption1, s.cardKey)}>Stream receiver</p>
              <p className={cx(fonts.caption1, s.cardValue)}>
                {formatAddress(tpc.streamReceiver)}
              </p>
            </div>
            <div className={s.cardRow}>
              <p className={cx(fonts.caption1, s.cardKey)}>Flow Rate / Month</p>
              <p className={cx(fonts.caption1, s.cardValue)}>
                {tpc?.minFlowRate || 0}
              </p>
            </div>
          </>
        )}

        {allowedFields.showEventId && (
          <div className={s.cardRow}>
            <p className={cx(fonts.caption1, s.cardKey)}>Event Id</p>

            <p className={cx(fonts.caption1, s.cardValue)}>{tpc.eventId}</p>
          </div>
        )}

        {allowedFields.showTokenId && tpc.traitsId != null && (
          <div className={s.cardRow}>
            <p className={cx(fonts.caption1, s.cardKey)}>Token Id</p>

            <p className={cx(fonts.caption1, s.cardValue)} title={tpc.traitsId}>
              {formatAddress(tpc.traitsId)}
            </p>
          </div>
        )}

        {tpc.traits &&
          tpc.type &&
          tpc.traits.length > 0 &&
          tokenTypesWithMetadata.includes(tpc.type) && (
            <div className={cx(s.cardRow, s.large)}>
              <p className={cx(fonts.caption1, s.cardKey)}>Metadata</p>

              <Button
                color="secondary"
                className={s.cardButton}
                onClick={(e) => {
                  e?.preventDefault();
                  showMetadataCallback(tpc);
                }}
              >
                Show details
              </Button>
            </div>
          )}

        {(onDelete || onModify) && (
          <div className={s.cardRow}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              className={s.iconButton}
              onClick={() => onDelete?.(tpc)}
              data-tracking-info={JSON.stringify({
                id: 'button:tgr-delete:click',
                server_id: getIdFromPk(tpc.pk),
                tpc_id: getEncodedTpcId(tpc.sk),
                chain_id: tpc.chainId,
                token_type: tpc.type,
              })}
            >
              <TrashIcon title="delete icon" />
            </div>
            <Button
              color="secondary"
              className={s.cardButton}
              onClick={() => onModify?.(tpc)}
              data-tracking-info={JSON.stringify({
                id: 'button:tgr-modify:click',
                server_id: getIdFromPk(tpc.pk),
                tpc_id: getEncodedTpcId(tpc.sk),
                chain_id: tpc.chainId,
                token_type: tpc.type,
              })}
            >
              Modify
            </Button>
          </div>
        )}
      </div>
    </MaybeLink>
  );
};

export default TpcCard;
