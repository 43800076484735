import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInviteServerModalOpen: false,
  inviteModalPlatform: 'discord',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openInviteModal(state, action) {
      state.isInviteServerModalOpen = true;
      state.inviteModalPlatform = action.payload;
    },
    closeInviteModal(state) {
      state.isInviteServerModalOpen = false;
    },
  },
});

export default modalSlice.reducer;
export const modalActions = modalSlice.actions;
