import React from 'react';
import { useSelector } from 'store/hooks';
import { TelegramIcon, DiscordIcon, RedditIconNormal } from 'icons';
import { IconType } from 'react-icons';
import s from './PlatformIcon.module.scss';

const PlatformIcon: React.FC = () => {
  const platform = useSelector((state) => state.user.platform);

  let IconComponent: IconType;
  switch (platform) {
    case 'telegram':
      IconComponent = TelegramIcon;
      break;
    case 'reddit':
      IconComponent = RedditIconNormal;
      break;
    default:
      IconComponent = DiscordIcon;
  }

  return (
    <IconComponent
      className={s.iconLogo}
      style={{
        borderRadius: 4,
        color: 'black',
        background: '#ffffff',
        padding: '2px',
      }}
    />
  );
};

export default PlatformIcon;
