import React, { useState } from 'react';
import cx from 'classnames';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/hooks';
import { ArrowShortIcon, DotsIcon, PlusIcon } from 'icons';
import { alphabeticalSort, getRoleColor } from 'utils';
import { chainTypes, tokenTypes } from 'constants/community';
import { TPC } from 'custom-types';

import {
  addSelectedRole,
  removeCommunityTPCsByRole,
  removeSelectedRole,
} from 'store/community/actionCreators';

// import emptyTPCsList from 'images/emptyTPCsList.png';
import HeaderSearch from 'components/HeaderSearch';
import Button from 'components/Button';
import SelectRoleModal from 'components/SelectRoleModal';
import TpcCard from 'components/TpcCard';
import ViewMetadataModal from 'components/ViewMetadataModal';
import InvalidTpcConfig from 'components/InvalidTpcConfig';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';

import fonts from 'styles/typography.module.scss';
import Dropdown from 'components/Dropdown';
import SimpleDropdown from 'components/SimpleDropdown';
import s from './TpcConfig.module.scss';
import { TGRQuota } from '../TGRQuota/TGRQuota';

type SortDirection = {
  value: string;
  label: string;
  dropdownTitle: string;
};

const sortDirections: SortDirection[] = [
  {
    value: 'az',
    label: 'Name (A-Z)',
    dropdownTitle: 'name (A-Z)',
  },
  {
    value: 'za',
    label: 'Name (Z-A)',
    dropdownTitle: 'name (Z-A)',
  },
];

const chainsFilterOptions = [
  {
    value: '-1',
    label: 'All chains',
  },
  ...chainTypes.filter((chain) => !chain.isDisabled),
];

const tokenTypeFilterOptions = [
  {
    value: 'all',
    label: 'All types',
  },
  ...tokenTypes.filter((token) => !token.isDisabled),
];

const TpcConfig: React.FC = () => {
  const { communityId: activeCommunityId } = useParams() as {
    communityId: string;
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedRoles = useSelector((state) => state.community.selectedRoles);

  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );

  const [roleToDelete, setRoleToDelete] = useState<null | {
    id: string;
    name: string;
  }>();
  const [showSelectRoleModal, setShowSelectRoleModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [activeTpc, setActiveTpc] = useState<TPC | null>(null);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const [allowedRole, setAllowedRole] = useState('all');
  const [allowedChain, setAllowedChain] = useState<number>(-1);
  const [allowedType, setAllowedType] = useState('all');

  const [sortOrder, setSortOrder] = useState<SortDirection>(sortDirections[0]);

  const communityFromState = communitiesFromState.find(
    (community) => community.communityId === activeCommunityId,
  )!;

  const { invalidTpcs } = communityFromState;

  const filteredTpcsByRole = communityFromState.tpcs.filter((tpc) =>
    allowedRole === 'all' ? true : tpc.roleId === allowedRole,
  );

  const filteredTpcsByChain = filteredTpcsByRole.filter((tpc) =>
    allowedChain === -1 ? true : tpc.chainId === allowedChain,
  );

  const filteredTpcsByToken = filteredTpcsByChain.filter((tpc) =>
    allowedType === 'all' ? true : tpc.type === allowedType,
  );

  const rolesWithTpcs = Array.from(
    new Set([
      ...communityFromState.tpcs.map((tpc) => tpc.roleId),
      ...selectedRoles,
    ]),
  ).filter((roleId) => (allowedRole === 'all' ? true : roleId === allowedRole));

  const roleFilterOptions = [
    {
      value: 'all',
      label: 'All roles',
    },
    ...Array.from(
      new Set([
        ...communityFromState.tpcs.map((tpc) => tpc.roleId),
        ...selectedRoles,
      ]),
    )
      .filter((roleId) =>
        communityFromState.roles.find((role) => role.id === roleId),
      )
      .map((roleId) => {
        const role = communityFromState.roles.find((el) => el.id === roleId)!;

        return {
          value: roleId,
          label: role.name,
        };
      }),
  ];

  const sortRoles =
    sortOrder.value === 'az'
      ? alphabeticalSort(
          communityFromState.roles.filter((role) =>
            rolesWithTpcs.includes(role.id),
          ),
        )
      : alphabeticalSort(
          communityFromState.roles.filter((role) =>
            rolesWithTpcs.includes(role.id),
          ),
        ).reverse();

  const toggleFilter = (
    value: string,
    filter: string,
    defaultValue: string,
  ) => {
    if (value === defaultValue) {
      setActiveFilters((prev) => prev.filter((itm) => itm !== filter));
    } else if (!activeFilters.includes(filter)) {
      setActiveFilters((prev) => [...prev, filter]);
    }
  };

  return (
    <>
      <HeaderSearch />

      <div
        className={cx(rolesWithTpcs.length > 0 ? s.content : s.contentEmpty)}
      >
        <div className={s.headerRow}>
          <h2 className={cx(fonts.title1, s.headerTitle)}>
            Token Granted Roles
          </h2>
          <Button
            color="secondary"
            className={s.headerButton}
            onClick={() => setShowSelectRoleModal(true)}
            data-tracking-info={JSON.stringify({
              id: 'button:select-role-header:click',
              server_id: activeCommunityId,
              role_id: communityFromState.roles.filter(
                (role) => !rolesWithTpcs.includes(role.id),
              ),
              role_name: communityFromState.roles.filter(
                (role) => !rolesWithTpcs.includes(role.id),
              ),
            })}
          >
            <PlusIcon className={s.plus} title="add icon" />
            Select role
          </Button>
        </div>
        <div className={s.headerRow}>
          <TGRQuota />
        </div>
        <div className={s.filtersRow}>
          <div className={s.filtersRowFilter}>
            <button
              type="button"
              className={cx(s.filterButton, fonts.button1, {
                [s.reverse]: showFilters,
              })}
              onClick={() => setShowFilters(!showFilters)}
            >
              <p>
                Filters
                {activeFilters.length > 0 && (
                  <span>({activeFilters.length})</span>
                )}
              </p>
              <ArrowShortIcon className={s.shortButton} title="short icon" />
            </button>
            {activeFilters.length > 0 && (
              <button
                type="button"
                className={s.clearFiltersButton}
                onClick={() => {
                  setActiveFilters([]);
                  setAllowedRole('all');
                  setAllowedChain(-1);
                  setAllowedType('all');
                  setShowFilters(false);
                }}
              >
                Clear filters
              </button>
            )}
          </div>
          <Dropdown
            variant="sort"
            trigger={
              <>
                Sort Roles by <span>{sortOrder.dropdownTitle}</span>
              </>
            }
            items={sortDirections}
            onChange={setSortOrder}
          />
        </div>

        {showFilters && (
          <div className={s.filters}>
            <SimpleDropdown
              value={allowedRole}
              options={roleFilterOptions}
              label="Discord role"
              onChange={(value = '') => {
                setAllowedRole(value);
                toggleFilter(value, 'role', 'all');
              }}
            />

            <SimpleDropdown
              value={allowedChain.toString()}
              options={chainsFilterOptions}
              label="Chain type"
              onChange={(value = '') => {
                setAllowedChain(parseInt(value, 10));
                toggleFilter(value, 'chain', '-1');
              }}
            />

            <SimpleDropdown
              value={allowedType}
              options={tokenTypeFilterOptions}
              label="Token type"
              onChange={(value = '') => {
                setAllowedType(value);
                toggleFilter(value, 'token', 'all');
              }}
            />
          </div>
        )}

        {rolesWithTpcs.length > 0 ? (
          <>
            {invalidTpcs.length > 0 && (
              <InvalidTpcConfig onShowMetadataModal={setActiveTpc} />
            )}
            <div className={s.rolesRow}>
              {sortRoles.map((role) => {
                const selectedTPCs = alphabeticalSort(
                  filteredTpcsByToken.filter((el) => el.roleId === role.id),
                );

                if (
                  selectedTPCs.length === 0 &&
                  (allowedRole !== 'all' ||
                    allowedChain !== -1 ||
                    allowedType !== 'all')
                )
                  return null;

                return (
                  <div className={s.roleWrapper} key={role.id}>
                    <button
                      type="button"
                      className={cx(s.roleHeader)}
                      onClick={() =>
                        navigate(
                          `/dashboard/${activeCommunityId}/tgrs/${role.id}`,
                        )
                      }
                      data-tracking-info={JSON.stringify({
                        id: 'button:select-existing-role:click',
                        server_id: activeCommunityId,
                        role_id: role.id,
                        role_name: role.name,
                      })}
                    >
                      <div
                        className={s.dot}
                        style={{
                          background: getRoleColor(role.color),
                        }}
                      />

                      <Link
                        to={`/dashboard/${activeCommunityId}/tgrs/${role.id}`}
                        className={cx(fonts.button2, s.roleName)}
                        data-tracking-info={JSON.stringify({
                          id: 'link:select-existing-role:click',
                          server_id: activeCommunityId,
                          role_id: role.id,
                          role_name: role.name,
                        })}
                      >
                        {role.name}
                      </Link>

                      <button
                        className={s.roleAddButton}
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();

                          navigate(
                            `/dashboard/${activeCommunityId}/tgrs/${role.id}/add`,
                          );
                        }}
                        data-tracking-info={JSON.stringify({
                          id: 'button:role-plus-add-tgr:click',
                          server_id: activeCommunityId,
                          role_id: role.id,
                          role_name: role.name,
                        })}
                      >
                        <PlusIcon className={s.plus} title="add icon" />
                      </button>

                      <Dropdown
                        showArrow={false}
                        variant="button"
                        trigger={<DotsIcon title="dots icon" />}
                        items={(
                          [
                            'Add new TGR',
                            selectedTPCs.length > 0
                              ? 'Delete all TGRs'
                              : 'Remove Role',
                          ] as const
                        ).map((label) => ({ value: label, label }))}
                        onChange={({ value }, e) => {
                          e.stopPropagation();
                          if (value === 'Add new TGR') {
                            navigate(
                              `/dashboard/${activeCommunityId}/tgrs/${role.id}/add`,
                            );
                          } else if (value === 'Delete all TGRs') {
                            setRoleToDelete({
                              id: role.id,
                              name: role.name,
                            });
                          } else if (value === 'Remove Role') {
                            dispatch(removeSelectedRole({ roleId: role.id }));
                          }
                        }}
                        data-tracking-info={JSON.stringify({
                          id: 'dropdown:role-dots-option:select',
                          server_id: activeCommunityId,
                          role_id: role.id,
                          role_name: role.name,
                        })}
                      />
                    </button>

                    {selectedTPCs.map((tpc) => (
                      <TpcCard
                        tpc={tpc}
                        key={tpc.sk}
                        showMetadataCallback={(item) => setActiveTpc(item)}
                        data-tracking-info={JSON.stringify({
                          id: 'card:tgr:click',
                          server_id: activeCommunityId,
                          role_id: role.id,
                          role_name: role.name,
                        })}
                      />
                    ))}

                    <button
                      type="button"
                      className={cx(fonts.button2, s.roleEmpty)}
                      onClick={() =>
                        navigate(
                          `/dashboard/${activeCommunityId}/tgrs/${role.id}/add`,
                        )
                      }
                      data-tracking-info={JSON.stringify({
                        id: 'button:add-new-tgr:click',
                        server_id: activeCommunityId,
                        role_id: role.id,
                        role_name: role.name,
                      })}
                    >
                      <PlusIcon className={s.plus} title="add icon" />
                      Add new TGR
                    </button>
                  </div>
                );
              })}

              <Button
                color="secondary"
                className={s.selectRoleButton}
                onClick={() => setShowSelectRoleModal(true)}
                data-tracking-info={JSON.stringify({
                  id: 'button:select-role-bottom:click',
                  server_id: activeCommunityId,
                })}
              >
                <PlusIcon className={s.plus} title="add icon" />
                Select role
              </Button>
            </div>
          </>
        ) : (
          <div className={s.empty}>
            <p className={cx(s.emptyText, fonts.paragraph)}>
              You haven’t configured token permission for your roles yet. <br />
              Select a role to get started!
            </p>

            <Button
              className={s.emptyButton}
              onClick={() => setShowSelectRoleModal(true)}
              data-tracking-info={JSON.stringify({
                id: 'button:select-first-role:click',
                server_id: activeCommunityId,
              })}
            >
              <PlusIcon className={s.plus} title="add icon" />
              Select role
            </Button>
          </div>
        )}

        {showSelectRoleModal && (
          <SelectRoleModal
            close={() => setShowSelectRoleModal(false)}
            callback={({ id: roleId }) => {
              if (roleId) {
                dispatch(
                  addSelectedRole({
                    roleId,
                  }),
                );
                navigate(`/dashboard/${activeCommunityId}/tgrs/${roleId}/add`);
              }
            }}
            data={communityFromState.roles.filter(
              (role) => !rolesWithTpcs.includes(role.id),
            )}
            data-tracking-info={JSON.stringify({
              id: 'modal:select-role:open',
              server_id: activeCommunityId,
              role_id: communityFromState.roles.filter(
                (role) => !rolesWithTpcs.includes(role.id),
              ),
              role_name: communityFromState.roles.filter(
                (role) => !rolesWithTpcs.includes(role.id),
              ),
            })}
          />
        )}

        {roleToDelete && (
          <DeleteConfirmationModal
            tpcName={roleToDelete.name}
            close={() => setRoleToDelete(null)}
            callback={() => {
              setRoleToDelete(null);
              dispatch(
                removeCommunityTPCsByRole(activeCommunityId, roleToDelete.id),
              );
            }}
            data-tracking-info={JSON.stringify({
              id: 'button:delete-role:click',
              server_id: activeCommunityId,
              role_id: roleToDelete.id,
              role_name: roleToDelete.name,
            })}
          />
        )}

        {activeTpc && (
          <ViewMetadataModal
            close={() => setActiveTpc(null)}
            activeTpc={activeTpc}
          />
        )}
      </div>
    </>
  );
};

export default TpcConfig;
