import httpClient from '../../sdk';
import { Subscription } from '../types';

export async function subscribe(
  type: string,
  planId: string,
  {
    subscriberId,
    redirectUri,
    state = {},
  }: {
    subscriberId: string;
    redirectUri: string;
    state?: Record<string, any>;
  },
): Promise<{ url: string }> {
  return await httpClient(
    `${type}/subscription/${encodeURIComponent(planId)}`,
    {
      method: 'post',
      body: JSON.stringify({
        redirectUri,
        state: {
          ...state,
          subscriberId,
          type: 'pro-bot',
        },
      }),
    },
  );
}

export async function unsubscribe(
  type: string,
  planId: string,
  subscriberId: string,
): Promise<{ url: string }> {
  return await httpClient(
    `${type}/subscription/${encodeURIComponent(
      planId,
    )}/subscriber/${encodeURIComponent(subscriberId)}`,
    {
      method: 'delete',
    },
  );
}

export async function fetchPlans() {
  const payload = await httpClient(`communities/plans`);
  return payload.items;
}

export async function fetchSubscriptions(communityId: string) {
  const payload = await httpClient(
    `/communities/${encodeURIComponent(communityId)}/subscriptions`,
  );
  return payload.items ?? ([] as Subscription[]);
}
