import { Location, useLocation } from 'react-router-dom';

interface LocationWithState extends Location {
  state: {
    from?: {
      pathname?: string;
    };
  };
}

export default function useSignInUrl(platform: string = 'discord') {
  const location = useLocation() as LocationWithState;
  let redirectURI =
    window.location.origin + (location.state?.from?.pathname ?? '');

  redirectURI += redirectURI.endsWith('/') ? '' : '/';
  const queryParams = new URLSearchParams({
    client_id: process.env.REACT_APP_CLIENT_ID ?? 'collabland_command_center',
    redirect_uri: redirectURI,
    response_type: 'token',
    state: 'portal',
    login_provider: platform,
  }).toString();
  return `${process.env.REACT_APP_API_URL}/oauth2/authorize?${queryParams}`;
}
