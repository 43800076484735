import React from 'react';
import cx from 'classnames';

import { SearchIcon } from 'icons';
import s from './HeaderSearch.module.scss';

export type HeaderSearchProps = {
  placeholder?: string;
  show?: boolean;
  showBig?: boolean;
  fullWidth?: boolean;
  value?: string;
  onChange?: (value: string) => void;
};
const HeaderSearch: React.FC<HeaderSearchProps> = ({
  placeholder = 'Search',
  show,
  showBig,
  fullWidth,
  value,
  onChange,
}) => {
  const handleChange = (e: any) => {
    const newValue = e.target.value;
    onChange?.(newValue);
  };

  return show ? (
    <header
      className={cx(
        showBig ? s.headerFull : s.headerNormal,
        fullWidth && s.fullWidth,
      )}
    >
      <div className={s.search}>
        <input
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
        <SearchIcon className={s.searchIcon} title="search icon" />
      </div>
    </header>
  ) : null;
};

export default HeaderSearch;
