import React, { useState } from 'react';

import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import s from './BalanceCheck.module.scss';
import useGetCurrentCommunity from '../../../hooks/useGetCurrentCommunity';
import httpClient from '../../../sdk';
import { addToast } from '../../../store/toasts/actionCreators';
import { useDispatch } from '../../../store/hooks';

interface Props {
  onChange: (isEnabled: boolean) => void;
  isChecked: boolean;
}

export default function BalanceCheck({ onChange, isChecked }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  // const [balanceCheckTime, setBalanceCheckTime] = useState(
  //   subHours(new Date(), 25),
  // );
  const community = useGetCurrentCommunity();
  // const navigate = useNavigate();
  // const [showBuyModal, setShowBuyModal] = useState(false);
  // const currentUrl = useCurrentUrl();
  // const { checkFeature } = usePlanHook(community);
  const handleBalanceCheck = () => {
    // if (checkFeature(FeatureIdentifiers.ON_DEMAND_BALANCE_CHECK)) {
    //   setBalanceCheckTime(new Date());
    // } else {
    //   setShowBuyModal(true);
    // }
    const url = `communities/${encodeURIComponent(
      community.pk,
    )}/on-demand-balance-check`;
    setLoading(true);
    httpClient(url, {
      method: 'POST',
    })
      .then((response) => {
        setLoading(false);
        dispatch(
          addToast({
            status: 'success',
            description: 'Balance check started',
          }),
        );
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          addToast({
            status: 'error',
            description: error.message,
          }),
        );
      });
  };

  // const checkDisabled = differenceInHours(new Date(), balanceCheckTime) <= 24;

  return (
    <>
      {/*{showBuyModal && (*/}
      {/*  <BuyPlanModal*/}
      {/*    handleClose={() => {*/}
      {/*      setShowBuyModal(false);*/}
      {/*    }}*/}
      {/*    handleSubmit={() => {*/}
      {/*      setShowBuyModal(false);*/}
      {/*      navigate(*/}
      {/*        `/dashboard/${community.communityId}/subscription?redirectUri=${currentUrl}`,*/}
      {/*      );*/}
      {/*    }}*/}
      {/*  ></BuyPlanModal>*/}
      {/*)}*/}
      {/*<CollapsibleRow*/}
      {/*  title={*/}
      {/*    <div className={s.titleRow}>*/}
      {/*      <h6 className={cx(s.titleLabel, fonts.title3)}>Balance check</h6>*/}
      {/*      <Switch*/}
      {/*        isChecked={isChecked}*/}
      {/*        onChange={onChange}*/}
      {/*        data-tracking-info={JSON.stringify({*/}
      {/*          id: 'switch:bg-balance-check:interaction',*/}
      {/*          server_id: community.communityId,*/}
      {/*        })}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*>*/}
      {/*  <div className={cx(s.sectionPadding, s.row)}>*/}
      {/*    <p className={cx(s.text, fonts.caption1)}>*/}
      {/*      Membership verification in regular intervals is enabled.*/}
      {/*    </p>*/}
      {/*    <p className={cx(s.text, fonts.caption1)}>*/}
      {/*      The most recent balance check was initiated on{' '}*/}
      {/*      {format(balanceCheckTime, 'd/M/yy')} at{' '}*/}
      {/*      {format(balanceCheckTime, 'HH:mm')}*/}
      {/*    </p>*/}
      {/*    {checkDisabled && (*/}
      {/*      <div className={s.info}>*/}
      {/*        <InfoIcon className={s.icon} />*/}
      {/*        <p className={cx(fonts.caption2, s.infoTextColor)}>*/}
      {/*          You must wait 24 hours before initiating a new balance check.*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*</CollapsibleRow>*/}
      <div className={s.titleRowFooter}>
        <Button
          color="secondary-light"
          onClick={handleBalanceCheck}
          disabled={loading}
        >
          Initiate Balance Check
        </Button>
        <Badge large className={s.badge}>
          Pro
        </Badge>
      </div>
    </>
  );
}
