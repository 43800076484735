import React, { ReactNode, useState } from 'react';
import cx from 'classnames';

import { SearchIcon } from 'icons';
import Modal from 'components/Modal';
import Button from 'components/Button';
import fonts from 'styles/typography.module.scss';
import s from './SelectRoleModal.module.scss';
import RoleName from 'components/RoleName';
import Spinner from 'components/Spinner';

export type ListRequiredProps = {
  id: string;
  name: string;
};

interface ListProps<T extends ListRequiredProps> {
  data: T[];
  title: string;
  loading?: boolean;
  close: () => void;
  callback: (value: T) => void;
  renderItem?: (data: T) => ReactNode;
  emptyState?: ReactNode;
}

export function SelectItemModal<T extends ListRequiredProps>({
  data,
  title,
  loading,
  close,
  callback,
  renderItem = (item) => item.name,
  emptyState = <p style={{ textAlign: 'center' }}>No results</p>,
}: ListProps<T>) {
  const [searchString, setSearchString] = useState('');
  const [selectedItem, setSelectedItem] = useState<T>();

  const handleSubmit = () => {
    if (selectedItem) callback(selectedItem);
  };

  const filteredData = data.filter((role) =>
    role.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()),
  );

  let content = null;

  if (loading) content = <Spinner />;
  else if (!data.length) content = emptyState;
  else if (filteredData?.length > 0)
    content = filteredData.map((item) => (
      <button
        key={item.id}
        type="button"
        className={cx(fonts.button2, s.roleButton)}
        onClick={() => setSelectedItem(item)}
      >
        {renderItem(item)}
        <div className="customRadio">
          <input readOnly type="radio" checked={item.id === selectedItem?.id} />
          <i />
        </div>
      </button>
    ));

  return (
    <Modal className={s.content}>
      <h4 className={cx(fonts.title2, s.title)}>{title}</h4>

      <div className={s.search}>
        <input
          type="text"
          placeholder="Search"
          onChange={(e) => setSearchString(e.target.value)}
          disabled={loading}
        />
        <SearchIcon className={s.searchIcon} title="search icon" />
      </div>

      <div className={s.rolesWrapper}>
        <div className={s.rolesList}>{content}</div>
      </div>

      <div className={s.row}>
        <Button className={s.cancel} color="secondary" onClick={close}>
          Cancel
        </Button>

        <Button
          className={s.submit}
          onClick={handleSubmit}
          disabled={!selectedItem || loading}
          data-tracking-info={JSON.stringify({
            id: 'button:apply-role:click',
            role_id: selectedItem?.id,
            role_name: selectedItem?.name,
          })}
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
}

type RoleModalProps = Omit<
  ListProps<{ id: string; name: string; color: number }>,
  'renderItem' | 'title'
>;

export default function SelectRoleModal(props: RoleModalProps) {
  return (
    <SelectItemModal
      {...props}
      title="Select Discord role"
      renderItem={(item) => (
        <RoleName className={cx(fonts.title5)} roleColor={item.color}>
          {item.name}
        </RoleName>
      )}
    />
  );
}
