/* eslint-disable import/prefer-default-export */

export const appCategoryLabels = {
  'bot-command': 'Bot Command',
  cc_extension: 'CC Extension',
};

export type AppCategory = keyof typeof appCategoryLabels;

export enum MINIAPP_KEYS {
  GUEST_PASS = 'guest-pass',
  ROLE_COMPOSITION = 'role-composition',
  SNAPSHOT = 'snapshot',
  COMMUNITY_ANALYTICS = 'community-analytics',
}

export const MINIAPP_ROUTES: Record<string, string> = {
  [MINIAPP_KEYS.GUEST_PASS]: 'guest-passes',
  [MINIAPP_KEYS.ROLE_COMPOSITION]: 'role-composition',
  [MINIAPP_KEYS.SNAPSHOT]: 'snapshot',
  [MINIAPP_KEYS.COMMUNITY_ANALYTICS]: 'analytics',
};
export const ENABLE_WALLET = false;
