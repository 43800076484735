import useScreenSize from 'hooks/useScreenSize';
import React from 'react';
import Select, {
  components,
  OptionProps,
  SingleValueProps,
  StylesConfig,
} from 'react-select';
import Creatable from 'react-select/creatable';

export type OptionType = {
  value: string;
  label: string;
  isDisabled?: boolean;
  dropdownLabel?: string;
};

type GlobalProps = {
  onChange: (value: string) => void;
  defaultValue?: { value: string; label: string; isDisabled?: boolean } | null;
  className?: string;
  placeholder?: string;
  options?: OptionType[];
  groupedOptions?: {
    options: OptionType[];
    label: string;
  }[];
  isCreatable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  hasCustomSingleValue?: boolean;
  value?: { value: string; label: string; isDisabled?: boolean } | null;
};

const customControlStyles = {
  borderColor: 'var(--color-yellow-400)',
  borderRadius: '8px',
  backgroundColor: 'var(--color-black)',
  minHeight: 48,
  color: 'var(--color-white)',
  cursor: 'pointer',
  transition: 'box-shadow 0.3s',
  '&:hover': {
    boxShadow: '0 0 0 1px var(--color-yellow-300) inset',
  },
  '&:focus, &:active': {
    outline: 0,
  },
};

export const customSelectStyles: StylesConfig<any> = {
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: 'var(--color-grey-600)',
    borderRadius: '8px',
    overflow: 'hidden',
    overscrollBehavior: 'contain',
  }),
  option: (provided: any, { isDisabled, isSelected, isFocused }: any) => ({
    ...provided,
    color:
      isSelected || isFocused ? 'var(--color-yellow)' : 'var(--color-white)',
    padding: 20,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    borderRadius: '8px',
    margin: '0 8px',
    width: 'calc(100% - 16px)',
    backgroundColor: null,
    ':hover': {
      ...provided[':hover'],
      backgroundColor: null,
    },
    ':active': {
      ...provided[':active'],
      backgroundColor: null,
    },
  }),
  control: (provided: any) => ({
    ...provided,
    ...customControlStyles,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: 14,
    color: 'var(--color-grey)',
    fontWeight: 'normal',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: 14,
    color: 'var(--color-white)',
    fontWeight: 'normal',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  input: (provided) => ({
    ...provided,
    color: 'var(--color-white)',
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'var(--color-yellow)',
      color: 'var(--color-black)',
      padding: '5px 0',
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: 14,
    color: 'var(--color-black)',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'var(--color-black)',
    ':hover': {
      backgroundColor: 'transparent',
      color: 'var(--color-white)',
    },
  }),
};
export const twitterSelectStyles: StylesConfig<any> = {
  ...customSelectStyles,
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (provided: any) => ({
    ...provided,
    ...customControlStyles,
    minHeight: 40,
  }),
};
const Option = (props: OptionProps<any, false>) => (
  <components.Option {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.data.dropdownLabel || props.data.label}
  </components.Option>
);

const SingleValue = ({ children, ...props }: SingleValueProps<GlobalProps>) => (
  <components.SingleValue {...props}>
    {children?.toString().split('\n')[0]}
  </components.SingleValue>
);

export const SelectComponent: React.FC<GlobalProps> = ({
  onChange,
  options,
  placeholder,
  className,
  isCreatable = false,
  isDisabled = false,
  isLoading = false,
  hasCustomSingleValue = false,
  value,
  defaultValue,
  groupedOptions = [],
}: GlobalProps) => {
  const screenSize = useScreenSize();

  const handleChange = (newValue: any) => {
    onChange(newValue.value);
  };

  return isCreatable ? (
    <Creatable
      isSearchable={!['mobile', 'tablet'].includes(screenSize)}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onChange={handleChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
      options={options}
      className={className}
      styles={customSelectStyles}
    />
  ) : (
    <Select
      isSearchable={!['mobile', 'tablet'].includes(screenSize)}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onChange={handleChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value || defaultValue}
      options={groupedOptions.length > 0 ? groupedOptions : options}
      className={className}
      styles={customSelectStyles}
      components={hasCustomSingleValue ? { Option, SingleValue } : { Option }}
    />
  );
};

export default SelectComponent;
