import React from 'react';
import cx from 'classnames';

import Modal from 'components/Modal';
import Button from 'components/Button';

import fonts from 'styles/typography.module.scss';
import s from './UpgradePlanModal.module.scss';

export type UpgradePlanModalProps = {
  title: string;
  message: string;
  handleClose: () => void;
  handleSubmit: () => void;
};

const UpgradePlanModal: React.FC<UpgradePlanModalProps> = ({
  title,
  message,
  handleClose,
  handleSubmit,
}) => {
  const buttonText = 'Upgrade';

  return (
    <Modal className={s.container}>
      <h4 className={cx(fonts.title2, s.title, s.text)}>{title}</h4>
      <p className={cx(s.text, fonts.title3, s.uninstallText)}>{message}</p>
      <div className={s.buttonsRow}>
        <Button
          className={s.buttonFixWidth}
          color="secondary"
          onClick={handleClose}
          data-tracking-info={JSON.stringify({
            id: 'upgrade_plan_modal_cancel',
          })}
        >
          Cancel
        </Button>
        <Button
          className={s.buttonFixWidth}
          onClick={handleSubmit}
          data-tracking-info={JSON.stringify({
            id: 'upgrade_plan_modal_confirm',
          })}
        >
          {buttonText}
        </Button>
      </div>
    </Modal>
  );
};

export default UpgradePlanModal;
