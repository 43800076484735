import { getCollabClient } from 'sdk';
import { createAction, createSlice, unwrapResult } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/reducerUtils';
import { addToast } from 'store/toasts/actionCreators';
import { RootState } from 'store';
import { RoleManagerState, UserRole } from '../types';

const initialState: RoleManagerState = {
  userRoles: {
    consistent: false,
    items: [],
  },
  loading: false,
};

export const emptyDiscordRoles = createAction('roleManager/emptyDiscordRoles');

export const getDiscordRoles = createAsyncThunk<
  { consistent: boolean; items: UserRole[] },
  { communityId: string; userId: string },
  { state: RootState }
>(
  'roleManager/getDiscordRoles',
  async ({ communityId, userId }, { getState, dispatch }) => {
    const data = await getCollabClient().roleManager.checkRoles(
      communityId,
      userId,
    );

    const errors = data.results?.[0].errors;
    if (errors) {
      const badRequestError = errors?.[0].includes('BadRequestError');
      dispatch(emptyDiscordRoles());
      dispatch(
        addToast({
          status: 'error',
          description: badRequestError
            ? "Something went wrong, please check your bot's configuration"
            : "User doesn't exist",
        }),
      );
      throw errors;
    }

    const rootState = getState();

    const communityFromState = rootState.community.communityGroups.items.find(
      (community) => community.communityId === communityId,
    );

    const currentRoles = data.results?.[0]['currentRoles'];

    const rolesWithTpcs = communityFromState?.roles.filter((role) =>
      communityFromState.tpcs.some((tpc) => tpc.roleId === role.id),
    );

    const communityData = rolesWithTpcs?.map((role) => {
      const newRole: UserRole = {
        assigned: '',
        current: false,
        expected: false,
        id: role.id,
        name: role.name,
        shouldAssign: '',
      };

      if (currentRoles?.includes(role.id)) {
        newRole.assigned = 'Assigned';
        newRole.shouldAssign = '';
      } else {
        newRole.assigned = 'Not assigned';
        newRole.shouldAssign = '';
      }

      if (!data.results?.[0].consistent) {
        const match = data.results?.[0].roles?.find(
          (assignedRole) => assignedRole.id === role.id,
        );

        if (match) {
          newRole.current = match.current;
          newRole.expected = match.expected;
          newRole.id = match.id;
          newRole.name = match.name;
          if (match.current && !match.expected) {
            newRole.shouldAssign = 'Should not be assigned';
            newRole.assigned = 'Assigned';
          }
          if (!match.current && match.expected) {
            newRole.shouldAssign = 'Should be assigned';
            newRole.assigned = 'Not assigned';
          }
        }
      }

      return newRole;
    });

    return {
      consistent: Boolean(data.results?.[0].consistent),
      items: communityData || [],
    };
  },
);

export const resolveDiscordRoles = createAsyncThunk<
  { consistent: boolean; items: UserRole[] },
  { communityId: string; userId: string },
  { state: RootState }
>(
  'roleManager/resolveDiscordRoles',
  async ({ communityId, userId }, { getState, dispatch }) => {
    // try {
    await getCollabClient().roleManager.applyRoles(communityId, userId);

    const result = unwrapResult(
      await dispatch(getDiscordRoles({ communityId, userId })),
    );

    dispatch(
      addToast({
        status: 'success',
        description: 'All issues have been resolved.',
      }),
    );

    return result;
    // } catch (err: any) {
    //   const errorMessage =
    //     err.statue === 403
    //       ? "The Bot is configured incorrectly, roles related issue(s) can't be resolved."
    //       : 'Issue(s) were not resolved. An error has occured.';

    //   dispatch(
    //     addToast({
    //       status: 'error',
    //       description: errorMessage,
    //     }),
    //   );

    //   throw err;
    // }
  },
);

const roleManagerSlice = createSlice({
  name: 'roleManager',
  initialState,
  reducers: {
    emptyDiscordRoles: (state) => {
      state.userRoles = {
        consistent: true,
        items: [],
      };
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDiscordRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDiscordRoles.fulfilled, (state, action) => {
        state.userRoles = action.payload;
        state.loading = false;
      })
      .addCase(getDiscordRoles.rejected, (state) => {
        state.loading = false;
      })
      .addCase(resolveDiscordRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(resolveDiscordRoles.fulfilled, (state, action) => {
        state.userRoles = action.payload;
        state.loading = false;
      })
      .addCase(resolveDiscordRoles.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default roleManagerSlice.reducer;
