import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/reducerUtils';
import { addToast } from 'store/toasts/actionCreators';

export type LetsGoConfig = {
  title?: string;
  imageUrl?: string;
  donateOptOut?: boolean;
  color?: string;
  description?: string;
};
export type LetsGoState = {
  config: LetsGoConfig;
  loading: boolean;
  error: string | null;
};

const initialState: LetsGoState = {
  config: {},
  loading: false,
  error: null,
};

export const fetchLetsGoConfig = createAsyncThunk(
  'botConfig/fetchLetsGoConfig',
  async (communityPk: string, { dispatch }) => {
    const token = localStorage.getItem('collabLandToken');
    const url = `${
      process.env.REACT_APP_API_URL
    }/communities/${encodeURIComponent(communityPk)}/lets-go-config`;

    const response = await fetch(url, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw response;
    }
    return await response.json();
  },
);

export const saveLetsGoConfig = createAsyncThunk(
  'botConfig/saveLetsGoConfig',
  async (
    {
      communityPk,
      formData,
    }: {
      communityPk: string;
      formData: LetsGoConfig;
    },
    { dispatch },
  ) => {
    const token = localStorage.getItem('collabLandToken');

    const url = `${
      process.env.REACT_APP_API_URL
    }/communities/${encodeURIComponent(communityPk)}/lets-go-config`;

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw response;
    }

    dispatch(
      addToast({
        status: 'success',
        description: 'Config Saved.',
      }),
    );

    dispatch(fetchLetsGoConfig(communityPk));
  },
);

export const publishLetsGoConfig = createAsyncThunk(
  'botConfig/publishLetsGoConfig',
  async (
    {
      communityPk,
    }: {
      communityPk: string;
    },
    { dispatch },
  ) => {
    const token = localStorage.getItem('collabLandToken');

    const url = `${
      process.env.REACT_APP_API_URL
    }/communities/${encodeURIComponent(communityPk)}/lets-go-config`;

    const response = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw response;
    }

    dispatch(
      addToast({
        status: 'success',
        description: 'Config Published.',
      }),
    );
  },
);

const letsGoConfigSlice = createSlice({
  name: 'letsGoConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLetsGoConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLetsGoConfig.fulfilled, (state, { payload }) => {
        state.loading = false;
        delete payload.source;
        state.config = payload;
      })
      .addCase(fetchLetsGoConfig.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message ?? 'Failed to retrieve config';
      })
      .addCase(saveLetsGoConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveLetsGoConfig.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(saveLetsGoConfig.rejected, (state) => {
        state.loading = false;
      })
      .addCase(publishLetsGoConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(publishLetsGoConfig.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(publishLetsGoConfig.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default letsGoConfigSlice.reducer;
