import React from 'react';
import cx from 'classnames';

import { TPC } from 'custom-types';

import Modal from 'components/Modal';
import Button from 'components/Button';
import { twitterAttributes } from 'constants/community';

import fonts from 'styles/typography.module.scss';
import s from './ViewMetadataModal.module.scss';

export type ViewMetadataModalProps = {
  close: () => void;
  activeTpc: TPC;
};

const ViewMetadataModal: React.FC<ViewMetadataModalProps> = ({
  close,
  activeTpc,
}) => {
  const traits =
    activeTpc.chainId.toString() === 'twitter'
      ? activeTpc.traits?.map((meta) => ({
          ...meta,
          name: (twitterAttributes as Record<string, string>)[meta.name],
        }))
      : activeTpc?.traits;
  return (
    <Modal className={s.content}>
      <h4 className={cx(fonts.title2, s.title)}>
        Metadata details for {activeTpc.name}
      </h4>
      <div className={s.gridContainer}>
        <div className={cx(s.gridHeader, s.gridItem)}>
          <p>Trait</p>
          <p>Value</p>
        </div>
        {traits?.map((meta, index) => (
          <div
            className={s.gridItem}
            key={`${meta.name}-${meta.value}-${index}`}
          >
            <p className={fonts.caption1} title={meta.name}>
              {meta.name}
            </p>

            <p className={fonts.caption1} title={meta.value}>
              {meta.value}
            </p>
          </div>
        ))}
      </div>
      <div className={s.row}>
        <Button className={s.cancel} color="secondary" onClick={close}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ViewMetadataModal;
