import { useSelector } from '../store/hooks';
import { Community } from '../custom-types';
import {
  FeatureIdentifiers,
  PlanDetail,
  Subscription,
  SubscriptionStatus,
} from '../store/types';
import { useEffect, useState } from 'react';
import { fetchSubscriptions } from '../store/pricing/service';

function normalizePlanFeatures(activePlanIds: string[], plans: PlanDetail[]) {
  const normalizePlan = plans.map((plan) => {
    const newPlan: PlanDetail = { ...plan };
    const childPlanIds = plan.features
      ?.filter((p) => p.identifier === FeatureIdentifiers.PLAN)
      .map((p) => p.metadata);
    if (childPlanIds) {
      const childPlans = plans.filter((p) => childPlanIds.includes(p.pk));
      if (childPlans) {
        childPlans.forEach((cp) => {
          newPlan.features = newPlan.features?.concat(cp.features ?? []);
        });
      }
    }
    return newPlan;
  });
  const activePlans = normalizePlan.filter((p) => activePlanIds.includes(p.pk));
  return activePlans.map((activePlan) => activePlan.features ?? []).flat();
}

export default function usePlanHook(community?: Community) {
  const activePlans = community?.activePlans ?? [];
  const subscriptions = useSelector(
    (state) => state.pricing.subscription[community?.pk ?? 0],
  );
  const plans = useSelector((state) => state.pricing.plans);
  const filteredPlan = community?.activePlans?.filter((p) =>
    p.startsWith('BOT#PLAN'),
  );
  const isDefaultPlan =
    filteredPlan?.length === 2 && filteredPlan.includes('BOT#PLAN#DEFAULT');
  const defaultPlan = plans?.find((p) => p?.pk === 'BOT#PLAN#DEFAULT');
  const activeFeatures = normalizePlanFeatures(activePlans, [...plans]);

  function checkIfParentPlanIsActive(planId: string) {
    return !!plans.find((plan) => {
      if (activePlans.includes(plan.pk)) {
        return !!plan.features.find(
          (feature) =>
            feature.identifier === FeatureIdentifiers.PLAN &&
            feature.metadata === planId,
        );
      }
      return false;
    });
  }

  function getTPCAllowedCount() {
    let allowedCount = 4;
    activeFeatures?.forEach((f) => {
      if (
        f.identifier === FeatureIdentifiers.TPC &&
        typeof f.metadata === 'number' &&
        f.metadata > allowedCount
      ) {
        allowedCount = f.metadata;
      }
    });
    return allowedCount;
  }

  function checkActiveSubscription(planId: string) {
    return subscriptions?.find(
      (sub) =>
        sub.sk.startsWith(planId) && sub.status === SubscriptionStatus.ACTIVE,
    );
  }

  function checkFeature(
    identifier: FeatureIdentifiers,
    metadataValue?: string,
  ) {
    return activeFeatures?.find((feature) => {
      if (feature.identifier !== identifier) {
        return false;
      }
      if (metadataValue && feature.metadata) {
        if (Array.isArray(feature.metadata)) {
          return feature.metadata.includes(metadataValue);
        }
        return feature.metadata === metadataValue;
      }
      return true;
    });
  }

  return {
    checkIfParentPlanIsActive,
    checkFeature,
    checkActiveSubscription,
    getTPCAllowedCount,
    activeFeatures,
    plans,
    defaultPlanIfActive: isDefaultPlan && defaultPlan,
  };
}
