import {
  EqualityFn,
  useDispatch as useDispatchOriginal,
  useSelector as useSelectorOriginal,
} from 'react-redux';
import { AppDispatch, RootState } from 'store';

export function useDispatch() {
  const dispatch = useDispatchOriginal<AppDispatch>();

  return dispatch;
}

export function useSelector<TState = RootState, Selected = unknown>(
  selector: (state: TState) => Selected,
  equalityFn?: EqualityFn<Selected> | undefined,
): Selected {
  const rootState = useSelectorOriginal(selector, equalityFn);

  return rootState;
}
