import React, { useState } from 'react';
import cx from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'store/hooks';
import { DotsIcon, PlusIcon, ArrowIcon } from 'icons';
import { getRoleColor } from 'utils';
import { TPC } from 'custom-types';

import {
  removeCommunityTPCsByRole,
  removeSelectedRole,
} from 'store/community/actionCreators';

import HeaderSearch from 'components/HeaderSearch';
import Button from 'components/Button';
import TpcCard from 'components/TpcCard';
import RoleName from 'components/RoleName';
import ViewMetadataModal from 'components/ViewMetadataModal';
import NotFound from 'components/NotFound';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';

import fonts from 'styles/typography.module.scss';
import Dropdown from 'components/Dropdown';
import s from './RoleConfig.module.scss';

const RoleConfig: React.FC = () => {
  const { communityId: activeCommunityId, roleId: activeRoleId } =
    useParams() as { communityId: string; roleId?: string };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );

  const [activeTpc, setActiveTpc] = useState<TPC | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const platform = useSelector((state) => state.user.platform);

  const communityFromState = communitiesFromState.find(
    (community) => community.communityId === activeCommunityId,
  );

  const activeRole = communityFromState?.roles.find(
    (role) => role.id === activeRoleId,
  );

  const tpcs =
    platform === 'discord'
      ? communityFromState?.tpcs.filter((tpc) => tpc.roleId === activeRoleId)
      : communityFromState?.tpcs;

  if (activeRoleId && !activeRole) return <NotFound title="Role not found" />;

  const deleteOptions = (
    [
      platform !== 'discord'
        ? 'Delete all TGAs'
        : tpcs && tpcs.length > 0
        ? 'Delete all TGRs'
        : 'Remove Role',
    ] as const
  ).map((label) => ({
    value: label,
    label,
  }));

  return (
    <>
      <main className={s.wrapper}>
        <div className={platform === 'discord' ? s.content : s.contentFull}>
          <HeaderSearch />

          <div className={s.head}>
            {platform === 'discord' && (
              <button
                className={cx(fonts.button1, s.backButton)}
                type="button"
                onClick={() => navigate('..', { relative: 'path' })}
                data-tracking-info={JSON.stringify({
                  id: 'button:back-to-tgrs:click',
                  server_id: activeCommunityId,
                })}
              >
                <ArrowIcon className={s.arrow} title="back icon" />
                Back to TGRs
              </button>
            )}

            <div className={s.headRow}>
              <RoleName
                roleColor={activeRole ? activeRole.color : 16777215}
                className={fonts.title1}
              >
                {platform === 'discord'
                  ? activeRole?.name
                  : 'Token Granted Access page'}
              </RoleName>
              <Button
                color="secondary"
                className={cx(fonts.button1, s.headButton)}
                onClick={() => navigate('add')}
                data-tracking-info={JSON.stringify({
                  id:
                    platform === 'discord'
                      ? 'button:add-tgr:click'
                      : 'button:add-tga:click',
                  server_id: activeCommunityId,
                })}
              >
                <PlusIcon className={s.plus} title="add icon" />
                {platform === 'discord' ? 'Add TGR' : 'Add TGA'}
              </Button>
              {tpcs && tpcs.length > 0 && (
                <Dropdown
                  showArrow={false}
                  trigger={
                    <Button
                      color="empty"
                      disabled={platform !== 'discord' && tpcs.length === 0}
                    >
                      <DotsIcon title="more icon" />
                    </Button>
                  }
                  items={deleteOptions}
                  onChange={(item) => {
                    if (item.value === 'Remove Role') {
                      dispatch(
                        removeSelectedRole({
                          roleId: activeRoleId!,
                          page: 'role-composition',
                        }),
                      );
                      navigate(`../..`, { relative: 'path' });
                      return;
                    }
                    setShowDeleteModal(true);
                  }}
                />
              )}
            </div>
            <p className={cx(fonts.body2, s.headCaption)}>
              {platform === 'discord' &&
                'These requirements must be met to be granted the role'}
              {platform === 'telegram' &&
                'These are all the rules by which a member can access the group'}
              {platform === 'reddit' &&
                'These are all the rules by which a member can access the subreddit'}
            </p>
          </div>

          {tpcs && tpcs.length > 0 ? (
            <div className={s.list}>
              {tpcs.map((tpc) => (
                <TpcCard
                  tpc={{
                    ...tpc,
                    type: tpc.type!,
                    sk: tpc.sk!,
                    pk: tpc.pk!,
                    collectionName: tpc.collectionName!,
                    streamReceiver: tpc.streamReceiver!,
                  }}
                  key={tpc.sk}
                  className={s.cardWrapper}
                  showMetadataCallback={(item) => setActiveTpc(item)}
                />
              ))}
            </div>
          ) : (
            <div className={s.empty}>
              {/* <img
                src={emptyTPCsList}
                alt="empty list"
                className={s.emptyImage}
              /> */}

              {platform === 'discord' ? (
                <p className={cx(s.emptyText, fonts.paragraph)}>
                  You don’t have any TGRs.
                  <br /> Create your first TGR with us!
                </p>
              ) : (
                <p className={cx(s.emptyText, fonts.paragraph)}>
                  You don’t have any TGAs.
                  <br /> Create your first TGA with us!
                </p>
              )}

              <Button
                className={s.emptyButton}
                onClick={() => navigate('add')}
                data-tracking-info={JSON.stringify({
                  id:
                    platform === 'discord'
                      ? 'button:first-add-tgr:click'
                      : 'button:first-add-tga:click',
                  server_id: activeCommunityId,
                })}
              >
                <PlusIcon className={s.plus} title="add icon" />
                {platform === 'discord' ? 'Add TGR' : 'Add TGA'}
              </Button>
            </div>
          )}
        </div>
        {platform === 'discord' && (
          <aside className={s.aside}>
            <h5 className={cx(fonts.title3, s.asideTitle)}>Roles</h5>

            {communityFromState?.roles.map((role) => (
              <button
                key={role.id}
                type="button"
                className={cx(s.roleButton, {
                  [s.active]: role.id === activeRoleId,
                })}
                onClick={() =>
                  navigate(`/dashboard/${activeCommunityId}/tgrs/${role.id}`)
                }
                // @ts-ignore
                style={{ '--custom-color': getRoleColor(role.color) }}
              >
                {role.name}

                <Dropdown
                  showArrow={false}
                  variant="button"
                  collisionPadding={25}
                  trigger={<DotsIcon title="more icon" />}
                  items={[
                    'Add new TGR',
                    tpcs && tpcs.length > 0 ? 'Delete all TGRs' : 'Remove Role',
                  ].map((label) => ({ value: label, label }))}
                  onChange={(item, e) => {
                    e.stopPropagation();
                    const { value } = item;
                    if (value === 'Add new TGR') {
                      navigate(
                        `/dashboard/${activeCommunityId}/tgrs/${role.id}/add`,
                      );
                    } else if (value === 'Delete all TGRs') {
                      setShowDeleteModal(true);
                    } else if (value === 'Remove Role') {
                      dispatch(
                        removeSelectedRole({
                          roleId: activeRoleId!,
                          page: 'role-composition',
                        }),
                      );
                      navigate(`../..`, { relative: 'path' });
                    }
                  }}
                />
              </button>
            ))}
          </aside>
        )}
      </main>

      {activeTpc && (
        <ViewMetadataModal
          close={() => setActiveTpc(null)}
          activeTpc={activeTpc}
        />
      )}

      {showDeleteModal && (
        <DeleteConfirmationModal
          tpcName={activeRole?.name ?? 'all TGAs'}
          close={() => setShowDeleteModal(false)}
          callback={() => {
            setShowDeleteModal(false);
            dispatch(
              removeCommunityTPCsByRole(activeCommunityId, activeRoleId),
            );
          }}
        />
      )}
    </>
  );
};

export default RoleConfig;
