import type { Dispatch } from 'redux';
import { createAsyncThunk as originalCreateAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkPayloadCreator, AsyncThunkOptions } from '@reduxjs/toolkit';
import parseErrorMessage from 'utils/parseErrorMessage';

type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

const fullSerializeError = (err: any) => {
  const errorMessage = parseErrorMessage(err);

  return {
    name: err.name,
    message: errorMessage,
    stack: err.stack,
    statusCode: err.statusCode,
  };
};

export const createAsyncThunk = <
  Returned,
  ThunkArg = void,
  ThunkApiConfig extends AsyncThunkConfig = {},
>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
  options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>,
) => {
  const thunkOptions = {
    serializeError: fullSerializeError,
    ...(options || {}),
  } as AsyncThunkOptions<ThunkArg, ThunkApiConfig>;
  return originalCreateAsyncThunk<Returned, ThunkArg, ThunkApiConfig>(
    typePrefix,
    payloadCreator,
    thunkOptions,
  );
};
