import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'store/hooks';
import emptyServerList from 'images/emptyServerList.png';
import HeaderSearch from 'components/HeaderSearch';
import Button from 'components/Button';
import { toggleRedditConnectModal } from 'store/community/actionCreators';
import fonts from 'styles/typography.module.scss';
import s from './EmptyServerPage.module.scss';
import { modalActions } from '../../store/modal';

const EmptyServerPage: React.FC = () => {
  const platform = useSelector((state) => state.user.platform);
  const communityState = useSelector((state) => state.community);
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      communityState.isCommunityLoaded &&
      !communityState?.communityGroups?.items?.length
    ) {
      if (platform === 'discord') {
        dispatch(modalActions.openInviteModal('discord'));
      } else {
        dispatch(modalActions.openInviteModal('telegram'));
      }
    }
  }, [platform, communityState]);
  return (
    <>
      <HeaderSearch />
      <div className={s.empty}>
        <img src={emptyServerList} alt="empty community" />

        <h2 className={fonts.paragraph}>
          You don’t have any servers connected yet. <br /> Start your first
          Tokenized Community with us!
        </h2>

        <Button
          onClick={() => {
            if (platform === 'reddit') {
              dispatch(toggleRedditConnectModal());
            } else if (platform === 'discord') {
              dispatch(modalActions.openInviteModal('discord'));
            } else {
              dispatch(modalActions.openInviteModal('telegram'));
            }
          }}
        >
          Connect bot
        </Button>
      </div>
    </>
  );
};

export default EmptyServerPage;
