import { CopyIcon } from 'icons';
import React, { useMemo, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';

interface CopyButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  value: string;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
}

const CopyButton = ({
  id,
  value,
  tooltipPosition = 'top',
  children,
  ...props
}: CopyButtonProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleCopy = () => {
    if (navigator.clipboard) {
      setShowTooltip(true);
      navigator.clipboard
        .writeText(value)
        .then(() => {
          /* Successfull copy */
          setTimeout(() => {
            setShowTooltip(false);
          }, 2000);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
    }
  };

  const renderedChildren = useMemo(
    () =>
      children ?? (
        <CopyIcon
          style={{
            width: 16,
            height: 16,
            marginRight: 8,
            color: 'var(--color-grey-100)',
          }}
        />
      ),
    [children],
  );
  return (
    <>
      <button
        ref={buttonRef}
        type="button"
        onClick={handleCopy}
        data-tooltip-content="Copied"
        data-tooltip-id={id}
        {...props}
      >
        {renderedChildren}
      </button>
      <Tooltip
        id={id}
        variant="dark"
        events={['click']}
        // content="Copied"
        place={tooltipPosition}
        isOpen={showTooltip}
      />
    </>
  );
};

export default CopyButton;
