export const GET_COMMUNITY_LIST = 'GET_COMMUNITY_LIST';
export const UPDATE_COMMUNITY = 'UPDATE_COMMUNITY';
export const UPDATE_COMMUNITY_TPCS = 'UPDATE_COMMUNITY_TPCS';
export const ADD_SELECTED_ROLE = 'ADD_SELECTED_ROLE';
export const REMOVE_SELECTED_ROLE = 'REMOVE_SELECTED_ROLE';
export const FILTER_TPC_BY_ROLE = 'FILTER_TPC_BY_ROLE';
export const GET_GUESTPASS_LIST = 'GET_GUESTPASS_LIST';
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_ERROR = 'FETCH_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const TOGGLE_REDDIT_CONNECT_MODAL = 'TOGGLE_REDDIT_CONNECT_MODAL';
export const UPDATE_WALLET_INFO = 'UPDATE_WALLET_INFO';
export const CREATE_WALLET_ERROR = 'CREATE_WALLET_ERROR';
