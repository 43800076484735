/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo } from 'react';
import cx from 'classnames';

import fonts from 'styles/typography.module.scss';
import Dropdown from '../Dropdown';
import s from './SimpleDropdown.module.scss';

type SimpleDropdownItem = {
  label: string;
  value: string;
};

type SimpleDropdownProps = {
  className?: string;
  name?: string;
  label?: string;
  options: SimpleDropdownItem[];
  value: string;
  onChange: (value: string) => void;
};

const SimpleDropdown: React.FC<SimpleDropdownProps> = ({
  className,
  options,
  value,
  onChange,
  label,
}) => {
  const currentLabel = useMemo(() => {
    return options.find((o) => `${o.value}` === `${value}`)?.label ?? '';
  }, [options, value]);

  return (
    <div className={cx(s.root, className)}>
      {label && <p className={cx(s.title, fonts.title5)}>{label}</p>}
      <Dropdown
        variant="outlined"
        trigger={currentLabel}
        items={options}
        onChange={({ value }) => onChange(value)}
      />
    </div>
  );
};

export default SimpleDropdown;
