import React from 'react';
import { useDispatch, useSelector } from 'store/hooks';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { preloadSubs } from '../../store/pricing';

const ValidateCommunity = () => {
  const { communityId } = useParams<{ communityId: string }>();
  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );
  const community = communitiesFromState.find(
    (c) => c.communityId === communityId,
  );

  const subscriptions = useSelector(
    (state) => state.pricing.subscription[community?.pk!],
  );
  const dispatch = useDispatch();
  const isCommunityLoaded = useSelector(
    (state) => state.community.isCommunityLoaded,
  );

  if (communityId && isCommunityLoaded && !community) {
    return <Navigate to={'/dashboard'} />;
  }

  if (!Array.isArray(subscriptions) && community) {
    dispatch(preloadSubs(community.pk));
  }
  return <Outlet />;
};

export default ValidateCommunity;
