import { Middleware } from '@reduxjs/toolkit';
import { TOKEN_EXPIRED } from './user/actionTypes';

export const errorHandleMiddleware: Middleware = () => (next) => (action) => {
  const result = next(action);

  if (result.error?.statusCode === 401) {
    next({ type: TOKEN_EXPIRED });
  }

  return result;
};
