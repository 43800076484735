import BannerLogo from 'images/Full-Logo-White.png';
import { ReactFCC } from 'custom-types/react';

import styles from './AuthLayout.module.scss';

const AuthLayout: ReactFCC = ({ children }) => (
  <div className={styles.root}>
    <img src={BannerLogo} className={styles.logo} alt="logo icon" />

    <div className={styles.container}>{children}</div>
  </div>
);

export default AuthLayout;
