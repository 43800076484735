import React, { useState } from 'react';
import cx from 'classnames';
import { getRoleColor } from 'utils';
import s from './CheckBox.module.scss';

let checkboxId = 0;

function getId(prefix = 'checkbox') {
  checkboxId += 1;
  return `${prefix}${checkboxId}`;
}

type CheckboxProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type' | 'onChange'
> & {
  label?: string;
  labelColor?: number;
  onChange?: (value: boolean) => void;
};

const Checkbox = (props: CheckboxProps) => {
  const { label, id, className, labelColor, onChange, ...nativeProps } = props;
  const [htmlId] = useState(id ?? getId());

  return (
    <div className={cx(s.checkBoxContainer, className)}>
      <input
        type="checkbox"
        className={s.checkBox}
        style={{ marginRight: 5, marginTop: 2 }}
        id={htmlId}
        onChange={(e) => onChange?.(e.target.checked)}
        {...nativeProps}
      />
      {label && (
        <label
          className={s.inputNormal}
          style={
            labelColor
              ? {
                  color: getRoleColor(labelColor),
                }
              : undefined
          }
          htmlFor={htmlId}
        >
          {label}
        </label>
      )}
    </div>
  );
};
export default Checkbox;
