import { CollabLandClient } from '@collabland/sdk';

let collabLandClient: CollabLandClient = new CollabLandClient(
  {},
  process.env.REACT_APP_API_URL!,
);

export const connectToSDK = async (token: string): Promise<void> => {
  const client = new CollabLandClient(
    {
      collabLandOAuth2: token,
    },
    process.env.REACT_APP_API_URL!,
  );

  await client.connect();
  collabLandClient = client;
};

export const getCollabClient = (): CollabLandClient => collabLandClient;

let defaultHeaders: Record<string, any> = {};

export default async function httpClient(
  url: string,
  options: RequestInit = {},
) {
  options.headers = {
    ...defaultHeaders,
    'Content-Type': 'application/json',
    ...(options.headers || {}),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL!}/${url}`,
    options,
  );
  const isBodyEmpty = response.status === 204;

  if (isBodyEmpty) {
    return null;
  }

  const contentType = response.headers.get('content-type');

  if (!contentType || !contentType.includes('application/json')) {
    return response;
  }

  const payload = await response.json();
  const unauthorized = 401;

  if (
    response.status === unauthorized ||
    payload?.error?.statusCode === unauthorized
  ) {
    const error = new ApiError('Session expired');
    window.location.href = '/login';
    error.statusCode = unauthorized;

    throw error;
  }

  if (payload?.error) {
    let message =
      payload.error.message ||
      payload.error.name ||
      payload.error.code ||
      payload.error;
    const isString = typeof message === 'string' || message instanceof String;

    if (!isString) {
      message = 'Invalid operation';
    }

    if (Array.isArray(payload.error.details)) {
      message = payload.error.details.map((err: any) => err.message).join('\n');
    }

    const error = new ApiError(message);

    error.statusCode = payload.error.statusCode;

    throw error;
  }

  return payload;
}

class ApiError extends Error {
  statusCode?: number;
}

httpClient.setDefaultHeaders = function (headers: Record<string, any>) {
  defaultHeaders = {
    ...defaultHeaders,
    ...headers,
  };
};
