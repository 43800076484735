import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'store/hooks';
import {
  getBackgroundCheckStatus,
  updateCommunityConfig,
} from 'store/community/actionCreators';
import {
  checkCommunityBotConf,
  hideBotBanner,
  showBotBanner,
  unlinkSubRedditToken,
} from 'store/discordBot';
import { enableProBotFeaturesV1 } from 'constants/features';
import Modal from 'components/Modal';
import Switch from 'components/Switch';
import Button from 'components/Button';
import NotificationBanner from 'components/NotificationBanner';
import HeaderSearch from 'components/HeaderSearch';
import LinkCopy from 'components/LinkCopy';
import BalanceCheck from './BalanceCheck/BalanceCheck';
import CustomMessage from './CustomMessage/CustomMessage';
import { CheckIcon, CloseIcon, EditIcon, InfoIcon } from 'icons';

import fonts from 'styles/typography.module.scss';
import s from './BotConfig.module.scss';
import usePlanHook from '../../hooks/usePlanHook';
import { FeatureIdentifiers } from '../../store/types';
import CommunityConfigToggle from './CommunityConfigToggle/CommunityConfigToggle';

const BotConfig: React.FC = () => {
  const { communityId: activeCommunityId } = useParams() as {
    communityId: string;
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState({
    checkBotLoading: false,
    unlinkLoading: false,
    updateCredLoading: false,
  });
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [bgBalanceCheck, setBgBalanceCheck] = useState(false);
  const [isEditMessage, setIsEditMessage] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);

  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );

  const telegramInvitationLink = communitiesFromState.find(
    (community) => community.communityId === activeCommunityId,
  )!.link;

  const botCheckResponse = useSelector((state) => {
    if (state.discordBot[activeCommunityId]) {
      return state.discordBot[activeCommunityId].responseData;
    }
    return [];
  });

  const showBanner = useSelector((state) => {
    if (state.discordBot[activeCommunityId])
      return state.discordBot[activeCommunityId].showBanner;
    return null;
  });

  const platform = useSelector((state) => state.user.platform);

  const activeCommunity = communitiesFromState.find(
    (community) => community.communityId === activeCommunityId,
  )!;

  const { checkFeature } = usePlanHook(activeCommunity);
  const redditInvitationLink = `${process.env.REACT_APP_API_URL}/reddit/authorize?state=${activeCommunityId}&subreddit=${activeCommunity.name}&redirect_uri=${process.env.REACT_APP_REDDIT_REDIRECT_URI}`;

  useEffect(() => {
    const communityFromState = communitiesFromState.find(
      (community) => community.communityId === activeCommunityId,
    );
    if (communityFromState) {
      setShowWelcomeMessage(communityFromState.isWelcomeMsgEnabled);
      setBgBalanceCheck(!communityFromState.disableBkgBalCheck);
      setWelcomeMessage(communityFromState.welcomeMessage);
    }
  }, [activeCommunityId, communitiesFromState]);

  useEffect(() => {
    if (activeCommunityId && platform === 'discord') {
      getBackgroundCheckStatus(activeCommunityId).then((result) => {
        if (!result.allowed) {
          setErrorMessage(
            'Reduce TGRs or upgrade subscription plan to resolve.',
          );
        }
      });
    }
  }, [activeCommunityId]);
  const submitWelcomeMessage = () => {
    setIsEditMessage(false);

    dispatch(
      updateCommunityConfig(activeCommunityId, {
        welcomeMessage,
      }),
    );
  };

  const toggleShowWelcomeMessage = (isEnabled: boolean) => {
    dispatch(
      updateCommunityConfig(activeCommunityId, {
        isWelcomeMsgEnabled: isEnabled,
      }),
    );

    setShowWelcomeMessage(isEnabled);
  };

  const toggleBgBalanceCheck = (isEnabled: boolean) => {
    dispatch(
      updateCommunityConfig(activeCommunityId, {
        disableBkgBalCheck: !isEnabled,
      }),
    );

    setBgBalanceCheck(isEnabled);
  };

  const checkBotConfig = async () => {
    setLoading({ ...loading, checkBotLoading: true });

    const dispatchCheckCommunityBot = dispatch(
      checkCommunityBotConf({ communityId: activeCommunityId }),
    );

    try {
      await dispatchCheckCommunityBot.unwrap();
    } finally {
      dispatch(showBotBanner({ communityId: activeCommunityId }));
      setLoading({ ...loading, checkBotLoading: false });
    }
  };

  const unlinkRedditToken = async () => {
    setLoading({ ...loading, unlinkLoading: true });

    const dispatchUnlinkSubRedditToken = dispatch(
      unlinkSubRedditToken({ communityId: activeCommunityId }),
    );

    try {
      await dispatchUnlinkSubRedditToken.unwrap();
    } finally {
      localStorage.removeItem('collabLandToken');
      navigate('/login', { state: { from: '/' } });
    }
  };

  return (
    <>
      <HeaderSearch />
      <div className={s.content}>
        {showBanner && (
          <NotificationBanner
            close={() =>
              dispatch(hideBotBanner({ communityId: activeCommunityId }))
            }
            msgClass={botCheckResponse.length > 1 ? 'warningMsg' : 'successMsg'}
            msgContents={botCheckResponse}
            msgTitle={
              botCheckResponse.length > 1
                ? 'WARNING: Bot is NOT configured correctly'
                : 'Check complete'
            }
          />
        )}
        {errorMessage && (
          <NotificationBanner
            close={() => setErrorMessage(undefined)}
            msgClass={'warningMsg'}
            msgContents={[
              {
                msg: (
                  <>
                    Reduce TGRs or{' '}
                    <Link to={`/dashboard/${activeCommunityId}/subscription`}>
                      upgrade subscription plan
                    </Link>{' '}
                    to resolve.
                  </>
                ),
              },
            ]}
            msgTitle={
              'Warning: Subscription TGRs exceeded, balance check is paused.'
            }
          />
        )}
        <h5 className={cx(fonts.title1, s.title)}>Bot Config</h5>
        {(platform === 'discord' || platform === 'reddit') && (
          <>
            <div className={s.row}>
              <div className={s.column}>
                <h6 className={cx(s.subtitle, fonts.title3)}>
                  Check Bot Configuration
                </h6>

                <p className={cx(s.text, fonts.caption1)}>
                  Check if the bot is properly configured
                </p>
              </div>

              <Button
                color="secondary-light"
                className={
                  loading.checkBotLoading
                    ? s.checkBotButtonLoading
                    : s.checkBotButton
                }
                onClick={() => checkBotConfig()}
                data-tracking-info={JSON.stringify({
                  id: 'button:check-bot-config:click',
                  server_id: activeCommunityId,
                })}
              >
                {loading.checkBotLoading
                  ? 'Checking...'
                  : 'Check configuration'}
              </Button>
            </div>
            {platform === 'discord' && enableProBotFeaturesV1 && (
              <>
                <div className={s.separator} />
                <CommunityConfigToggle
                  activeCommunityId={activeCommunityId}
                  disabled={!checkFeature(FeatureIdentifiers.TOGGLE_UPDATES)}
                  description={
                    "Community messages are relevant updates and services specifically tailored for your members' needs and interests"
                  }
                  name={'Community Messaging'}
                  keyword={'disablePromotionalUpdates'}
                />
                <div className={s.row}>
                  <div className={s.column}>
                    <h6 className={cx(s.subtitle, fonts.title3)}>
                      Balance check
                    </h6>

                    <p className={cx(s.text, fonts.caption1)}>
                      Membership verification in regular intervals is enabled.
                    </p>
                  </div>
                  <Switch
                    onChange={toggleBgBalanceCheck}
                    isChecked={
                      !!checkFeature(FeatureIdentifiers.BG_BALANCE_CHECK) &&
                      bgBalanceCheck
                    }
                    isDisabled={
                      botCheckResponse?.length > 1 ||
                      !checkFeature(FeatureIdentifiers.BG_BALANCE_CHECK)
                    }
                    data-tracking-info={JSON.stringify({
                      id: 'switch:bg-balance-check:toggle',
                      server_id: activeCommunityId,
                    })}
                  />
                </div>
                {checkFeature(FeatureIdentifiers.ON_DEMAND_BALANCE_CHECK) && (
                  <BalanceCheck
                    onChange={toggleBgBalanceCheck}
                    isChecked={bgBalanceCheck}
                  />
                )}
                <div className={s.separator} />
                <CustomMessage />
              </>
            )}
            {/*{enableProBotFeaturesV2 && (*/}
            {/*  <>*/}
            {/*    <div className={s.separator} />*/}
            {/*    <div className={s.separator} />*/}
            {/*    <WhitelistedRoles />*/}
            {/*    <div className={s.separator} />*/}
            {/*    <AddressShare />*/}
            {/*  </>*/}
            {/*)}*/}
          </>
        )}
        {platform === 'telegram' && (
          <>
            <div className={s.row}>
              <div className={s.column}>
                <h6 className={cx(s.subtitle, fonts.title3)}>Balance check</h6>

                <p className={cx(s.text, fonts.caption1)}>
                  Membership verification in regular intervals is enabled.
                </p>
              </div>
              <Switch
                onChange={toggleBgBalanceCheck}
                isChecked={
                  // !!checkFeature(FeatureIdentifiers.BG_BALANCE_CHECK) &&
                  bgBalanceCheck
                }
                isDisabled={
                  botCheckResponse?.length > 1
                  // || !checkFeature(FeatureIdentifiers.BG_BALANCE_CHECK)
                }
                data-tracking-info={JSON.stringify({
                  id: 'switch:bg-balance-check:toggle',
                  server_id: activeCommunityId,
                })}
              />
            </div>
            <div className={s.separator} />
            <div className={s.row}>
              <h6 className={cx(s.subtitle, fonts.title3)}>
                Show community welcome message
              </h6>
              <Switch
                onChange={toggleShowWelcomeMessage}
                isChecked={showWelcomeMessage}
              />
            </div>

            <p className={cx(s.text, fonts.caption1)}>
              Add a welcome message to send to all new group members.
            </p>
            <p className={cx(s.text, fonts.caption1)}>
              This message will be sent as DM
            </p>

            {showWelcomeMessage && (
              <div className={s.form}>
                <div className={s.formHeader}>
                  <p className={cx(fonts.title5, s.formTitle)}>
                    Welcome message
                  </p>

                  {isEditMessage ? (
                    <>
                      <button
                        className={cx(s.cancel, s.button)}
                        type="button"
                        onClick={() => {
                          setIsEditMessage(false);
                          setWelcomeMessage(activeCommunity.welcomeMessage);
                        }}
                      >
                        <CloseIcon className={s.icon} />
                        Cancel
                      </button>

                      <button
                        className={s.button}
                        type="button"
                        onClick={submitWelcomeMessage}
                      >
                        <CheckIcon className={s.icon} />
                        Save
                      </button>
                    </>
                  ) : (
                    <button
                      className={s.button}
                      type="button"
                      onClick={() => setIsEditMessage(true)}
                    >
                      <EditIcon className={s.icon} />
                      Edit
                    </button>
                  )}
                </div>

                <textarea
                  placeholder="Enter welcome message"
                  rows={8}
                  disabled={!isEditMessage}
                  className={s.textarea}
                  value={welcomeMessage || ''}
                  onChange={(e) => setWelcomeMessage(e.target.value)}
                />

                <div className={s.info}>
                  <InfoIcon className={s.icon} />

                  <p className={cx(fonts.caption2, s.infoTextColor)}>
                    Welcome message can include links.
                  </p>
                </div>
              </div>
            )}
          </>
        )}
        {(platform === 'telegram' || platform === 'reddit') && (
          <>
            <div className={s.separator} />
            <div className={s.row}>
              <div className={s.column}>
                <h6 className={cx(s.subtitle, fonts.title3)}>Invite link</h6>

                <p className={cx(s.text, fonts.caption1)}>
                  Share this unique invite link to invite new members
                </p>
              </div>
              <div className={s.linkarea}>
                <LinkCopy
                  label="Copy link:"
                  value={
                    platform === 'reddit'
                      ? redditInvitationLink
                      : telegramInvitationLink
                  }
                  isUrl
                  data-tracking-info={JSON.stringify({
                    id: 'button:copy-telegram-invite-link:click',
                    server_id: activeCommunityId,
                  })}
                />
              </div>
            </div>
          </>
        )}
        {platform === 'reddit' && (
          <>
            <div className={s.separator} />
            <div className={s.row}>
              <div className={s.column}>
                <h6 className={cx(s.subtitle, fonts.title3)}>Unlink token</h6>

                <p className={cx(s.text, fonts.caption1)}>
                  Remove Collab.land from your subreddit
                </p>
              </div>

              <Button
                color="danger-outline"
                className={
                  loading.unlinkLoading
                    ? s.checkBotButtonLoading
                    : s.checkBotButton
                }
                onClick={() => setShowUnlinkModal(true)}
              >
                {loading.unlinkLoading ? 'Working...' : 'Unlink'}
              </Button>
            </div>
          </>
        )}
        {showUnlinkModal && (
          <Modal className={s.modal}>
            <h4 className={cx(fonts.title2, s.title)}>Unlink Subreddit</h4>

            <p className={cx(fonts.body1, s.text)}>
              Are you sure you want to unlink your token? This action will log
              you out and remove the subreddit from the list.
            </p>

            <div className={s.row}>
              <Button
                type="button"
                color="secondary"
                className={s.cancel}
                onClick={() => setShowUnlinkModal(false)}
              >
                Cancel
              </Button>

              <Button
                type="button"
                color="danger"
                className={s.submit}
                onClick={() => {
                  unlinkRedditToken();
                  setShowUnlinkModal(false);
                }}
              >
                Unlink
              </Button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default BotConfig;
