import React from 'react';
import SwitchFromLib from 'react-switch';

import { CheckIcon, CloseIcon } from 'icons';

export type SwitchProps = {
  onChange: (s: boolean) => void;
  isChecked: boolean;
  isDisabled?: boolean;
};

const Switch: React.FC<SwitchProps> = ({
  isChecked = false,
  isDisabled = false,
  onChange,
}) => {
  return (
    <SwitchFromLib
      checked={isChecked}
      onChange={onChange}
      handleDiameter={18}
      offColor={isDisabled ? '#454545' : '#8B8B8B'}
      offHandleColor="#686868"
      onColor="#cf9b1e"
      onHandleColor={isDisabled ? '#a17408' : '#f6c349'}
      activeBoxShadow={
        isChecked
          ? '0px 0px 1px 10px rgba(246, 195, 73, 0.6)'
          : '0px 0px 1px 10px rgba(54, 54, 54, 0.6)'
      }
      height={12}
      width={30}
      disabled={isDisabled}
      uncheckedIcon={false}
      checkedIcon={false}
      uncheckedHandleIcon={
        <CloseIcon
          style={{ width: '12px', color: 'white' }}
          title="unchecked icon"
        />
      }
      checkedHandleIcon={
        <CheckIcon
          style={{ width: '12px', color: 'white' }}
          title="checked icon"
        />
      }
      className="react-switch"
      id="small-radius-switch"
    />
  );
};

export default Switch;
