import fonts from 'styles/typography.module.scss';
import s from './PremiumSupport.module.scss';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Badge from '../Badge';
import { subscribe } from '../../store/pricing/service';
import { addToast } from '../../store/toasts/actionCreators';
import { useDispatch, useSelector } from '../../store/hooks';
import useGetCurrentCommunity from '../../hooks/useGetCurrentCommunity';
import usePlanHook from '../../hooks/usePlanHook';
import { FeatureIdentifiers } from '../../store/types';
import useClipboard from '../../hooks/useClipboard';
import { getDiscordSupportChannelLink } from '../../store/premiumSupport';
import { AlarmClock, Bubble, CheckSharpIcon, CopyIcon } from 'icons';
import Button from '../Button';
import Spinner from '../Spinner';
import CancelSubscriptionModal from '../CancelSubscriptionModal';

const PremiumSupport = () => {
  const dispatch = useDispatch();
  const community = useGetCurrentCommunity();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { loading, link, code } = useSelector((state) => state.premiumSupport);
  const { copy } = useClipboard();
  const { checkFeature, checkActiveSubscription } = usePlanHook(community);

  const subscription = checkActiveSubscription(`SUPPORT#PLAN#PREMIUM`);
  useEffect(() => {
    if (checkFeature(FeatureIdentifiers.PREMIUM_SUPPORT) && !link) {
      dispatch(getDiscordSupportChannelLink(community.pk));
    }
  }, [dispatch, checkFeature, community.pk, link]);
  const handleSubmit = async (type: string) => {
    try {
      const data = await subscribe(type, 'SUPPORT#PLAN#PREMIUM', {
        subscriberId: community.pk,
        redirectUri: `${window.location.origin}/dashboard/${community.communityId}/premium-support`,
        state: {},
      });
      window.location.href = data.url;
    } catch (err) {
      dispatch(
        addToast({
          description: 'Failed to subscribe',
          status: 'error',
        }),
      );
      throw err;
    }
  };
  return (
    <>
      <main className={s.wrapper}>
        {showCancelModal && (
          <CancelSubscriptionModal
            handleClose={() => {
              setShowCancelModal(false);
            }}
            subscriberId={community.pk}
            planId={'SUPPORT#PLAN#PREMIUM'}
          />
        )}
        <div className={s.content}>
          <div className={s.head}>
            <div className={s.headRow}>
              <div className={s.headTitleContainer}>
                <div>
                  <div className={s.titleContainer}>
                    <h2 className={cx(fonts.title1, s.headerTitle)}>
                      {'CollabCare'}
                    </h2>{' '}
                    <Badge className={s.badge}>Pro</Badge>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <>
            <div className={s.container}>
              {!checkFeature(FeatureIdentifiers.PREMIUM_SUPPORT) ? (
                <>
                  <iframe
                    className={s.video}
                    src="https://www.youtube.com/embed/ES6qI16-6kA?si=2dWAWgtWuenbTBy8"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                  <div className={s.infoCard}>
                    <div className={s.item}>
                      <div className={s.itemIcon}>
                        {' '}
                        <img alt="Check" src={CheckSharpIcon} />
                      </div>
                      <div className={s.itemContent}>
                        <div className={s.itemHeading}>Direct Connection</div>
                        <p className={s.itemText}>
                          With CollabCare, immerse yourself in a world where
                          barriers don&#39;t exist. Our premium channel is not
                          just about getting answers; it’s about building a
                          relationship. Here, you’ll experience direct,
                          immediate, and uniquely personalized attention for all
                          your Collab.Land queries. No bots, no automated
                          responses - just real-time solutions tailored to you.
                          You&#39;re not just another project; you&#39;re a part
                          of our inner circle.
                        </p>
                      </div>
                    </div>
                    <div className={s.item}>
                      <div className={s.itemIcon}>
                        <img alt="Check" src={AlarmClock} />
                      </div>
                      <div className={s.itemContent}>
                        <div className={s.itemHeading}>
                          Skip the Support Line, Your time is valuable.
                        </div>
                        <p className={s.itemText}>
                          {' '}
                          Why wait? As a premium member, the typical support
                          line isn’t in your vocabulary. Get prompt answers,
                          actionable solutions, and exceptional speed in
                          addressing all your community needs. Experience
                          support as it should be – swift and efficient.
                        </p>
                      </div>
                    </div>
                    <div className={s.item}>
                      <div className={s.itemIcon}>
                        {' '}
                        <img alt="Check" src={Bubble} />
                      </div>
                      <div className={s.itemContent}>
                        <div className={s.itemHeading}>Invite Your Team</div>
                        <p className={s.itemText}>
                          {' '}
                          Collaboration is the heart of innovation. Extend the
                          perks of CollabCare to up to 10 of your team members.
                          Engage, collaborate, and benefit as a cohesive unit.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className={s.buttonsContainer}>
                    <div className={s.buttonCard}>
                      <label className={s.labelTextWrapper}>
                        Pay with Card
                      </label>
                      <Button
                        onClick={() => handleSubmit('stripe')}
                        data-tracking-info={JSON.stringify({
                          id: 'button:buy-collabcare:click',
                          server_id: community.communityId,
                        })}
                      >
                        Buy $199/mo
                      </Button>
                    </div>
                    <div className={s.buttonCard}>
                      <label className={s.labelTextWrapper}>
                        Pay with Crypto
                      </label>
                      <Button
                        onClick={() => {
                          // handleSubmit('unlock')
                        }}
                      >
                        Coming soon
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className={s.detailCard}>
                  <div className={s.itemContent}>
                    <div className={s.itemHeading}>
                      Dedicated Support Channel
                    </div>
                    <p className={s.itemText}>
                      Here is the personal invite link to your project's
                      dedicated Discord channel where the Collab.Land team will
                      be available to assist you with any issues that arise. You
                      may share this link and code with up to 10 teammates.
                    </p>
                  </div>
                  {loading && <Spinner />}
                  {!!link && !!code && (
                    <>
                      <div className={s.copyContainer}>
                        <a className={s.textContainer} href={link}>
                          {link}
                        </a>
                        <Button
                          className={s.copyButton}
                          color="secondary"
                          onClick={() => copy(link, 'Invite Link')}
                        >
                          <CopyIcon />
                        </Button>
                      </div>
                      <div className={s.copyContainer}>
                        <label className={s.textContainer}>{code}</label>
                        <Button
                          className={s.copyButton}
                          color="secondary"
                          onClick={() => copy(code, 'Code')}
                        >
                          <CopyIcon />
                        </Button>
                      </div>
                    </>
                  )}
                  {subscription && (
                    <div className={s.cancelButtonsContainer}>
                      {!subscription.isCancelled && (
                        <Button
                          onClick={() => {
                            setShowCancelModal(true);
                          }}
                        >
                          Cancel Subscription
                        </Button>
                      )}
                      {subscription.isCancelled && subscription.cancelOn && (
                        <Button color={'secondary'}>
                          Validate until{' '}
                          {new Date(
                            subscription.cancelOn * 1000,
                          ).toLocaleDateString(navigator.language)}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        </div>
      </main>
    </>
  );
};

export default PremiumSupport;
