import React, { ReactNode } from 'react';
import { ButtonProps } from 'components/Button/Button';
import { CloseIcon } from 'icons';

import s from './NewsTickerBanner.module.scss';

type NewsTickerBannerProps = Omit<ButtonProps, 'renderContainer'> & {
  content: ReactNode;
  close: () => void;
};

const NewsTickerBanner: React.FC<NewsTickerBannerProps> = ({
  content,
  close,
}: NewsTickerBannerProps) => (
  <div className={s.msgContainer}>
    <div className={s.msgHeaderLeft}>
      <p className={s.vitem}>{content}</p>
    </div>
    <div className={s.msgHeaderRight}>
      <button type="button" className={s.closeButton} onClick={close}>
        <CloseIcon className={s.xIcon} title="close alt dark" />
      </button>
    </div>
  </div>
);

export const AdBanner: React.FC<NewsTickerBannerProps> = ({
  content,
  close,
}: NewsTickerBannerProps) => (
  <div className={s.adContainer}>
    <div className={s.adHeaderLeft}>
      <p className={s.vitem}>{content}</p>
    </div>
    <div className={s.adHeaderRight}>
      <button type="button" className={s.closeButton} onClick={close}>
        <CloseIcon className={s.xIcon} title="close alt dark" />
      </button>
    </div>
  </div>
);

export default NewsTickerBanner;
