import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { useSelector } from 'store/hooks';
import Button from 'components/Button';
import SupportModal from 'components/SupportModal';
import { ArrowIcon } from 'icons';
import fonts from 'styles/typography.module.scss';
import EmptyServerPage from 'components/EmptyServerPage';
import s from './OverviewPage.module.scss';

const OverviewPage: React.FC = () => {
  const { communityId: activeCommunityId } = useParams();
  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    const iframeLocalStorage =
      window.sessionStorage.getItem('showIframe') === 'true';
    setShowIframe(iframeLocalStorage);
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem('showIframe', String(showIframe));
  }, [showIframe]);

  const activeCommunity = communitiesFromState.find(
    (community) => community.communityId === activeCommunityId,
  );

  const renderPrimer = () => {
    return (
      <div className={s.primerContainer}>
        <h4 className={cx(fonts.title2, s.title)}>
          Login with your credentials
        </h4>
        <p className={cx(s.text, fonts.caption1)}>
          Or contact us to get access to community insights
        </p>
        <Button className={s.btn} onClick={() => setShowIframe(true)}>
          Login
        </Button>
        <Button
          className={s.btn}
          color="secondary"
          onClick={() => setShowSupportModal(true)}
        >
          Contact us
        </Button>
      </div>
    );
  };

  return activeCommunity ? (
    <div className={s.content}>
      <h2 className={cx(fonts.title1, s.headerTitle)}>Analytics page</h2>
      {showIframe && (
        <button
          className={cx(fonts.button1, s.backButton)}
          type="button"
          onClick={() => setShowIframe(false)}
        >
          <ArrowIcon className={s.arrow} title="back icon" />
          Back
        </button>
      )}
      {showIframe && (
        <iframe
          title="Dashboard Analytics"
          src={`https://opensearch.collab.land/_dashboards/app/dashboards?security_tenant=global#/view/66c2a290-5812-11ec-9944-59d449739fb6?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(description:'',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'6aa9d450-4057-11ec-9944-59d449739fb6',key:body.communityId,negate:!f,params:(query:DIS%23COMM%23${activeCommunityId}),type:phrase),query:(match_phrase:(body.communityId:DIS%23COMM%23${activeCommunityId})))),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'QA%20Dashboard',viewMode:view)&hide-filter-bar=true`}
        />
      )}
      {!showIframe && renderPrimer()}
      {showSupportModal && (
        <SupportModal close={() => setShowSupportModal(false)} />
      )}
    </div>
  ) : (
    <EmptyServerPage />
  );
};

export default OverviewPage;
