import cx from 'classnames';
import s from './TGRQuota.module.scss';
import fonts from '../../styles/typography.module.scss';
import React from 'react';
import usePlanHook from '../../hooks/usePlanHook';
import useGetCurrentCommunity from '../../hooks/useGetCurrentCommunity';
import { InfoIcon } from '../../icons';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export const TGRQuota = () => {
  const communityFromState = useGetCurrentCommunity();
  const { getTPCAllowedCount, defaultPlanIfActive } =
    usePlanHook(communityFromState);
  const allowedTGR = getTPCAllowedCount();
  const currentTGR =
    communityFromState.roleCompositionTpcs.length +
    communityFromState.tpcs.length;
  const remainingTGR = allowedTGR - currentTGR;
  return (
    <div>
      <div className={cx(s.tpcDetail)}>
        <div className={cx(s.tpcDetailRow, fonts.caption1)}>
          <p style={{ display: 'flex', gap: 2 }}>
            Total TGRs Created:{' '}
            <div
              data-tooltip-content="includes Role Composition"
              data-tooltip-id="tooltip-info"
            >
              <InfoIcon className={s.infoIcon} title="question icon" />
            </div>
            <ReactTooltip id="tooltip-info" place="right" variant="dark" />
          </p>
          <p>{currentTGR}</p>
        </div>
        <div className={cx(s.tpcDetailRow, fonts.caption1)}>
          <p>Subscription TGR + Role Composition Allowance:</p>
          <p>{allowedTGR > 10000 ? 'Unlimited' : allowedTGR}</p>
        </div>
        <div className={cx(s.tpcDetailRow, fonts.caption1)}>
          <p>Subscription Allowance Remaining:</p>
          <p>{allowedTGR > 10000 ? 'Unlimited' : remainingTGR}</p>
        </div>
      </div>
      {defaultPlanIfActive && (
        <p style={{ fontSize: 14, fontWeight: 700 }}>
          TGRs are unlimited until May 1, 2024
        </p>
      )}
    </div>
  );
};
