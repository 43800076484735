import React, { useState } from 'react';
import Button from 'components/Button';
import telegramChatPermission from 'images/telegramChatPermission.png';
import telegramChatSetting from 'images/telegramChatSetting.png';
import telegramAddMember from 'images/telegramAddMember.png';
import s from './TelegramInviteCard.module.scss';
import usePopup, { PopUpEvents } from '../../../../hooks/usePopupWindow';
import { useDispatch, useSelector } from '../../../../store/hooks';
import { getBotUrl } from '../../../../constants/community';
import { getCommunitiesAction } from '../../../../store/community/actionCreators';
import { Telegram } from '../../../../icons';

const TelegramInviteCard = ({
  onSuccess,
  onClose,
}: {
  onSuccess: () => void;
  onClose: () => void;
}) => {
  const [invitedGroup, setInvitedGroup] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const user = useSelector((state) => state.user);
  const popup = usePopup();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const onClickInstall = (community: any) => {
    popup.open(
      PopUpEvents.BOT_INVITED,
      getBotUrl('telegram', community.id),
      () => {
        dispatch(getCommunitiesAction());
        setInvitedGroup(true);
        onSuccess();
      },
      400,
      700,
    );
  };
  return (
    <>
      {!invitedGroup && (
        <>
          {page === 0 ? (
            <>
              <div className={s.heading}>
                Welcome, {user['user_profile'].name}!
              </div>
              <div className={s.contextText}>
                This is the Command Center. It is where you will set up and
                configure the Collab.Land Bot.
              </div>
              <div className={s.heading2}>Prepare your Telegram group</div>
              <div className={s.contextText2}>
                You will need to set your group’s Chat History <br /> Settings
                to
                <i>Visible</i>.{' '}
                <a
                  className={s.learnMoreLink}
                  href="https://docs.collab.land/help-docs/set-up-the-bot/telegram-setup"
                >
                  Learn More
                </a>
              </div>
              <img
                className={s.chatSetting}
                alt="Chat setting"
                src={telegramChatSetting}
              />
            </>
          ) : (
            <>
              <div className={s.heading}>Invite Collab.Land Bot</div>
              <Button
                className={s.telegramBtn}
                color="primary"
                onClick={onClickInstall}
              >
                <img src={Telegram} alt={'Telegram icon'} />
                Invite Bot
              </Button>
              <div className={s.heading2}>Or add the bot manually</div>
              <div className={s.contextText2}>
                Please enter the username exactly - beware of imposters with
                similar names
              </div>
              <img
                className={s.addMember}
                alt="Add member"
                src={telegramAddMember}
              />
              <div className={s.contextText2}>
                Then, set the Collab.Land bot as an admin.
              </div>
            </>
          )}
          <div className={s.buttonGroup}>
            {page !== 0 && (
              <Button color={'secondary'} onClick={() => setPage(0)}>
                Back
              </Button>
            )}
            <Button
              color={'primary'}
              onClick={() => {
                if (page === 0) {
                  setPage(1);
                }
                if (page === 1) {
                  dispatch(getCommunitiesAction());
                  setInvitedGroup(true);
                  onSuccess();
                }
              }}
            >
              Next
            </Button>
          </div>
        </>
      )}
      {invitedGroup && (
        <>
          <div className={s.heading}>One last thing!</div>
          <div className={s.contextText}>
            If you invited the bot manually, make sure to make the bot an Admin
            with the following permissions.
          </div>

          <img
            className={s.permissionGuide}
            alt="Permission Guide"
            src={telegramChatPermission}
          />

          {/*<Button*/}
          {/*  className={s.tgrButton}*/}
          {/*  onClick={() => {*/}
          {/*    navigate(`/dashboard/${invitedGroup.id}/tgrs`);*/}
          {/*    onClose();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Set Up A Token Granted Role*/}
          {/*</Button>*/}
        </>
      )}
    </>
  );
};

export default TelegramInviteCard;
