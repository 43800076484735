import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'store/hooks';

import Button from 'components/Button';
import LogOutModal from 'components/LogOutModal';
import PlatformIcon from 'components/PlatformIcon';
import RedditConnectModal from 'components/RedditConnectModal';
import Spinner from 'components/Spinner';
import SupportModal from 'components/SupportModal';
import WalletBalance from 'components/WalletBalance';
import {
  adIcon,
  LeaveIcon,
  lightningRoundedIconUrl,
  simpleLogoBlackUrl,
  UserCircle,
} from 'icons';
import { toggleRedditConnectModal } from 'store/community/actionCreators';
import { getNameInitials } from 'utils';
// import AdminSurveyBanner from 'components/AdminSurveyBanner';
import Badge from 'components/Badge';
import NewsTickerBanner from 'components/NewsTickerBanner';

import {
  enableProBotFeatures,
  enableProBotFeaturesV1,
  enableProBotFeaturesV2,
} from 'constants/features';
import { ENABLE_WALLET, MINIAPP_KEYS } from 'constants/marketplace';
import { currentVersion } from 'constants/releaseNotes';
import useScreenSize from 'hooks/useScreenSize';
import fonts from 'styles/typography.module.scss';

import moment from 'moment';
import { Community } from '../../custom-types';
import usePlanHook from '../../hooks/usePlanHook';
import { expireToken } from '../../store/user';
import s from './ServerPage.module.scss';
import { AdBanner } from '../../components/NewsTickerBanner/NewsTickerBanner';

interface ServerPageInterface {
  visible?: boolean;
}

const ServerPage: React.FC<ServerPageInterface> = ({ visible = true }) => {
  const screenSize = useScreenSize();
  const platform = useSelector((state) => state.user.platform);
  const contentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { communityId } = useParams<{ communityId?: string }>();
  const user = useSelector((state) => state.user);
  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );
  const isCommunityLoaded = useSelector(
    (state) => state.community.isCommunityLoaded,
  );
  const showRedditConnectModal = useSelector(
    (state) => state.community.showRedditConnectModal,
  );

  const [showLogOutModal, setShowLogOutModal] = useState(false);
  const [showNewsTickerBanner, setShowNewsTickerBanner] = useState(true);
  const [showAdBanner, setShowAdBanner] = useState(true);
  const [showAlertBanner, setShowAlertBanner] = useState(true);
  /* const [showAdminSurveyBanner, setShowAdminSurveyBanner] = useState(() => {
    const showBanner =
      process.env.NODE_ENV !== 'development' &&
      localStorage.getItem('collabLandSurvey');
    return JSON.parse(showBanner) ?? true;
  }); */

  const [showSupportModal, setShowSupportModal] = useState(false);

  const isNoActiveCommunity =
    !isCommunityLoaded || communitiesFromState.length === 0 || !communityId;

  const activeCommunityId = communityId;

  const activeSubpage = location.pathname
    .replace('/dashboard/', '')
    .split('/')[1];

  const activeCommunity = communitiesFromState.find(
    (community) => community.communityId === activeCommunityId,
  );
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [contentRef, location.pathname]);

  const shouldShowSnapshot = activeCommunity?.miniapps?.includes(
    MINIAPP_KEYS.SNAPSHOT,
  );

  if (!isCommunityLoaded) return <Spinner fullPage />;

  return (
    <div className={s.root}>
      <main className={cx(s.main, !visible && s.hidden)}>
        {showLogOutModal && (
          <LogOutModal
            close={() => {
              setShowLogOutModal(false);
            }}
            callback={() => {
              localStorage.removeItem('collabLandToken');
              dispatch(expireToken());
              navigate('/login', { state: { from: '/' } });
              setShowLogOutModal(false);
            }}
          />
        )}

        {showSupportModal && (
          <SupportModal close={() => setShowSupportModal(false)} />
        )}

        {showRedditConnectModal && (
          <RedditConnectModal
            close={() => dispatch(toggleRedditConnectModal())}
          />
        )}

        <div className={s.navigation}>
          {isNoActiveCommunity || !activeCommunity ? (
            <div className={s.placeholderInput} />
          ) : (
            <>
              {!['mobile', 'tablet'].includes(screenSize) && (
                <>
                  <h5 className={cx(fonts.title3, s.communityName)}>
                    {activeCommunity.name}
                  </h5>
                </>
              )}
            </>
          )}
          {!['mobile', 'tablet'].includes(screenSize) && (
            <div className={s.communitySubName}>
              <PlatformIcon />
              <p>
                Logged in with{' '}
                <span className={s.subtitle}>{user.platform}</span>
              </p>
            </div>
          )}
          <p className={cx(s.title, fonts.caption1)}>Administration</p>
          <div
            className={cx(s.linkWrapper, {
              [s.active]:
                user.platform === 'discord'
                  ? activeSubpage === 'tgrs'
                  : activeSubpage === 'tgas',
            })}
          >
            <Link
              onClick={(e) => {
                if (isNoActiveCommunity) {
                  e.preventDefault();
                }
              }}
              to={
                user.platform === 'discord'
                  ? `/dashboard/${activeCommunityId}/tgrs`
                  : `/dashboard/${activeCommunityId}/tgas`
              }
              className={cx(s.link, fonts.title4, {
                [s.disabled]: isNoActiveCommunity,
              })}
              data-tracking-info={JSON.stringify({
                id:
                  user.platform === 'discord'
                    ? 'page-link:tgrs:click'
                    : 'page-link:tgas:click',
                server_id: activeCommunityId,
              })}
            >
              {user.platform === 'discord'
                ? 'Token Gating Rules (TGRs)'
                : 'TGAs'}
            </Link>
          </div>
          {enableProBotFeaturesV1 && user.platform === 'discord' && (
            <div
              className={cx(s.linkWrapperSpaceBetween, {
                [s.active]: activeSubpage === 'subscription',
              })}
            >
              <Link
                onClick={(e) => {
                  if (isNoActiveCommunity) {
                    e.preventDefault();
                  }
                }}
                to={`/dashboard/${activeCommunityId}/subscription`}
                className={cx(s.link, fonts.title4, {
                  [s.disabled]: isNoActiveCommunity,
                })}
                data-tracking-info={JSON.stringify({
                  id: 'page-link:subscription:click',
                  server_id: activeCommunityId,
                })}
              >
                Subscriptions
                <Badge className={s.badge}>Pro</Badge>
              </Link>
            </div>
          )}
          <div
            className={cx(s.linkWrapper, {
              [s.active]: activeSubpage === 'marketplace',
            })}
          >
            <Link
              onClick={(e) => {
                if (isNoActiveCommunity || user.platform !== 'discord') {
                  e.preventDefault();
                }
              }}
              to={`/dashboard/${activeCommunityId}/marketplace`}
              className={cx(s.link, fonts.title4, {
                [s.disabled]:
                  isNoActiveCommunity || user.platform !== 'discord',
              })}
              data-tracking-info={JSON.stringify({
                id: 'page-link:marketplace:click',
                server_id: activeCommunityId,
              })}
            >
              Marketplace
              {/*<Badge className={s.badge}>BETA</Badge>*/}
            </Link>
          </div>
          <div
            className={cx(s.linkWrapper, {
              [s.active]: activeSubpage === 'config',
            })}
          >
            <Link
              onClick={(e) => {
                if (isNoActiveCommunity) {
                  e.preventDefault();
                }
              }}
              to={`/dashboard/${activeCommunityId}/config`}
              className={cx(s.link, fonts.title4, {
                [s.disabled]: isNoActiveCommunity,
              })}
              data-tracking-info={JSON.stringify({
                id:
                  user.platform !== 'discord'
                    ? 'page-link:bot-config-invite-link:click'
                    : 'page-link:bot-config:click',
                server_id: activeCommunityId,
              })}
            >
              {user.platform !== 'discord'
                ? 'Bot Config / Invite Link'
                : 'Bot Config'}
            </Link>
          </div>

          {user.platform === 'discord' && (
            <>
              <div
                className={cx(s.linkWrapper, {
                  [s.active]: activeSubpage === 'rolesmanager',
                })}
              >
                <Link
                  onClick={(e) => {
                    if (isNoActiveCommunity) {
                      e.preventDefault();
                    }
                  }}
                  to={`/dashboard/${activeCommunityId}/rolesmanager`}
                  className={cx(s.link, fonts.title4, {
                    [s.disabled]: isNoActiveCommunity,
                  })}
                  data-tracking-info={JSON.stringify({
                    id: 'page-link:role-troubleshooting:click',
                    server_id: activeCommunityId,
                  })}
                >
                  Role Troubleshooting
                </Link>
              </div>

              <div
                className={cx(s.linkWrapper, {
                  [s.active]: activeSubpage === 'role-composition',
                })}
              >
                <Link
                  onClick={(e) => {
                    if (isNoActiveCommunity) {
                      e.preventDefault();
                    }
                  }}
                  to={`/dashboard/${activeCommunityId}/role-composition`}
                  className={cx(s.link, fonts.title4, {
                    [s.disabled]: isNoActiveCommunity,
                  })}
                >
                  Role Composition
                  <Badge className={s.badge}>Pro</Badge>
                </Link>
              </div>
            </>
          )}
          {enableProBotFeaturesV2 && (
            <div
              className={cx(s.linkWrapper, {
                [s.active]: activeSubpage === 'analytics',
              })}
            >
              <Link
                onClick={(e) => {
                  if (isNoActiveCommunity) {
                    e.preventDefault();
                  }
                }}
                to={`/dashboard/${activeCommunityId}/analytics`}
                className={cx(s.link, fonts.title4, {
                  [s.disabled]: isNoActiveCommunity,
                })}
              >
                Community Analytics
                <Badge className={s.badge}>Pro</Badge>
              </Link>
            </div>
          )}

          {user.platform === 'discord' && (
            <div
              className={cx(s.linkWrapper, {
                [s.active]: activeSubpage === 'guest-passes',
              })}
            >
              <Link
                onClick={(e) => {
                  if (isNoActiveCommunity) {
                    e.preventDefault();
                  }
                }}
                to={`/dashboard/${activeCommunityId}/guest-passes`}
                className={cx(s.link, fonts.title4, {
                  [s.disabled]: isNoActiveCommunity,
                })}
              >
                Guest Pass
                <Badge className={s.badge}>Pro</Badge>
              </Link>
            </div>
          )}

          {shouldShowSnapshot && (
            <p className={cx(s.title, fonts.caption1, s.menuSection)}>
              Installed apps
            </p>
          )}
          {shouldShowSnapshot && (
            <div
              className={cx(s.linkWrapper, {
                [s.active]: activeSubpage === 'snapshot',
              })}
            >
              <Link
                onClick={(e) => {
                  if (isNoActiveCommunity) {
                    e.preventDefault();
                  }
                }}
                to={`/dashboard/${activeCommunityId}/snapshot`}
                className={cx(s.link, fonts.title4, {
                  [s.disabled]: isNoActiveCommunity,
                })}
              >
                Snapshot
              </Link>
            </div>
          )}
          {ENABLE_WALLET && (
            <div className={s.mobileWalletWrapper}>
              <p className={cx(s.title, fonts.caption1)}>Community wallet</p>
              <WalletBalance />
            </div>
          )}
          <div className={s.supportContainer}>
            <Button
              className={s.donate}
              onClick={() =>
                window.open('https://donate.collab.land/', '_blank')
              }
              data-tracking-info={JSON.stringify({
                id: 'button:donate:click',
                server_id: activeCommunityId,
              })}
            >
              <img src={simpleLogoBlackUrl} alt="support icon" />
              Donate
            </Button>
            {enableProBotFeatures && activeCommunityId && (
              <Button
                className={s.premiumSupport}
                onClick={() =>
                  navigate(`/dashboard/${activeCommunityId}/premium-support`)
                }
                data-tracking-info={JSON.stringify({
                  id: 'button:collabcare:click',
                  server_id: activeCommunityId,
                })}
              >
                <img src={simpleLogoBlackUrl} alt="support icon" />
                CollabCare
              </Button>
            )}
            <Button
              className={s.support}
              onClick={() => setShowSupportModal(true)}
              data-tracking-info={JSON.stringify({
                id: 'button:support:click',
                server_id: activeCommunityId,
              })}
            >
              <img src={simpleLogoBlackUrl} alt="support icon" />
              Support
            </Button>
          </div>
          <div className={cx(s.linkWrapper, s.releaseNotesWrapper)}>
            <Link
              to={`/dashboard${
                activeCommunityId ? `/${activeCommunityId}` : ''
              }/release-notes`}
              className={s.releaseNotesButton}
              data-tracking-info={JSON.stringify({
                id: 'link:release-notes:click',
                server_id: activeCommunityId,
              })}
            >
              Release notes {currentVersion ? `- ${currentVersion}` : ''}
            </Link>
          </div>
        </div>
        <div className={s.control}>
          {(user?.user_profile?.metadata?.avatar &&
            user.platform === 'discord') ||
          (user.iconURL && user.platform === 'reddit') ? (
            <div className={s.userIcon}>
              {user?.user_profile?.metadata?.avatar || user.iconURL ? (
                <img
                  alt="user icon"
                  src={
                    user.iconURL
                      ? user.iconURL
                      : `https://cdn.discordapp.com/avatars/${user.id}/${user?.user_profile?.metadata?.avatar}.png`
                  }
                />
              ) : (
                <p className={cx(fonts.title2)}>
                  {user.username ? getNameInitials(user.username) : ''}
                </p>
              )}
            </div>
          ) : (
            <div className={s.userIcon}>
              <UserCircle title="user icon" />
            </div>
          )}
          {user.platform === 'discord' ? (
            <h4 className={cx(s.userName, fonts.title4)}>{user.username}</h4>
          ) : (
            <h4 className={cx(s.userName, fonts.title4)}>
              {
                // eslint-disable-next-line
                user['name'] ? user['name'] : ''
              }
            </h4>
          )}
          <button
            className={s.leave}
            type="button"
            onClick={() => setShowLogOutModal(true)}
            data-tracking-info={JSON.stringify({
              id: 'button:log-out-icon:click',
            })}
          >
            <LeaveIcon className={s.exit} title="exit icon" />
          </button>
        </div>
      </main>
      <div className={s.content} ref={contentRef}>
        {showNewsTickerBanner && platform === 'discord' && (
          <NewsTickerBanner
            content={
              <p style={{ cursor: 'default' }}>
                Collab.Land{' '}
                <span
                  onClick={() => {
                    navigate(`/dashboard/${activeCommunityId}/subscription`);
                  }}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  data-tracking-info={JSON.stringify({
                    id: 'news-ticker:subscription:click',
                    server_id: activeCommunityId,
                  })}
                >
                  Subscriptions
                </span>{' '}
                are here -- make your choice today!
              </p>
            }
            close={() => setShowNewsTickerBanner(false)}
          />
        )}
        {showAdBanner && (
          <AdBanner
            content={
              <div
                onClick={() => {
                  window.open('https://defensivedefi.com');
                }}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#23075b',
                  gap: 10,
                  fontWeight: 400,
                }}
                data-tracking-info={JSON.stringify({
                  id: 'ad-banner:defensivedefi:click',
                  server_id: activeCommunityId,
                })}
              >
                <img
                  style={{
                    width: '60px',
                    height: '60px',
                  }}
                  src={adIcon}
                  alt={'ad icon'}
                />
                <span style={{ fontWeight: 700 }}>
                  Check out this free DeFi survival guide,
                </span>{' '}
                courtesy of your accounting friends at Cipher Counts
              </div>
            }
            close={() => setShowAdBanner(false)}
          />
        )}
        {/*{showAlertBanner && activeCommunity && (*/}
        {/*  <PlanAlert*/}
        {/*    community={activeCommunity}*/}
        {/*    close={() => setShowAlertBanner(false)}*/}
        {/*  />*/}
        {/*)}*/}
        {/* showAdminSurveyBanner && (
          <AdminSurveyBanner close={() => setShowAdminSurveyBanner(false)} />
        ) */}
        <Outlet />
      </div>
    </div>
  );
};

const PlanAlert = ({
  community,
  close,
}: {
  community: Community;
  close: () => void;
}) => {
  const isDefaultPlan = community.activePlans?.includes('BOT#PLAN#DEFAULT');
  const { plans } = usePlanHook(community);
  const defaultPlan = plans?.find((p) => p?.pk === 'BOT#PLAN#DEFAULT');
  return (
    <>
      {' '}
      {isDefaultPlan && defaultPlan && (
        <NewsTickerBanner
          content={`Please opt a plan before ${moment(
            defaultPlan?.expirationTime! * 1000,
          ).format('DD/MM/YYYY')}`}
          close={close}
        />
      )}
    </>
  );
};

export default ServerPage;
