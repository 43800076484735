import React, { useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'store/hooks';
import { clearToasts } from 'store/toasts/actionCreators';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { InfoIcon, FailIcon, SuccessIcon } from 'icons';
import s from './Toast.module.scss';

type ToastProps = {
  direction: string;
};

const Toast: React.FC<ToastProps> = ({ direction }: ToastProps) => {
  const listFromState = useSelector((state) => state.toasts.toastList);
  const dispatch = useDispatch();
  const ToastProvider = ToastPrimitive.Provider;
  const ToastViewport = ToastPrimitive.Viewport;
  const ToastParent = ToastPrimitive.Root;
  const ToastDescription = ToastPrimitive.Description;
  const ToastClose = ToastPrimitive.Close;
  useEffect(() => {
    dispatch(clearToasts());
  }, [dispatch]);

  return (
    <div className={cx(s.toastContainer, s[direction])}>
      <ToastProvider swipeDirection="right">
        {listFromState.map((toast, i) => (
          <ToastParent
            className={s.toastParent}
            style={{
              backgroundColor:
                toast.status === 'success'
                  ? 'var(--color-green-400)'
                  : toast.status === 'error'
                  ? 'var(--color-red-400)'
                  : toast.status === 'warning'
                  ? 'var(--color-yellow-400)'
                  : toast.status === 'info'
                  ? 'var(--color-blue-400)'
                  : '',
              gridTemplateAreas: '"title action" "description action"',
              gridTemplateColumns: 'auto max-content',
            }}
            key={`toast-${i}`}
          >
            <ToastDescription
              style={{
                gridArea: 'description',
              }}
              className={s.toastDescription}
            >
              {toast.status === 'info' && (
                <InfoIcon className={s.infoIcon} title="info icon" />
              )}
              {toast.status === 'error' && (
                <FailIcon className={s.errorIcon} title="error icon" />
              )}
              {toast.status === 'success' && (
                <SuccessIcon className={s.successIcon} title="success icon" />
              )}
              {toast.status === 'warning' && (
                <SuccessIcon className={s.warningIcon} title="warning icon" />
              )}
              {toast.description}
            </ToastDescription>
            <ToastClose aria-label="Close" className={s.closeBtn}>
              <span aria-hidden>×</span>
            </ToastClose>
          </ToastParent>
        ))}
        <ToastViewport className={s.ToastViewport} />
      </ToastProvider>
    </div>
  );
};

export default Toast;
