import { createSlice, unwrapResult } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/reducerUtils';
import httpClient, { connectToSDK, getCollabClient } from 'sdk';
import { UserState } from '../types';
import { getCommunitiesAction } from '../community/actionCreators';
import { Mixpanel } from '../../mixpanel';

const initialState: UserState = {
  id: '',
  username: '',
  platform: '',
  email: '',
  avatar: '',
  iconURL: '',
  accessToken: '',
  is2FAEnabled: false,
  connections: [],
  isUserLoaded: false,
  isTokenExpired: false,
};

export const getUserData = createAsyncThunk('user/getUserData', async () => {
  const user = await getCollabClient().account.getUserProfile();
  Mixpanel.register({
    user_id: user.id,
    platform: user.platform,
  });

  if (user.id) {
    Mixpanel.identify(user.id);
  }

  Mixpanel.people.set({
    user_info: user,
  });
  return user;
});

export const login = createAsyncThunk(
  'user/login',
  async ({ token }: { token: string }, { dispatch }) => {
    await connectToSDK(token);

    localStorage.setItem('collabLandToken', token);
    httpClient.setDefaultHeaders({
      authorization: `Bearer ${token}`,
    });
    const userData = await dispatch(getUserData());

    try {
      const user = unwrapResult(userData);

      if (user.platform) {
        // @ts-ignore
        dispatch(getCommunitiesAction());
      }
    } catch (err) {}

    return { token };
  },
);

export const checkUserToken = createAsyncThunk(
  'user/checkUserToken',
  async (_, { dispatch }) => {
    const token = localStorage.getItem('collabLandToken');

    if (token) {
      dispatch(login({ token }));
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    expireToken: (state) => {
      state = {
        ...initialState,
        isTokenExpired: true,
        isUserLoaded: false,
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.fulfilled, (state, { payload }) => {
        state = {
          ...state,
          ...payload,
          isTokenExpired: false,
          isUserLoaded: true,
        };

        return state;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.accessToken = payload.token;
      });
  },
});

export const { expireToken } = userSlice.actions;

export default userSlice.reducer;
