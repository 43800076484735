import { DiscordBotState } from '../types';
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/reducerUtils';
import { RootState } from 'store';
import { getCollabClient } from 'sdk';
import { BotConfigError } from '@collabland/sdk';

const initialState: DiscordBotState = {};

function handleStatusErrors(
  error: any,
  { forwardApiError }: { forwardApiError?: boolean } = {
    forwardApiError: true,
  },
) {
  let errorMessage = error?.response?.obj?.error?.message as string;

  if (error.status === 403) {
    errorMessage = /<\/?[a-z][\s\S]*>/i.test(
      error?.response?.obj?.error?.message,
    )
      ? 'Something is wrong with your bot configuration, try refreshing the page'
      : error?.response?.obj?.error?.message;
  } else if (error.status === 404) {
    errorMessage = error?.response?.obj?.error?.message;
  } else if (error.status === 500) {
    errorMessage = error?.response?.obj?.error?.message;
  } else {
    // A very generic catch-all
    errorMessage =
      forwardApiError && errorMessage
        ? errorMessage
        : "Something went wrong, check your bot's configuration.";
  }

  return errorMessage;
}

interface BotErrorResponse extends BotConfigError {
  link?: string;
}

async function getBotConfigErrors(
  platform: string,
  state: RootState,
  communityId: string,
) {
  const errors: { msg: string; lnk?: string }[] = [];

  if (platform === 'discord') {
    const responseDiscord = await getCollabClient().discordBot.checkBotConfig(
      communityId,
    );

    if (responseDiscord.status === 'FAILED') {
      responseDiscord?.errors!.forEach((error: BotErrorResponse) =>
        errors.push({
          msg: error.action || 'Misconfigured Bot error',
          lnk: error.link,
        }),
      );
    }
  }

  if (platform === 'reddit') {
    const responseReddit = await getCollabClient().reddit.isCommunityConfigSet(
      communityId,
    );

    if (responseReddit.botTokenStatus === 'invalid') {
      const subreddit = state.community.communityGroups.items.find(
        (item) => item.communityId === communityId,
      )!;
      const redirectURI = window.location.origin;
      // Redirect user to the authorize endpoint to complete the bot registration
      const redditInvitationLink = `${
        process.env.REACT_APP_API_URL
      }/reddit/authorize?${new URLSearchParams({
        flow: 'admin:refresh',
        state: communityId,
        subreddit: subreddit.name,
        redirect_uri: redirectURI,
      })}`;

      const canVerifyRedditApp =
        responseReddit.message !== undefined &&
        responseReddit.message !== 'Reddit App not found.';

      if (canVerifyRedditApp) {
        errors.push({
          msg: 'Click on the link to verify',
          lnk: redditInvitationLink,
        });
      } else {
        errors.push({
          msg: 'Something is wrong with your bot configuration, please update your Reddit app credentials',
        });
      }
    }
  }

  if (errors.length) {
    errors.unshift({
      msg: 'The Bot is misconfigured, and will not function as expected.',
    });
  }

  return errors;
}

export const checkCommunityBotConf = createAsyncThunk<
  { communityId: string; messages: { msg: string; lnk?: string }[] },
  { communityId: string },
  {
    state: RootState;
    rejectValue: {
      communityId: string;
      messages: { msg: string; lnk?: string }[];
    };
  }
>(
  'discordBot/checkCommunityBotConf',
  async ({ communityId }, { getState, rejectWithValue }) => {
    const userData = getState().user;
    const state = getState();

    let errors: { msg: string; lnk?: string }[] = [];

    try {
      errors = await getBotConfigErrors(userData.platform, state, communityId);
    } catch (err) {
      const errorMessage = handleStatusErrors(err);

      errors.push({
        msg: errorMessage,
      });
    }

    if (errors.length) {
      return rejectWithValue({
        communityId,
        messages: errors,
      });
    }

    return {
      communityId,
      messages: [{ msg: 'No errors found in bot configuration.' }],
    };
  },
  {
    serializeError: (err: any) => {
      const errorMessage = handleStatusErrors(err);

      return {
        name: err.name,
        message: errorMessage,
        stack: err.stack,
        statusCode: err.statusCode,
      };
    },
  },
);

export const unlinkSubRedditToken = createAsyncThunk(
  'discordBot/unlinkSubRedditToken',
  async ({ communityId }: { communityId: string }, { rejectWithValue }) => {
    try {
      await getCollabClient().reddit.unlinkToken(communityId);
    } catch (err: any) {
      if (err.status === 500) {
        return rejectWithValue({
          communityId,
          messages: [
            {
              msg: 'The Bot is misconfigured, and will not function as expected.',
            },
            {
              msg: err?.response?.obj?.error?.message,
            },
          ],
        });
      }
    }
  },
);

const discordBotSlice = createSlice({
  name: 'discordBot',
  initialState,
  reducers: {
    setBotModalShown: (
      state,
      action: {
        payload: { communityId: string; isModalShown: boolean };
        type: string;
      },
    ) => {
      state[action.payload.communityId] = {
        ...state[action.payload.communityId],
        isModalShown: action.payload.isModalShown,
        showModal: false,
      };
    },
    showBotBanner: (
      state,
      action: { payload: { communityId: string }; type: string },
    ) => {
      state[action.payload.communityId] = {
        ...state[action.payload.communityId],
        showBanner: true,
      };
    },
    hideBotBanner: (
      state,
      action: { payload: { communityId: string }; type: string },
    ) => {
      state[action.payload.communityId] = {
        ...state[action.payload.communityId],
        showBanner: false,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(checkCommunityBotConf.fulfilled, (state, action) => {
        state[action.payload.communityId] = {
          ...state[action.payload.communityId],
          responseData: action.payload.messages,
          showModal: false,
          checked: true,
        };
      })
      .addCase(checkCommunityBotConf.rejected, (state, action) => {
        if (action.payload) {
          state[action.payload.communityId] = {
            ...state[action.payload.communityId],
            responseData: action.payload.messages,
            showModal: true,
            checked: true,
          };
        }
      });
  },
});

export default discordBotSlice.reducer;
export const { setBotModalShown, showBotBanner, hideBotBanner } =
  discordBotSlice.actions;
