import React from 'react';
import cx from 'classnames';
import CopyButton from 'components/CopyButton';
import fonts from 'styles/typography.module.scss';
import s from './LinkCopy.module.scss';

export type LinkCopyProps = {
  label?: string;
  value: string;
  isUrl?: boolean;
};

const LinkCopy: React.FC<LinkCopyProps> = ({ label, value, isUrl }) => {
  return (
    <div className={s.cardRow}>
      {label && (
        <div className={s.cardBox}>
          <p className={cx(fonts.caption1, s.cardKey)}>{label}</p>
        </div>
      )}
      <div className={s.cardBox}>
        <p className={cx(fonts.caption1, s.cardValue, isUrl && s.cardLink)}>
          {value}
        </p>
      </div>
      <div className={s.cardBox}>
        <CopyButton id={`${label}-copy-button`} value={value} />
      </div>
    </div>
  );
};

export default LinkCopy;
