import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import { PlusIcon, CloseIcon, InfoIcon } from 'icons';
import Select from 'react-select';
import Modal from 'components/Modal';
import Button from 'components/Button';
import TextField from 'components/TextField';
import { twitterSelectStyles } from 'components/Select/Select';
import useScreenSize from 'hooks/useScreenSize';
import {
  twitterSelectOptions,
  twitterMetadataValidationSchema,
} from 'constants/community';
// import Tabs from 'components/Tabs';
import fonts from 'styles/typography.module.scss';
import s from './EditMetadataModal.module.scss';
import { Traits } from 'custom-types';

export type SelectRolesModalProps = {
  close: () => void;
  callback: (traits: Traits[], condition: string) => void;
  traits: Traits[];
  traitsCondition?: string;
  isTwitter: boolean;
};

type Error = {
  type: 'error' | 'warning';
  message: string;
};

const lettersRE = /^[0-9a-zA-Z ]+$/;

const EditMetadataModal: React.FC<SelectRolesModalProps> = ({
  close,
  traits,
  traitsCondition = 'and',
  isTwitter = false,
  callback,
}) => {
  const breakpoint = useScreenSize();
  const metadataListContRef = useRef(null);
  const traitInputRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);

  const [error, setError] = useState<Error | null>(null);
  const [condition, setCondition] = useState(traitsCondition);
  const [metadataList, setMetadataList] = useState(traits);

  const resetMetadataList = () => {
    setMetadataList([{ name: '', value: '' }]);
  };

  useEffect(() => {
    if (metadataList.length === 0) resetMetadataList();
  }, [metadataList]);

  const updateMetadataCell = (traitIdx: number, key: string, value: string) => {
    if (!lettersRE.test(value)) {
      setError({
        type: 'warning',
        message:
          "Warning: It's recommended to only use alphanumeric characters for traits/values",
      });
    } else if (error) {
      setError(null);
    }
    setMetadataList(
      metadataList.map((meta, index) =>
        index === traitIdx ? { ...meta, [key]: value } : meta,
      ),
    );
  };

  const removeMetadataCell = (traitIdx: number) => {
    setError(null);
    if (metadataList.length === 1) resetMetadataList();
    else setMetadataList(metadataList.filter((_, index) => index !== traitIdx));
  };

  const validateForm = () => {
    setError(null);

    const hasEmptyFields = metadataList.some(
      ({ name, value }) => !name || !value,
    );
    if (hasEmptyFields) {
      setError({
        type: 'error',
        message: 'Please fill in missing traits/values in the list below',
      });
      return false;
    }
    if (isTwitter) {
      try {
        const parsedMetadata: Record<string, any> = {};
        metadataList.forEach((m) => {
          parsedMetadata[m.name] = m.value;
        });
        twitterMetadataValidationSchema.validateSync(parsedMetadata, {
          abortEarly: false,
        });
      } catch (err: Error | any) {
        let message = 'Errors:\n';
        err.errors.forEach((e: string) => {
          message += `${e}.\n`;
        });
        setError({
          type: 'error',
          message: message,
        });
        return false;
      }
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    callback(metadataList, condition);

    close();
  };

  return (
    <Modal className={s.content}>
      <h4 className={cx(fonts.title2, s.title)}>Add metadata</h4>
      <div className={cx(s.infoMsg)}>
        <p>
          Select AND if all the traits should match, select OR if at least one
          of the traits should match
        </p>
        <span>Empty trait or value properties are not allowed.</span>
      </div>
      {error && (
        <div
          className={cx(s.banner, error.type === 'error' ? s.error : s.warning)}
        >
          <InfoIcon className={s.infoIcon} title="info icon" />
          <p>{error.message}</p>
        </div>
      )}
      <div className={s.switchContainer}>
        <button
          type="button"
          data-active={condition === 'and'}
          onClick={() => setCondition('and')}
        >
          AND
        </button>
        <button
          type="button"
          data-active={condition === 'or'}
          onClick={() => setCondition('or')}
        >
          OR
        </button>
      </div>
      <div className={s.traitsWrapper}>
        <div className={s.traitsHeader}>
          {['mobile'].includes(breakpoint) ? (
            <p className={cx(fonts.title4, s.traitsHeaderTitle)}>
              Trait and value
            </p>
          ) : (
            <>
              <p className={cx(fonts.title4, s.traitsHeaderTitle)}>Trait</p>
              <p className={cx(fonts.title4, s.traitsHeaderTitle)}>Value</p>
            </>
          )}
        </div>
        <div ref={metadataListContRef} className={s.metadataList}>
          {metadataList.map((metadata, index) => (
            <div className={s.metadataRow} key={`meta-row-${index}`}>
              <div className={s.metadataContainer}>
                {isTwitter ? (
                  <Select
                    className={s.metadataInput}
                    styles={{
                      ...twitterSelectStyles,
                    }}
                    menuPortalTarget={document.body}
                    isMulti={false}
                    captureMenuScroll
                    isSearchable={false}
                    isClearable={false}
                    tabSelectsValue={false}
                    options={twitterSelectOptions}
                    defaultValue={
                      metadata?.name
                        ? twitterSelectOptions.find(
                            (item) => item.value === metadata.name,
                          )
                        : null
                    }
                    onChange={(option) => {
                      if (option)
                        updateMetadataCell(index, 'name', option.value);
                    }}
                  />
                ) : (
                  <TextField
                    className={s.metadataInput}
                    name="trait-name"
                    value={metadata.name}
                    placeholder="Add a trait"
                    reference={traitInputRef}
                    onChange={(value) =>
                      updateMetadataCell(index, 'name', value)
                    }
                  />
                )}
                <div className={s.metadataInput}>
                  <TextField
                    name="trait-value"
                    value={metadata.value}
                    placeholder="Add a value"
                    onChange={(value) =>
                      updateMetadataCell(index, 'value', value)
                    }
                  />
                </div>
              </div>
              <button
                type="button"
                className={s.metadataBodyButton}
                onClick={() => {
                  removeMetadataCell(index);
                }}
              >
                <CloseIcon className={s.close} title="delete icon" />
              </button>
            </div>
          ))}
        </div>
        <Button
          type="button"
          color="secondary"
          className={cx(s.addMetadataButton, fonts.button2)}
          onClick={() => {
            setMetadataList((prev) => [...prev, { name: '', value: '' }]);
            setTimeout(() => {
              traitInputRef.current?.focus();
            }, 100);
          }}
        >
          <PlusIcon className={s.plus} title="add icon" />
          Add trait
        </Button>
      </div>

      <div className={s.row}>
        <Button
          size="small"
          className={s.cancel}
          color="secondary"
          onClick={close}
        >
          Cancel
        </Button>
        <Button
          disabled={metadataList.length === 0}
          size="small"
          className={s.submit}
          onClick={handleSubmit}
        >
          Save Traits
        </Button>
      </div>
    </Modal>
  );
};

export default EditMetadataModal;
