import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { createAsyncThunk } from 'store/reducerUtils';
import {
  BarChartProps,
  LineChartProps,
  Chart,
  PieChartProps,
} from 'components/Charts/types';

type DynamicAnalyticsResponse = {
  period: string;
  chart: Chart;
};
type AnalyticsState = {
  communitiesConnections: {
    [key: string]: DynamicAnalyticsResponse;
  };
  communitiesLetsGo: {
    [key: string]: DynamicAnalyticsResponse;
  };
  communitiesRolesAdded: {
    [key: string]: DynamicAnalyticsResponse;
  };
  communitiesRolesPie: {
    [key: string]: DynamicAnalyticsResponse;
  };
  loading: boolean;
  error: string | null;
};
const initialState: AnalyticsState = {
  communitiesRolesAdded: {},
  communitiesLetsGo: {},
  communitiesConnections: {},
  communitiesRolesPie: {},
  loading: false,
  error: null,
};

const SERVER = process.env.REACT_APP_API_URL;

// const sleep = () => new Promise((res) => setTimeout(res, 2000));

export const fetchCommunityAnalyticsWalletConnections = createAsyncThunk<
  DynamicAnalyticsResponse,
  { communityId: string; range: string },
  { state: RootState }
>(
  'analytics/fetchCommunityAnalyticsWalletConnections',
  async ({ communityId, range }, { getState }) => {
    // await sleep();
    // const data = (await import(
    //   '../../utils/mockConnection.json'
    // )) as DynamicAnalyticsResponse;

    // return data;

    const token = localStorage.getItem('collabLandToken'); // FIXME, use token from rootstate if possible
    const url = `${SERVER}/analytics/${encodeURIComponent(
      communityId,
    )}/wallet-connections?range=${range}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'X-API-KEY': process.env.REACT_APP_COLLABLAND_KEY ?? '',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    });
    const data = (await res.json()) as LineChartProps;

    return { period: range, chart: data };
  },
);

export const fetchCommunityAnalyticsRolesAdded = createAsyncThunk<
  DynamicAnalyticsResponse,
  { communityId: string; range: string },
  { state: RootState }
>(
  'analytics/fetchCommunityAnalyticsRolesAdded',
  async ({ communityId, range }, { getState }) => {
    const community = getState().community.communityGroups.items.find(
      (c) => c.communityId === communityId,
    );
    // await sleep();
    // const data = (await import(
    //   '../../utils/mockRolesAdded.json'
    // )) as DynamicAnalyticsResponse;

    // return data;

    const token = localStorage.getItem('collabLandToken');
    const url = `${SERVER}/analytics/${encodeURIComponent(
      communityId,
    )}/roles-added?range=${range}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'X-API-KEY': process.env.REACT_APP_COLLABLAND_KEY ?? '',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(community?.roles),
    });

    const data = (await res.json()) as BarChartProps;

    return { period: range, chart: data };
  },
);

export const fetchCommunityAnalyticsLetsGo = createAsyncThunk<
  DynamicAnalyticsResponse,
  { communityId: string; range: string },
  { state: RootState }
>(
  'analytics/fetchCommunityAnalyticsLetsGo',
  async ({ communityId, range }, { getState }) => {
    // await sleep();
    // const data = (await import(
    //   '../../utils/mockLetsgo.json'
    // )) as DynamicAnalyticsResponse;

    // return data;

    const token = localStorage.getItem('collabLandToken');
    const url = `${SERVER}/analytics/${encodeURIComponent(
      communityId,
    )}/user-interactions?range=${range}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'X-API-KEY': process.env.REACT_APP_COLLABLAND_KEY ?? '',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    });
    const data = (await res.json()) as LineChartProps;

    return { period: range, chart: data };
  },
);

export const fetchCommunityAnalyticsRolesPie = createAsyncThunk<
  DynamicAnalyticsResponse,
  { communityId: string; range: string },
  { state: RootState }
>(
  'analytics/fetchCommunityAnalyticsRolesPie',
  async ({ communityId, range }, { getState }) => {
    // await sleep();
    // const data = (await import(
    //   '../../utils/mockPie.json'
    // )) as DynamicAnalyticsResponse;

    // return data;

    const community = getState().community.communityGroups.items.find(
      (c) => c.communityId === communityId,
    );
    const token = localStorage.getItem('collabLandToken');
    const url = `${SERVER}/analytics/${encodeURIComponent(
      communityId,
    )}/role-distribution?range=${range}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'X-API-KEY': process.env.REACT_APP_COLLABLAND_KEY ?? '',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(community?.roles),
    });
    const data = (await res.json()) as PieChartProps;

    return { period: range, chart: data };
  },
);

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommunityAnalyticsWalletConnections.pending, (state) => {
        state.error = null;
      })
      .addCase(
        fetchCommunityAnalyticsWalletConnections.fulfilled,
        (state, action) => {
          state.communitiesConnections[action.meta.arg.communityId] =
            action.payload;
        },
      )
      .addCase(
        fetchCommunityAnalyticsWalletConnections.rejected,
        (state, { error }) => {
          state.error =
            error.message ??
            'Failed to fetch analytics data: Wallet Connections';
        },
      )
      .addCase(fetchCommunityAnalyticsRolesAdded.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchCommunityAnalyticsRolesAdded.fulfilled, (state, action) => {
        state.communitiesRolesAdded[action.meta.arg.communityId] =
          action.payload;
      })
      .addCase(
        fetchCommunityAnalyticsRolesAdded.rejected,
        (state, { error }) => {
          state.error =
            error.message ??
            'Failed to fetch analytics data: Token Gated Role Granted';
        },
      )
      .addCase(fetchCommunityAnalyticsLetsGo.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchCommunityAnalyticsLetsGo.fulfilled, (state, action) => {
        state.communitiesLetsGo[action.meta.arg.communityId] = action.payload;
      })
      .addCase(fetchCommunityAnalyticsLetsGo.rejected, (state, { error }) => {
        state.error =
          error.message ?? 'Failed to fetch analytics data: User Interactions';
      })
      .addCase(fetchCommunityAnalyticsRolesPie.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchCommunityAnalyticsRolesPie.fulfilled, (state, action) => {
        state.communitiesRolesPie[action.meta.arg.communityId] = action.payload;
      })
      .addCase(fetchCommunityAnalyticsRolesPie.rejected, (state, { error }) => {
        state.error =
          error.message ?? 'Failed to fetch analytics data: Role Distribution';
      });
  },
});

export default analyticsSlice.reducer;
