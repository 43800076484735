import React, { useEffect, useState, useCallback } from 'react';
import cx from 'classnames';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/hooks';
import Spinner from 'components/Spinner';
import NotificationBanner from 'components/NotificationBanner';
import { AttentionIcon, InfoIcon } from 'icons';
import {
  getDiscordRoles,
  resolveDiscordRoles,
  emptyDiscordRoles,
} from 'store/roleManager';
import Button from 'components/Button';
import TextField from 'components/TextField';
import LinkCopy from 'components/LinkCopy';

import fonts from 'styles/typography.module.scss';
import s from './CommunityRoles.module.scss';
import Dropdown from 'components/Dropdown';

type RoleFilterOption = {
  value: string;
  label: string;
};

const roleFilterOptions: RoleFilterOption[] = [
  {
    value: 'all',
    label: 'All roles',
  },
  {
    value: 'deviants',
    label: 'Incorrect role assigment',
  },
  {
    value: 'assigned',
    label: 'Assigned roles',
  },
  {
    value: 'unassigned',
    label: 'Not assigned roles',
  },
];

const CommunityRoles: React.FC = () => {
  const { communityId: activeCommunityId } = useParams() as {
    communityId: string;
  };
  const dispatch = useDispatch();
  const [showBanner, setShowBanner] = useState(true);
  const [filterOptions, setFilterOptions] = useState<RoleFilterOption>(
    roleFilterOptions[0],
  );
  const [userId, setUserId] = useState('');
  const [error, setError] = useState('');
  const user = useSelector((state) => state.user);

  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );

  const communityFromState = communitiesFromState.find(
    (community) => community.communityId === activeCommunityId,
  )!;

  const rolesWithTpcs = communityFromState.roles.filter((role) =>
    communityFromState.tpcs.some((tpc) => tpc.roleId === role.id),
  );

  const rolesFromState = useSelector(
    (state) => state.roleManager.userRoles.items,
  ).filter((role) => {
    switch (filterOptions.value) {
      case 'all':
        return role;
      case 'deviants':
        return role.shouldAssign !== '';
      case 'assigned':
        return role.assigned === 'Assigned';
      case 'unassigned':
        return role.assigned === 'Not assigned';
      default:
        return role;
    }
  });

  const consistent = useSelector(
    (state) => state.roleManager.userRoles.consistent,
  );

  const loading = useSelector((state) => state.roleManager.loading);

  useEffect(() => {
    dispatch(emptyDiscordRoles());
  }, [dispatch]);

  const onCheckUserClick = useCallback(() => {
    setError('');
    setShowBanner(false);
    if (userId.trim()) {
      const numOnlyRegEx = /^[0-9\b]+$/;
      if (!numOnlyRegEx.test(userId.trim())) {
        setError('Invalid Discord user ID. Please use only numbers');
      } else if (userId.trim().length < 17 || userId.trim().length > 19) {
        setError('Discord ID should be between 17 and 19 digits.');
      } else {
        dispatch(
          getDiscordRoles({
            communityId: activeCommunityId,
            userId: userId.trim(),
          }),
        );
        setFilterOptions(roleFilterOptions[0]);
      }
    } else {
      setError('Please type a user id');
    }
  }, [activeCommunityId, dispatch, userId]);

  const onResolveClick = useCallback(() => {
    dispatch(
      resolveDiscordRoles({
        communityId: activeCommunityId,
        userId,
      }),
    );
  }, [activeCommunityId, dispatch, userId]);
  return (
    <>
      <div className={s.content}>
        <h5 className={cx(fonts.title1, s.title)}>Community Role Manager</h5>
        {loading && (
          <div className={s.formLoading}>
            <Spinner />
          </div>
        )}
        {showBanner && (
          <NotificationBanner
            close={() => setShowBanner(false)}
            msgClass="infoMsg"
            msgContents={
              rolesWithTpcs.length > 0
                ? ['Enter the User ID to review roles and resolve any issues.']
                : [
                    'There are NO Token-Granted Roles for this server. Go to the TGRs page to configure.',
                  ]
            }
            msgTitle="Community Roles"
          />
        )}
        <div className={s.form}>
          <div className={s.searcharea}>
            <div className={s.searchareaWithButtonContainer}>
              <TextField
                placeholder="enter a user id"
                label="User ID"
                inputClassName={s.input}
                error={error}
                value={userId}
                onChange={(value) => setUserId(value)}
                onKeyDown={onCheckUserClick}
                className={s.searchInput}
                name="address"
              />
              <Button
                disabled={rolesWithTpcs.length <= 0}
                className={s.checkRolesButton}
                onClick={onCheckUserClick}
                data-tracking-info={JSON.stringify({
                  id: 'button:check-discord-roles:click',
                  server_id: activeCommunityId,
                  member_user_id: userId,
                })}
              >
                Check Discord Roles
              </Button>
            </div>
            {error && (
              <div className={s.errorMsg}>
                <p className={cx(fonts.caption2, s.errorMsgText)}>{error}</p>
              </div>
            )}

            <div className={s.info}>
              <InfoIcon className={s.infoIcon} title="info icon" />
              <p className={cx(fonts.caption2, s.infoText)}>
                Use only the user ID from the Discord account
              </p>
            </div>
          </div>
        </div>

        <div className={s.textarea}>
          <LinkCopy label="Community ID" value={activeCommunityId} />
          <LinkCopy label="User ID" value={user.id} />
        </div>
        <div>
          <>
            <Dropdown
              variant="sort"
              trigger={
                <>
                  Filter by <span>{filterOptions.label}</span>
                </>
              }
              items={roleFilterOptions}
              onChange={setFilterOptions}
            />
          </>
        </div>
        <div className={s.rolesContent}>
          {rolesFromState.length > 0 &&
            !loading &&
            rolesFromState
              .sort((role) => (!role.shouldAssign ? 1 : -1))
              .map((role) => (
                <div className={s.roleCell} key={role.id}>
                  <div
                    className={
                      role.shouldAssign ? s.roleBodyWarning : s.roleBody
                    }
                  >
                    <div className={s.roleBodyRow}>
                      <Link
                        target="_blank"
                        className={cx(fonts.caption1, s.roleValue)}
                        to={`/dashboard/${activeCommunityId}/tgrs/${role.id}`}
                      >
                        {role.name}
                      </Link>

                      <p className={cx(fonts.caption1, s.roleValue)}>
                        {role.assigned}
                      </p>
                    </div>
                    <div className={s.roleBodyRow}>
                      <p className={cx(fonts.caption1, s.roleValue)}>
                        Role ID : {role.id}
                      </p>

                      {role.shouldAssign !== '' && (
                        <p className={cx(fonts.caption1, s.roleValueWarning)}>
                          <AttentionIcon
                            className={s.errorMsgIcon}
                            title="warning icon"
                          />
                          <span className={cx(fonts.caption2)}>
                            {role.shouldAssign}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
        </div>
        <div className={s.resolveRolesButtonContainer}>
          {!consistent && rolesFromState.length > 0 && !loading && (
            <Button className={s.resolveRolesButton} onClick={onResolveClick}>
              Resolve
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CommunityRoles;
