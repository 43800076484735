import { ReactNode, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import fonts from 'styles/typography.module.scss';
import s from './CollapsibleRow.module.scss';
import { ArrowShortIcon } from 'icons';

export type Props = {
  title: string | ReactNode;
  children: ReactNode;
  onChange?: (collapsed: boolean) => void;
};

const CollapsibleRow = ({ title, children, onChange }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setHeight(ref?.current?.offsetHeight ?? 0);
  }, [children]);

  const handleOnClick = () => {
    onChange?.(!collapsed);
    setCollapsed(!collapsed);
  };

  return (
    <>
      <div className={s.titleWrapper}>
        {typeof title === 'string' ? (
          <h6 className={cx(fonts.title3)}>{title}</h6>
        ) : (
          title
        )}
        <button onClick={handleOnClick}>
          <ArrowShortIcon
            className={cx(s.arrow, collapsed && s.collapsed)}
            title="short icon"
          />
        </button>
      </div>
      <div
        style={{ height: collapsed ? 0 : height }}
        className={s.contentWrapper}
      >
        <div ref={ref} className={s.contentHeightWrapper}>
          {children}
        </div>
      </div>
    </>
  );
};

export default CollapsibleRow;
