import React, { useState } from 'react';

import Modal from 'components/Modal';
import Button from 'components/Button';
import MaskotHeart from 'images/maskot_heart.png';
import DancingMascot from 'images/DancingMascot.png';
import s from './InviteCommunityModal.module.scss';
import DiscordInviteCard from './components/DiscordInviteCard/DiscordInviteCard';
import TelegramInviteCard from './components/TelegramInviteCard/TelegramInviteCard';
import { useDispatch } from '../../store/hooks';
import { modalActions } from '../../store/modal';

export type InviteCommunityModalProps = {
  platform: string;
};

const InviteCommunityModal: React.FC<InviteCommunityModalProps> = ({
  platform,
}) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const dispatch = useDispatch();
  return (
    <Modal className={s.root}>
      <span
        className={s.closeButton}
        onClick={() => {
          dispatch(modalActions.closeInviteModal());
        }}
      >
        &times;
      </span>
      <div className={s.container}>
        {!showSuccess && (
          <Button
            className={s.helpButton}
            onClick={() => {
              window.open(
                platform === 'discord'
                  ? 'https://collabland.freshdesk.com/support/solutions/articles/70000036689-discord-bot-walkthrough'
                  : 'https://collabland.freshdesk.com/support/solutions/articles/70000637073-telegram-bot-walkthrough',
              );
            }}
          >
            Need Help?
          </Button>
        )}
        {platform === 'discord' && (
          <DiscordInviteCard
            onSuccess={() => {
              setShowSuccess(true);
            }}
            onClose={() => {
              dispatch(modalActions.closeInviteModal());
            }}
          />
        )}
        {platform === 'telegram' && (
          <TelegramInviteCard
            onSuccess={() => {
              setShowSuccess(true);
            }}
            onClose={() => {
              dispatch(modalActions.closeInviteModal());
            }}
          />
        )}
      </div>
      <div className={s.imageContainer}>
        {showSuccess ? (
          <img alt="Dancing mascot" src={DancingMascot} />
        ) : (
          <img alt="Holding handout" src={MaskotHeart} />
        )}
      </div>
    </Modal>
  );
};

export default InviteCommunityModal;
