import { useParams } from 'react-router-dom';
import { useSelector } from '../store/hooks';
import { Community } from '../custom-types';

export default function useGetCurrentCommunity(): Community {
  const { communityId: activeCommunityId } = useParams() as {
    communityId: string;
  };
  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );

  return communitiesFromState.find(
    (community) => community.communityId === activeCommunityId,
  )!;
}
